import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import {
  notificationsKeys,
  RequestTypes,
} from '@core/react-query/features/notifications'
import { RequestErrorType } from '@core/utils/api-axios-instance'

export type GetNotificationsUnseenCounterResponse = { unseen: number }

export const useGetNotificationsUnseenCounter = (
  payload: RequestTypes['getNotificationsUnseenCounter'],
  options?: UseQueryOptions<
    GetNotificationsUnseenCounterResponse,
    RequestErrorType,
    GetNotificationsUnseenCounterResponse
  >,
) => {
  return useQuery({
    ...notificationsKeys.getNotificationsUnseenCounter(payload),
    ...options,
  })
}
