import {
  Dialog as MaterialDialog,
  DialogTitle as MaterialDialogTitle,
  DialogContent as MaterialDialogContent,
  DialogActions as MaterialDialogActions,
} from '@mui/material'

const Dialog = MaterialDialog

const DialogTitle = MaterialDialogTitle

const DialogContent = MaterialDialogContent

const DialogActions = MaterialDialogActions

export { Dialog, DialogTitle, DialogContent, DialogActions }
