import { RequestErrorType } from '@core/utils/api-axios-instance'
import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query'
import {
  notificationsKeys,
  RequestTypes,
} from '@core/react-query/features/notifications'
import { useMutateQueryData } from '@core/react-query/hooks'

export const useDeleteNotification = (
  options?: UseMutationOptions<
    unknown,
    RequestErrorType,
    RequestTypes['deleteNotification']
  >,
) => {
  const queryClient = useQueryClient()
  const { deleteInfiniteQueryItem } = useMutateQueryData(queryClient)

  return useMutation({
    ...notificationsKeys.deleteNotification,
    ...options,
    onMutate: (...params) => {
      const [{ userId, notificationId }] = params

      deleteInfiniteQueryItem(
        notificationsKeys.getNotifications({ userId }).queryKey.slice(0, -3),
        notificationId,
      )

      options?.onMutate?.(...params)
    },
  })
}
