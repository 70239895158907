import { TemplateRuleCondition } from '@core/entities/template/TemplateRuleCondition'
import { IPermitTemplateApprovalRule } from '@core/entities/permit-template-editor/IPermitTemplateApprovalRule'
import { Entity } from '@core/entities/entity'
import { plainToInstance, Type } from 'class-transformer'

export class PermitTemplateApprovalRule extends Entity<IPermitTemplateApprovalRule> {
  @Type(() => TemplateRuleCondition)
  conditions?: Array<TemplateRuleCondition>

  approvals?: Array<{
    id: string
  }>

  public static new(payload: unknown): PermitTemplateApprovalRule {
    const entity = plainToInstance(PermitTemplateApprovalRule, payload)

    return entity
  }
}
