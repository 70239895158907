import { plainToInstance } from 'class-transformer'
import { IProjectUser } from './IProjectUser'
import { ProjectRole } from '@core/entities/user/ProjectRole'
import { UserStatus } from '@core/entities/user/UserStatus'
import moment from 'moment/moment'
import { User } from '@core/entities/user/User'
import { localizedStrings } from '@core/strings'

export class ProjectUser extends User<IProjectUser> {
  orgRoles?: Array<ProjectRole>
  roles?: Array<ProjectRole>
  lastActive?: string
  status: UserStatus
  authGroups: Array<{
    id: number
    name: string
  }>

  public static new(payload: unknown): ProjectUser {
    const entity = plainToInstance(ProjectUser, payload)

    return entity
  }

  public getStatusString(): string {
    switch (this.status) {
      case 'ACTIVE':
        return localizedStrings.active
      case 'DISABLED':
        return localizedStrings.disabled
      default:
        return this.status
    }
  }

  public getLastActiveString(): string {
    return this?.lastActive
      ? moment(this?.lastActive).format('Do MMM YYYY')
      : localizedStrings.never
  }

  public getRoleStrings(): string[] {
    const roles = this.orgRoles || this.roles || []
    return roles.map((role) => ProjectUser.getRoleString(role))
  }

  public isDisabled(): boolean {
    return this.status === 'DISABLED'
  }

  public static getRoleString(role: ProjectRole): string {
    switch (role) {
      case 'PROJECT_ADMIN':
        return localizedStrings.projectAdmin
      case 'PROJECT_VIEWER':
        return localizedStrings.projectViewer
      case 'SUPERVISOR':
        return localizedStrings.user
      case 'OPERATIVE':
        return localizedStrings.user
      case 'AUTHORISED_PERSON':
        return localizedStrings.inviteUser
      default:
        return role
    }
  }
}
