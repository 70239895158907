export type OrganisationLink = {
  sourceOrganisationId: number
  sourceOrganisationName: string
  destinationOrganisationId: number
  destinationOrganisationName: string
  relationshipTypes: OrganisationLinkType[]
}

export type OrganisationPermission =
  | 'DASHBOARD'
  | 'LIST_USER_PERMITS'
  | 'LIST_BUSINESS_UNITS'
  | 'LIST_DRAFTS'
  | 'LIST_MEMBERS'
  | 'LIST_PERMITS'
  | 'LIST_PROJECTS'
  | 'LIST_TEMPLATES'
  | 'LIST_AUDITS'
  | 'MEMBER_DETAILS'
  | 'READ'
  | 'CREATE_BUSINESS_UNITS'
  | 'CREATE_DRAFTS'
  | 'CREATE_TEMPLATES'
  | 'EDIT'
  | 'UPDATE_MEMBER_ROLE'
  | 'UPDATE_MEMBER_STATUS'
  | 'INVITE_USER'
  | 'LIST_ADMIN_TEMPLATES'
  | 'CREATE_PROJECTS'
  | 'SEE_ALL_PERMITS'
  | 'CREATE_REGISTER_TEMPLATES'
  | 'LIST_GROUPS'
  | 'CREATE_GROUPS'

export const ALL_ORGANISATION_LINK_TYPES = ['USES_TEMPLATES_OF'] as const
type OrganisationLinkTuple = typeof ALL_ORGANISATION_LINK_TYPES
export type OrganisationLinkType = OrganisationLinkTuple[number]
