import NextLink from 'next/link'
import { BoxProps, Flex } from '@fundamentals'
import { styled } from '@mui/material/styles'

type LinkProps = BoxProps & {
  href?: string
  active?: boolean
}

const StyledLink = styled(Flex)<LinkProps>`
  cursor: pointer;
  align-items: center;
  font-weight: bold;
`

const Link: React.FC<LinkProps> = ({ children, href, ...props }) => {
  if (href) {
    return (
      <NextLink href={href}>
        <StyledLink {...props}>{children}</StyledLink>
      </NextLink>
    )
  }
  return <StyledLink {...props}>{children}</StyledLink>
}

export { Link }
