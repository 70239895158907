import { BoxProps } from '@fundamentals'

export interface ThemeType {
  space: {}
  fonts: {
    nunitoSains: string
    helvetica: string
    inter: string
  }
  palette: {
    primary: {
      10: string
      7: string
      6: string
    }
    blue: {
      oxford: {
        7: string
        10: string
      }
      steel: {
        10: string
        5: string
      }
      saint: {
        10: string
      }
      cosmicCobalt: {
        10: string
        7: string
      }
    }

    lavender: {
      3: string
    }
    green: {
      10: string
      9: string
      8: string
      7: string
      6: string
      5: string
      4: string
      3: string
      2: string
      1: string
    }
    white: {
      10: string
      ghost: string
    }
    red: {
      10: string
      9: string
      8: string
      7: string
      6: string
      5: string
      4: string
      3: string
      2: string
      1: string
    }
    navy: {
      10: string
      9: string
      8: string
      7: string
      6: string
      5: string
      4: string
      3: string
      2: string
      1: string
    }
    grey: {
      10: string
      9: string
      8: string
      7: string
      6: string
      5: string
      4: string
      3: string
      2: string
      1: string
    }
  }
  borders: {
    thin: string
    medium: string
  }
  radii: {
    small: string
    medium: string
    large: string
  }
  variants: {
    navbar: {
      height: string
      bg: string
    }
    card: {
      backgroundColor: string
      boxShadow: string
      padding: number
      borderRadius: string
    }
    table: {
      header: BoxProps
      row?: BoxProps
    }
  }
}

const theme: ThemeType = {
  space: [0, '0.25rem', '0.5rem', '1rem', '1.5rem', '3rem', '5rem'],
  fonts: { nunitoSains: 'Nunito sans', helvetica: 'Helvetica', inter: 'Inter' },
  palette: {
    primary: { 10: '#F7A400', 7: '#F9BF4D', 6: '#FFC95F' },
    blue: {
      oxford: {
        10: 'rgba(11, 16, 46, 0.7)',
        7: 'rgba(11, 16, 46, 0.7)',
      },
      steel: { 10: '#A4B5D2', 5: '#A4B5D27F' },
      saint: { 10: '#313273' },
      cosmicCobalt: { 10: '#343090', 7: '#3430901a' },
    },
    lavender: { 3: '#F8F8FE' },
    green: {
      10: '#43DCA8',
      9: '#56DFB1',
      8: '#87E9C7',
      7: '#7BE6C2',
      6: '#8EEACB',
      5: '#A1EED3',
      4: '#A1EED3',
      3: '#C7F5E5',
      2: '#D9F8EE',
      1: '#ECFCF6',
    },
    white: {
      10: '#fff',
      ghost: '#f4f6fc',
    },
    red: {
      10: '#E15555',
      9: '#E46666',
      8: '#E77777',
      7: '#EA8888',
      6: '#ED9999',
      5: '#F0AAAA',
      4: '#F3BBBB',
      3: '#F6CCCC',
      2: '#F9DDDD',
      1: '#FCEEEE',
    },
    navy: {
      10: '#0B102E',
      9: '#1A1B4B',
      8: '#3C4058',
      7: '#54586D',
      6: '#6D7082',
      5: '#858797',
      4: '#9D9FAB',
      3: '#B6B7C0',
      2: '#CECFD5',
      1: '#E7E7EA',
    },
    grey: {
      10: '#A4B5D2',
      9: '#ADBCD7',
      8: '#B6C4DB',
      7: '#BFCBDF',
      6: '#C8D3E4',
      5: '#D1DAE9',
      4: '#DBE1ED',
      3: '#E4E9F2',
      2: '#EDF0F6',
      1: '#F4F6FC',
    },
  },
  borders: {
    thin: '1px solid',
    medium: '2px solid',
  },
  radii: {
    small: '3px',
    medium: '6px',
    large: '18px',
  },
  variants: {
    navbar: {
      height: '67px',
      bg: 'legacy.grey.1',
    },
    card: {
      boxShadow: '0 6px 13px 0 rgba(52, 48, 144, 0.1)',
      backgroundColor: 'white',
      borderRadius: '6px',
      padding: 2,
    },
    table: {
      header: {
        fontWeight: 800,
        borderBottom: '1px solid',
        borderColor: 'lightgrey',
      },
      row: {
        paddingY: 2,
      },
    },
  },
}

export default theme
