import { Checkbox as MaterialCheckbox } from '@mui/material'

const Checkbox = ({ checked, dataTest = '', ...props }) => {
  return (
    <MaterialCheckbox
      checked={checked}
      data-test={`${dataTest}${checked ? 'selected' : 'not-selected'}`}
      {...props}
    />
  )
}

export { Checkbox }
