import React, { FunctionComponent, useRef } from 'react' // we need this to make JSX compile
import cx from 'classnames'
import ReactTooltip, { TooltipProps } from 'react-tooltip'

type ComponentType = {
  title?: React.ReactNode
  content?: React.ReactNode
} & TooltipProps

const Tooltip: FunctionComponent<ComponentType> = ({
  title,
  children,
  className,
  ...rest
}) => {
  const id = useRef(Utils.GUID())
  return (
    <span className={cx('question-tooltip', className)}>
      {
        <span className='table__header-text' data-for={id.current} data-tip>
          {title} <i className='fas fa-info-circle primary'></i>
        </span>
      }
      {/* @ts-ignore */}
      <ReactTooltip html id={id.current} type='dark' effect='solid' {...rest}>
        {`${children}`}
      </ReactTooltip>
    </span>
  )
}

export const TooltipRegular: FunctionComponent<ComponentType> = ({
  title,
  content,
  children,
  ...rest
}) => {
  const id = useRef(Utils.GUID())
  return (
    <span>
      {
        <span data-for={id.current} data-tip>
          {title}{' '}
        </span>
      }
      {/* @ts-ignore */}
      <ReactTooltip html id={id.current} type='dark' effect='solid' {...rest}>
        {`${children}`}
      </ReactTooltip>
    </span>
  )
}

export const TooltipSidebar: FunctionComponent<ComponentType> = ({
  title,
  children,
  disable = false,
  ...rest
}) => {
  const id = useRef(Utils.GUID())
  return (
    <span className='question-tooltip'>
      {
        <span className='table__header-text' data-for={id.current} data-tip>
          {children}
        </span>
      }
      {/* @ts-ignore */}
      <ReactTooltip
        html
        id={id.current}
        type='dark'
        effect='solid'
        disable={disable}
        {...rest}
      >
        {title}
      </ReactTooltip>
    </span>
  )
}

export default Tooltip
