export const useScrolling = () => {
  const scrollingElementId = 'scrollingElement'

  const scrollToBottom = () => {
    const scroller = document.getElementById(scrollingElementId)
    scroller?.scrollTo(0, scroller?.scrollHeight)
  }

  const scrollToTop = () => {
    const scroller = document.getElementById(scrollingElementId)
    scroller?.scrollTo(0, 0)
  }

  return {
    scrollingElementId,
    scrollToBottom,
    scrollToTop,
  }
}
