import { localizedStrings } from '@core/strings'
import { Exclude, plainToInstance, Type } from 'class-transformer'
import { IRegisterItem } from './IRegisterItem'
import {
  RegisterItemDiagramProperty,
  RegisterItemFlatPropertyData,
  RegisterItemSelectProperty,
  RegisterItemStringProperty,
  RegisterItemStatus,
  RegisterItemWithLinksPropertyData,
} from './types'
import { Entity } from '@core/entities/entity'
import { RegisterTemplateProperty } from '@core/entities/register/RegisterTemplateProperty'
import moment from 'moment/moment'
import {
  GroupMetadata,
  ShapeGroup,
  SkiaOval,
  SkiaPolygon,
  SkiaRect,
} from '@core/entities/skia'
import { RegisterTemplate } from '@core/entities/register/RegisterTemplate'

export class RegisterItem extends Entity<IRegisterItem> {
  id: number
  uniqueId: string
  data: RegisterItemFlatPropertyData[]
  dataWithLinkProperties?: RegisterItemWithLinksPropertyData[]
  status: RegisterItemStatus

  @Type(() => RegisterTemplateProperty)
  registerProperties: RegisterTemplateProperty[]

  @Exclude({ toPlainOnly: true })
  isSelected: boolean

  public static new(payload: unknown): RegisterItem {
    const entity = plainToInstance(RegisterItem, payload)

    return entity
  }

  public selectRegisterItemList(): void {
    this.isSelected = true
  }

  public getRegisterItemPropertyDisplayValues(
    registerItemProperty: RegisterItemFlatPropertyData,
  ): string {
    return (
      this.getRegisterItemPropertyValues(registerItemProperty) ??
      localizedStrings.na
    )
  }

  public getRegisterItemPropertyValues(
    registerItemProperty: RegisterItemFlatPropertyData,
  ): string {
    switch (registerItemProperty.type) {
      default:
      case 'STRING':
        return registerItemProperty?.textValue
      case 'SELECT':
        return registerItemProperty?.selectedOption?.label
      case 'DATE':
        const dateRange = registerItemProperty?.dateValue
          ? moment(registerItemProperty?.dateValue).format('do MMM yyyy')
          : null
        const startDate = registerItemProperty?.startTime
          ? moment(registerItemProperty?.startTime).format('do MMM yyyy')
          : null
        const endDate = registerItemProperty?.endTime
          ? moment(registerItemProperty?.endTime).format('do MMM yyyy')
          : null
        return dateRange || startDate || endDate
      case 'NUMBER':
        return typeof registerItemProperty?.numberValue === 'number'
          ? String(registerItemProperty?.numberValue)
          : null
      case 'USER':
        return registerItemProperty?.selectedUser?.id
          ? String(registerItemProperty?.selectedUser?.id)
          : null
      case 'DIAGRAM':
        return registerItemProperty?.siteBoxDocumentName
      case 'INSPECTION':
        return registerItemProperty?.inspection?.inspectionId
    }
  }

  public getRegisterItemColor(colorPropertyId: string): string {
    const colorProperty = this.data.find(
      (property) => property.registerPropertyId === colorPropertyId,
    ) as RegisterItemSelectProperty<string>

    return colorProperty?.selectedOption?.colour
  }

  public getRegisterItemGroupMetadata(
    registerTemplate: RegisterTemplate,
  ): GroupMetadata {
    // Handling groupMetadata
    const colorPropertyId = registerTemplate.diagram.colourProperty.id
    const identifierPropertyId = registerTemplate.diagram.identifierProperty.id
    const colorRgb = this.getRegisterItemColor(colorPropertyId)
    const labels = registerTemplate.getDiagramLabels(this)

    return {
      color: colorRgb,
      groupId: this.uniqueId,
      labels: labels.map((label) => ({
        name: label.name,
        text: label.label,
        backgroundColor: label.color,
        enabled: true,
      })),
      groupName: this.getRegisterItemPropertyDisplayValues(
        this.data.find(
          (column) => column.registerPropertyId === identifierPropertyId,
        ) as RegisterItemStringProperty<string>,
      ),
    }
  }

  public getRegisterItemShapes(): ShapeGroup {
    // Handling shapeGroups
    const itemAnnotations = (
      this.data.find(
        (column) => column.registerPropertyId === '-1',
      ) as RegisterItemDiagramProperty<string>
    ).annotations

    const shapes = itemAnnotations.map((annotation) => {
      switch (annotation.annotationData.type) {
        case 'POLYGON':
          return SkiaPolygon.newFromSiteboxDocumentAnnotation(annotation)
        case 'RECTANGLE':
          return SkiaRect.newFromSiteboxDocumentAnnotation(annotation)
        case 'ELLIPSE':
          return SkiaOval.newFromSiteboxDocumentAnnotation(annotation)
        default:
          return null
      }
    })

    return { shapes, groupId: this.uniqueId }
  }

  public getRegisterItemPreviewData(
    previewProperties: number,
  ): RegisterItemFlatPropertyData[] {
    return (
      this.data
        .filter((item) => item.type !== 'INSPECTION')
        .slice(0, previewProperties) ?? []
    )
  }

  public hasInspection(): boolean {
    return this.data.some(
      (item) =>
        item.type === 'INSPECTION' && !!item.inspection?.lastInspectedBy,
    )
  }
}
