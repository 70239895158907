import { RequestErrorType } from '@core/utils/api-axios-instance'
import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query'
import {
  notificationsKeys,
  RequestTypes,
} from '@core/react-query/features/notifications'
import { GetNotificationsUnseenCounterResponse } from './useGetNotificationsUnseenCounter'

export const useMarkAllNotificationsAsSeen = (
  options?: UseMutationOptions<
    unknown,
    RequestErrorType,
    RequestTypes['markAllNotificationsAsSeen']
  >,
) => {
  const queryClient = useQueryClient()

  return useMutation({
    ...notificationsKeys.markAllNotificationsAsSeen,
    ...options,
    onMutate: (...params) => {
      const [{ userId }] = params

      queryClient.setQueryData<GetNotificationsUnseenCounterResponse>(
        notificationsKeys.getNotificationsUnseenCounter({ userId }).queryKey,
        (previousData) => {
          if (previousData) {
            return {
              ...previousData,
              unseen: 0,
            }
          }
        },
      )

      options?.onMutate?.(...params)
    },
  })
}
