import { DefaultOptions } from '@tanstack/query-core'

export const defaultOptions: DefaultOptions = {
  queries: {
    refetchOnWindowFocus: false,
  },
  mutations: {
    // We don't retry mutations since they're not idempotent
    // We can allow retries if we implement idempotency keys and idempotency checking on the back-end
    retry: 0,
  },
}
