import { Type } from 'class-transformer'
import { Entity } from '@core/entities/entity'
import { IPermitLastExtension } from './IPermitLastExtension'
import { User } from '@core/entities/user'

export class PermitLastExtension extends Entity<IPermitLastExtension> {
  id: string

  @Type(() => User)
  requestedBy: User

  requestedOn: string
  extensionReason: string
  extensionEndsOn: string
  status: string

  @Type(() => User)
  approvedBy: User

  approvedOn: string

  @Type(() => User)
  rejectedBy: User

  rejectedOn: string
  rejectionReason: string
}
