import { IconButton as MaterialButton } from '@mui/material'
import { styled, experimental_sx as sx } from '@mui/material/styles'

const IconButton = styled(MaterialButton)`
  ${({}) => {
    return sx({
      '&:focus': {
        outline: 'none',
      },
      '&:hover': {
        outline: 'none',
      },
    })
  }}
`

export { IconButton }
