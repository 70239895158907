import { useEffect, useRef, useState } from 'react'
import { Channel } from 'pusher-js'
import { PusherOptions, usePusher } from '@core/providers/usePusher'

type UseRealTimeSubscription = (
  channelKey: string,
  options?: PusherOptions,
) => {
  channel: Channel
  bindEventHandler: (
    eventName: string,
    callback: Function,
    context?: any,
  ) => void
}

export const useRealTimeSubscription: UseRealTimeSubscription = (
  channelKey,
  options = { enabled: true },
) => {
  const { sendAnalyticsEvent } = API.useAnalytics()
  const pusher = usePusher(options)
  const [currentChannel, setCurrentChannel] = useState<Channel>()
  const [previousChannel, setPreviousChannel] = useState<Channel>()
  const enabledRef = useRef(!!options.enabled)

  useEffect(() => {
    enabledRef.current = !!options.enabled
  }, [options.enabled])

  useEffect(() => {
    if (previousChannel) {
      previousChannel.unsubscribe()
      sendAnalyticsEvent({
        eventName: 'pusher.subscription-closed',
        eventProperties: {
          platform: API.platform === 'web' ? 'web' : 'mobile',
          channel: previousChannel.name,
        },
      })

      setPreviousChannel(undefined)
    }
  }, [previousChannel])

  useEffect(() => {
    if (!pusher || !channelKey) return

    const newChannel = pusher.subscribe(channelKey)
    newChannel.bind('pusher:subscription_succeeded', () => {
      sendAnalyticsEvent({
        eventName: 'pusher.subscription-successful',
        eventProperties: {
          platform: API.platform === 'web' ? 'web' : 'mobile',
          channel: newChannel.name,
        },
      })
    })
    setPreviousChannel(currentChannel)
    setCurrentChannel(newChannel)

    return () => {
      newChannel.unsubscribe()
      newChannel.unbind_all()
      sendAnalyticsEvent({
        eventName: 'pusher.subscription-closed',
        eventProperties: {
          platform: API.platform === 'web' ? 'web' : 'mobile',
          channel: newChannel.name,
        },
      })
    }
  }, [pusher, channelKey])

  const bindEventHandler = (
    eventName: string,
    callback: Function,
    context?: any,
  ) => {
    if (!currentChannel) return
    const enabledCallback = (...args) => enabledRef.current && callback(...args)
    currentChannel.bind(eventName, enabledCallback, context)
  }

  return { channel: currentChannel, bindEventHandler }
}
