import { Link as MaterialLink, LinkProps } from '@mui/material'
import React from 'react'
import { styled, experimental_sx as sx } from '@mui/material/styles'

type ComponentType = LinkProps & {}

const StyledLink = styled(MaterialLink)`
  cursor: pointer;
  ${sx({ color: 'primary' })}
`

const Link: React.FC<ComponentType> = ({ ...props }) => {
  return <StyledLink sx={{ color: 'primary' }} {...props} />
}

export { Link }
