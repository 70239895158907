import { Entity } from '@core/entities/entity'
import { IProcessTag } from '@core/entities/processTags/index'

import { plainToInstance } from 'class-transformer'

export class ProcessTag extends Entity<IProcessTag> {
  id: number

  name: string

  color: string

  public static new(payload: unknown): ProcessTag {
    const entity = plainToInstance<ProcessTag, unknown>(ProcessTag, payload)

    return entity
  }
}
