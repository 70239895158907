import { Type, plainToInstance } from 'class-transformer'
import { Entity } from '@core/entities/entity'
import { TemplateForm } from '@core/entities/template/TemplateForm'
import { TemplateFormAnswers } from '@core/entities/template/TemplateFormAnswers'
import { IPermitFinalSignOffReviewContent } from '@core/entities/permit/PermitFinalSignOffReviewContent'

export class PermitFinalSignOffReviewContent extends Entity<IPermitFinalSignOffReviewContent> {
  id: string
  label: string

  @Type(() => TemplateForm)
  finalSignOffQuestions: TemplateForm

  @Type(() => TemplateFormAnswers)
  finalSignOffAnswers: TemplateFormAnswers

  public static new(payload: unknown): PermitFinalSignOffReviewContent {
    const entity = plainToInstance(PermitFinalSignOffReviewContent, payload)

    return entity
  }
}
