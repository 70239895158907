import { UseQueryOptions, useQuery } from '@tanstack/react-query'
import { profileKeys } from '@core/react-query/features/profile/profileKeys'
import { IProfile, Profile } from '@core/entities/profile'
import Cognito from '@core/cognito'
export const useGetProfile = (
  options: UseQueryOptions<IProfile, string, Profile> & {
    skipPrint?: boolean
  } = {},
) => {
  const cognitoUsername = Cognito.usernameSync()

  return useQuery<IProfile, string, Profile>({
    keepPreviousData: true,
    select: Profile.new,
    staleTime: 5 * 60 * 1000, // 5 minutes
    ...profileKeys.profile._ctx.byUserId({
      userId: cognitoUsername,
    }),
    ...options,
  })
}
