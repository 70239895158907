import {
  Menu as MaterialMenu,
  MenuItem as MaterialMenuItem,
} from '@mui/material'

const Menu = MaterialMenu

const MenuItem = MaterialMenuItem

export { Menu, MenuItem }
