// SiteAssist Color Palette 2024-06-12

const Blue = {
  50: 'rgb(227, 242, 253)',
  100: 'rgb(187, 222, 251)',
  200: 'rgb(144, 202, 249)',
  300: 'rgb(100, 181, 246)',
  400: 'rgb(66, 165, 245)',
  500: 'rgb(32, 149, 243)',
  600: 'rgb(30, 135, 229)',
  700: 'rgb(25, 118, 210)',
  800: 'rgb(21, 100, 192)',
  900: 'rgb(13, 70, 161)',
  A100: 'rgb(130, 177, 255)',
  A200: 'rgb(68, 138, 255)',
  A400: 'rgb(41, 121, 255)',
  A700: 'rgb(41, 98, 255)',
}

const DeepPurple = {
  50: 'rgb(234, 230, 246)',
  100: 'rgb(201, 194, 232)',
  200: 'rgb(165, 154, 217)',
  300: 'rgb(129, 113, 203)',
  400: 'rgb(100, 84, 192)',
  500: 'rgb(69, 57, 181)',
  600: 'rgb(58, 52, 175)',
  700: 'rgb(40, 45, 166)',
  800: 'rgb(17, 39, 158)',
  900: 'rgb(0, 29, 144)',
  A100: 'rgb(179, 136, 255)',
  A200: 'rgb(124, 77, 255)',
  A400: 'rgb(101, 31, 255)',
  A700: 'rgb(98, 0, 234)',
}

const Green = {
  50: 'rgb(227, 244, 236)',
  100: 'rgb(188, 227, 208)',
  200: 'rgb(145, 209, 178)',
  300: 'rgb(96, 192, 149)',
  400: 'rgb(54, 179, 126)',
  500: 'rgb(0, 166, 105)',
  600: 'rgb(0, 151, 95)',
  700: 'rgb(0, 133, 82)',
  800: 'rgb(0, 116, 70)',
  900: 'rgb(0, 85, 48)',
  A100: 'rgb(185, 246, 202)',
  A200: 'rgb(105, 240, 174)',
  A400: 'rgb(0, 230, 118)',
  A700: 'rgb(0, 200, 83)',
}

const Red = {
  50: 'rgb(250, 233, 232)',
  100: 'rgb(254, 204, 191)',
  200: 'rgb(254, 171, 150)',
  300: 'rgb(254, 138, 108)',
  400: 'rgb(255, 111, 77)',
  500: 'rgb(255, 86, 48)',
  600: 'rgb(244, 80, 44)',
  700: 'rgb(230, 73, 39)',
  800: 'rgb(215, 67, 35)',
  900: 'rgb(190, 55, 27)',
  A100: 'rgb(255, 138, 128)',
  A200: 'rgb(255, 82, 82)',
  A400: 'rgb(255, 23, 68)',
  A700: 'rgb(213, 0, 0)',
}

const Yellow = {
  50: 'rgb(255, 254, 230)',
  100: 'rgb(255, 250, 193)',
  200: 'rgb(254, 246, 151)',
  300: 'rgb(253, 241, 107)',
  400: 'rgb(251, 237, 70)',
  500: 'rgb(248, 232, 20)',
  600: 'rgb(255, 220, 28)',
  700: 'rgb(255, 195, 15)',
  800: 'rgb(255, 171, 0)',
  900: 'rgb(254, 127, 0)',
  A100: 'rgb(255, 255, 141)',
  A200: 'rgb(255, 255, 0)',
  A400: 'rgb(255, 234, 0)',
  A700: 'rgb(255, 214, 0)',
}

const Cyan = {
  50: 'rgb(224, 246, 251)',
  100: 'rgb(178, 234, 245)',
  200: 'rgb(127, 220, 239)',
  300: 'rgb(76, 205, 231)',
  400: 'rgb(35, 195, 224)',
  500: 'rgb(0, 184, 217)',
  600: 'rgb(0, 169, 198)',
  700: 'rgb(0, 148, 171)',
  800: 'rgb(0, 128, 147)',
  900: 'rgb(0, 94, 103)',
  A100: 'rgb(132, 255, 255)',
  A200: 'rgb(24, 255, 255)',
  A400: 'rgb(0, 229, 255)',
  A700: 'rgb(0, 184, 212)',
}

const Purple = {
  50: 'rgb(243, 229, 245)',
  100: 'rgb(225, 190, 231)',
  200: 'rgb(206, 147, 216)',
  300: 'rgb(186, 104, 200)',
  400: 'rgb(171, 71, 188)',
  500: 'rgb(156, 39, 176)',
  600: 'rgb(142, 36, 170)',
  700: 'rgb(123, 31, 162)',
  800: 'rgb(106, 27, 154)',
  900: 'rgb(74, 20, 140)',
  A100: 'rgb(179, 136, 255)',
  A200: 'rgb(124, 77, 255)',
  A400: 'rgb(101, 31, 255)',
  A700: 'rgb(98, 0, 234)',
}

const Teal = {
  50: 'rgb(224, 242, 241)',
  100: 'rgb(178, 223, 219)',
  200: 'rgb(128, 203, 196)',
  300: 'rgb(77, 182, 172)',
  400: 'rgb(38, 166, 154)',
  500: 'rgb(0, 150, 136)',
  600: 'rgb(0, 137, 123)',
  700: 'rgb(0, 121, 107)',
  800: 'rgb(0, 105, 92)',
  900: 'rgb(0, 77, 64)',
  A100: 'rgb(167, 255, 235)',
  A200: 'rgb(100, 255, 218)',
  A400: 'rgb(29, 233, 182)',
  A700: 'rgb(0, 191, 165)',
}

const Orange = {
  50: 'rgb(255, 243, 224)',
  100: 'rgb(255, 224, 178)',
  200: 'rgb(255, 204, 128)',
  300: 'rgb(255, 183, 77)',
  400: 'rgb(255, 167, 38)',
  500: 'rgb(255, 152, 0)',
  600: 'rgb(251, 140, 0)',
  700: 'rgb(245, 124, 0)',
  800: 'rgb(239, 108, 0)',
  900: 'rgb(230, 81, 0)',
  A100: 'rgb(255, 209, 128)',
  A200: 'rgb(255, 171, 64)',
  A400: 'rgb(255, 145, 0)',
  A700: 'rgb(255, 109, 0)',
}

const Amber = {
  50: 'rgb(255, 248, 225)',
  100: 'rgb(255, 236, 179)',
  200: 'rgb(255, 224, 130)',
  300: 'rgb(255, 213, 79)',
  400: 'rgb(255, 202, 40)',
  500: 'rgb(255, 193, 7)',
  600: 'rgb(255, 179, 0)',
  700: 'rgb(255, 160, 0)',
  800: 'rgb(255, 143, 0)',
  900: 'rgb(255, 111, 0)',
  A100: 'rgb(255, 229, 127)',
  A200: 'rgb(255, 215, 64)',
  A400: 'rgb(255, 196, 0)',
  A700: 'rgb(255, 171, 0)',
}

const Indigo = {
  50: 'rgb(232, 234, 246)',
  100: 'rgb(197, 202, 233)',
  200: 'rgb(159, 168, 218)',
  300: 'rgb(121, 134, 203)',
  400: 'rgb(92, 107, 192)',
  500: 'rgb(63, 81, 181)',
  600: 'rgb(57, 73, 171)',
  700: 'rgb(48, 63, 159)',
  800: 'rgb(40, 53, 147)',
  900: 'rgb(26, 35, 126)',
  A100: 'rgb(140, 158, 255)',
  A200: 'rgb(83, 109, 254)',
  A400: 'rgb(61, 90, 254)',
  A700: 'rgb(48, 79, 254)',
}

const Pink = {
  50: 'rgb(252, 228, 236)',
  100: 'rgb(248, 187, 208)',
  200: 'rgb(244, 143, 177)',
  300: 'rgb(240, 98, 146)',
  400: 'rgb(236, 64, 122)',
  500: 'rgb(233, 30, 99)',
  600: 'rgb(216, 27, 96)',
  700: 'rgb(194, 24, 91)',
  800: 'rgb(173, 20, 87)',
  900: 'rgb(136, 14, 79)',
  A100: 'rgb(245, 0, 87)',
  A200: 'rgb(255, 64, 129)',
  A400: 'rgb(245, 0, 87)',
  A700: 'rgb(197, 17, 98)',
}

const LightBlue = {
  50: 'rgb(225, 245, 254)',
  100: 'rgb(179, 229, 252)',
  200: 'rgb(129, 212, 250)',
  300: 'rgb(79, 195, 247)',
  400: 'rgb(41, 182, 246)',
  500: 'rgb(3, 169, 244)',
  600: 'rgb(3, 155, 229)',
  700: 'rgb(2, 136, 209)',
  800: 'rgb(2, 119, 189)',
  900: 'rgb(1, 87, 155)',
  A100: 'rgb(128, 216, 255)',
  A200: 'rgb(64, 196, 255)',
  A400: 'rgb(0, 176, 255)',
  A700: 'rgb(0, 145, 234)',
}

const LightGreen = {
  50: 'rgb(241, 248, 233)',
  100: 'rgb(220, 237, 200)',
  200: 'rgb(197, 225, 165)',
  300: 'rgb(174, 213, 129)',
  400: 'rgb(156, 204, 101)',
  500: 'rgb(139, 195, 74)',
  600: 'rgb(124, 179, 66)',
  700: 'rgb(104, 159, 56)',
  800: 'rgb(85, 139, 47)',
  900: 'rgb(51, 105, 30)',
  A100: 'rgb(204, 255, 144)',
  A200: 'rgb(178, 255, 89)',
  A400: 'rgb(118, 255, 3)',
  A700: 'rgb(100, 221, 23)',
}

const Lime = {
  50: 'rgb(249, 251, 231)',
  100: 'rgb(240, 244, 195)',
  200: 'rgb(230, 238, 156)',
  300: 'rgb(220, 231, 117)',
  400: 'rgb(212, 225, 87)',
  500: 'rgb(205, 220, 57)',
  600: 'rgb(192, 202, 51)',
  700: 'rgb(175, 180, 43)',
  800: 'rgb(158, 157, 36)',
  900: 'rgb(130, 119, 23)',
  A100: 'rgb(244, 255, 129)',
  A200: 'rgb(238, 255, 65)',
  A400: 'rgb(198, 255, 0)',
  A700: 'rgb(174, 234, 0)',
}

const DeepOrange = {
  50: 'rgb(251, 233, 231)',
  100: 'rgb(255, 204, 188)',
  200: 'rgb(255, 171, 145)',
  300: 'rgb(255, 138, 101)',
  400: 'rgb(255, 112, 67)',
  500: 'rgb(255, 87, 72)',
  600: 'rgb(244, 81, 30)',
  700: 'rgb(230, 74, 25)',
  800: 'rgb(216, 67, 21)',
  900: 'rgb(191, 54, 12)',
  A100: 'rgb(255, 158, 128)',
  A200: 'rgb(255, 110, 64)',
  A400: 'rgb(255, 61, 0)',
  A700: 'rgb(221, 44, 0)',
}

const Brown = {
  50: 'rgb(239, 235, 233)',
  100: 'rgb(215, 204, 200)',
  200: 'rgb(188, 170, 164)',
  300: 'rgb(161, 136, 127)',
  400: 'rgb(141, 110, 99)',
  500: 'rgb(121, 85, 72)',
  600: 'rgb(109, 76, 65)',
  700: 'rgb(93, 64, 55)',
  800: 'rgb(78, 52, 46)',
  900: 'rgb(62, 39, 35)',
}

const Grey = {
  50: 'rgb(250, 250, 250)',
  100: 'rgb(245, 245, 245)',
  200: 'rgb(238, 238, 238)',
  300: 'rgb(224, 224, 224)',
  400: 'rgb(189, 189, 189)',
  500: 'rgb(158, 158, 158)',
  600: 'rgb(117, 117, 117)',
  700: 'rgb(97, 97, 97)',
  800: 'rgb(66, 66, 66)',
  900: 'rgb(33, 33, 33)',
  A100: 'rgb(213, 213, 213)',
  A200: 'rgb(170, 170, 170)',
  A400: 'rgb(97, 97, 97)',
  A700: 'rgb(48, 48, 48)',
}

const BlueGrey = {
  50: 'rgb(236, 239, 241)',
  100: 'rgb(207, 216, 220)',
  200: 'rgb(176, 190, 197)',
  300: 'rgb(144, 164, 174)',
  400: 'rgb(120, 144, 156)',
  500: 'rgb(96, 125, 139)',
  600: 'rgb(84, 110, 122)',
  700: 'rgb(69, 90, 100)',
  800: 'rgb(55, 71, 79)',
  900: 'rgb(38, 50, 56)',
}

const Common = {
  White: 'rgb(255, 255, 255)',
  Black: 'rgb(0, 0, 0)',
}

export const Palette = {
  Blue,
  DeepPurple,
  Green,
  Red,
  Yellow,
  Cyan,
  Purple,
  Teal,
  Orange,
  Amber,
  Indigo,
  Pink,
  LightBlue,
  Lime,
  DeepOrange,
  Brown,
  Grey,
  BlueGrey,
  Common,
}
