import type {
  Feature,
  FeatureCollection,
  Polygon,
  Point,
  GeoJSON,
  Geometry,
  GeoJsonProperties,
} from 'geojson'
import { polygon, lineString, featureCollection, point } from '@turf/helpers'
import booleanIntersects from '@turf/boolean-intersects'
import { Coordinate, GeoLocationArea, MapBounds } from '@core/entities/map'

export const getW3wLocation = (geoJSON: GeoJSON): Feature<Point> => {
  if (!(geoJSON && geoJSON.type === 'FeatureCollection')) return
  const w3wFeaturePoint = geoJSON.features.find(
    ({ properties: { inputType } }) => inputType === 'WHAT_3_WORDS',
  ) as Feature<Point>

  if (!w3wFeaturePoint) return

  return w3wFeaturePoint
}

export const getGeolocationAreas = (
  geoJSON: GeoJSON,
): FeatureCollection<Polygon> => {
  if (!(geoJSON && geoJSON?.type === 'FeatureCollection')) return
  const areaGeometries = geoJSON.features.filter(
    ({ properties: { inputType }, geometry }) =>
      inputType === 'DRAW_ON_MAP' && geometry.type === 'Polygon',
  ) as Feature<Polygon>[]

  if (areaGeometries.length !== 0) {
    return featureCollection(areaGeometries)
  }
}

export const geojsonToAreas = (geojson: FeatureCollection) => {
  return geojson?.features?.map((feature) => {
    const [coordinates] =
      'coordinates' in feature.geometry ? feature.geometry?.coordinates : [[]]
    return {
      coordinates:
        typeof coordinates !== 'number' &&
        coordinates.map((coordinate) => {
          const [longitude, latitude] = coordinate
          return {
            longitude,
            latitude,
          }
        }),
    }
  })
}

export const areasToGeojson = (
  geoLocationAreas: GeoLocationArea[],
): FeatureCollection => {
  if (!geoLocationAreas) return null
  return {
    'type': 'FeatureCollection',
    'features': geoLocationAreas?.map((area) => ({
      'type': 'Feature',
      'properties': {},
      'geometry': {
        'coordinates': [area.coordinates.map((c) => [c.longitude, c.latitude])],
        'type': 'Polygon',
      },
    })),
  }
}

export const geoLocationToPolygon = (geoLocationAreas: GeoLocationArea[]) => {
  return polygon(
    geoLocationAreas.map((area) => {
      return area.coordinates.map((coord) => [coord.longitude, coord.latitude])
    }),
  )
}

export const coordinatesToPolygon = (coordinates: Coordinate[]) => {
  return polygon([
    coordinates.map((coord) => [coord.longitude, coord.latitude]),
  ])
}

export const coordinatesToLineString = (coordinates: Coordinate[]) => {
  return lineString(
    coordinates.map((coord) => [coord.longitude, coord.latitude]),
  )
}

export const mapBoundsToLineString = (mapBounds: MapBounds) => {
  return lineString([
    [mapBounds.nw.lng, mapBounds.nw.lat],
    [mapBounds.se.lng, mapBounds.se.lat],
  ])
}

export const mapBoundsToPolygon = (mapBounds: MapBounds) => {
  return polygon([
    [
      [mapBounds.nw.lng, mapBounds.nw.lat], // top-left
      [mapBounds.se.lng, mapBounds.nw.lat], // top-right
      [mapBounds.se.lng, mapBounds.se.lat], // bottom-right
      [mapBounds.nw.lng, mapBounds.se.lat], // bottom-left
      [mapBounds.nw.lng, mapBounds.nw.lat], // top-left - to close polygon
    ],
  ])
}

export const isGeoPolygonInBounds = (
  geoLocationAreas: FeatureCollection<Polygon>,
  mapBounds: MapBounds,
) => {
  const mapBoundPolygon = mapBoundsToPolygon(mapBounds)

  return geoLocationAreas.features.some((area) => {
    return booleanIntersects(mapBoundPolygon, area)
  })
}

export const isGeoPointInBounds = (
  point: Feature<Point>,
  mapBounds: MapBounds,
) => {
  const [longitude, latitude] = point.geometry.coordinates
  const { nw, se } = mapBounds

  // Check if point latitude is within the range of nw and se latitude
  const latWithinBounds =
    latitude >= Math.min(nw.lat, se.lat) && latitude <= Math.max(nw.lat, se.lat)

  // Check if point longitude is within the range of nw and se longitude
  const lngWithinBounds =
    longitude >= Math.min(nw.lng, se.lng) &&
    longitude <= Math.max(nw.lng, se.lng)

  return latWithinBounds && lngWithinBounds
}

/**
 * Creates a feature based on the geometry type.
 * @param {Geometry} geometry - The geometry object of the feature.
 * @param {GeoJsonProperties} properties - The properties object for the feature.
 * @returns {Feature} The new feature with updated properties based on the geometry type.
 */
const createFeatureByType = (
  geometry: Geometry,
  properties: GeoJsonProperties,
): Feature => {
  switch (geometry.type) {
    case 'Point':
      return point(geometry.coordinates, properties)
    case 'LineString':
      return lineString(geometry.coordinates, properties)
    case 'Polygon':
      return polygon(geometry.coordinates, properties)
    default:
      throw new Error('Unsupported geometry type: ' + geometry.type)
  }
}

/**
 * Updates a feature collection by applying a new property value conditionally.
 * @param {FeatureCollection<G>} inputFeatureCollection - The original FeatureCollection to be updated.
 * @param {(feature: Feature<G>) => boolean} conditionFn - A function that takes a feature and returns true if the property should be updated.
 * @param {string} propertyKey - The key of the property to update.
 * @param {any} trueValue - The value to set the property to if the condition is true.
 * @param {any} falseValue - The value to set the property to if the condition is false.
 * @returns {FeatureCollection<G>} A new FeatureCollection with updated features.
 */
export const updateFeatureCollectionPropertyByCondition = <G extends Geometry>(
  inputFeatureCollection: FeatureCollection<G>,
  conditionFn: (feature: Feature<G>) => boolean,
  propertyKey: string,
  trueValue: any,
  falseValue: any,
): FeatureCollection<G> => {
  const updatedFeatures = inputFeatureCollection.features.map((feature) => {
    const newProperties: GeoJsonProperties = {
      ...feature.properties,
      [propertyKey]: conditionFn(feature) ? trueValue : falseValue,
    }
    return createFeatureByType(feature.geometry, newProperties)
  })

  //@ts-ignore
  return featureCollection(updatedFeatures)
}

/**
 * Updates a feature collection by applying a new property value.
 * @param {FeatureCollection<G>} inputFeatureCollection - The original FeatureCollection to be updated.
 * @param {string} propertyKey - The key of the property to update.
 * @param {any} propertyValue - The value to set the property to.
 * @returns {FeatureCollection<G>} A new FeatureCollection with updated features.
 */
export const updateFeatureCollectionProperty = <G extends Geometry>(
  inputFeatureCollection: FeatureCollection<G>,
  propertyKey: string,
  propertyValue: any,
): FeatureCollection<G> => {
  const updatedFeatures = inputFeatureCollection.features.map((feature) => {
    const newProperties: GeoJsonProperties = {
      ...feature.properties,
      [propertyKey]: propertyValue,
    }
    return createFeatureByType(feature.geometry, newProperties)
  })

  //@ts-ignore
  return featureCollection(updatedFeatures)
}
