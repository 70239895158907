import { ISkiaRect } from '@core/entities/skia/SkiaRect/ISkiaRect'
import { plainToInstance } from 'class-transformer'
import { Point } from '@core/entities/skia/types'
import { SkiaShapeBase } from '@core/entities/skia/SkiaShapeBase/SkiaShapeBase'
import { SiteBoxDocumentAnnotation } from '@core/entities/SiteBoxDocumentAnnotation'

export class SkiaRect extends SkiaShapeBase<ISkiaRect> {
  id: string
  type: 'RECT'
  x: number
  y: number
  width: number
  height: number

  public static new(payload: unknown): SkiaRect {
    return plainToInstance(SkiaRect, payload)
  }

  public static newFromSiteboxDocumentAnnotation(
    payload: SiteBoxDocumentAnnotation,
  ): SkiaRect {
    const [x, y, width, height] = payload.annotationData.bbox

    const transformToSkiaRect = {
      id: payload.uuid,
      type: 'RECT',
      x: x,
      y: y,
      width: width,
      height: height,
    }

    return plainToInstance(SkiaRect, transformToSkiaRect)
  }

  isInBounds({ x, y }: Point): boolean {
    return (
      x >= this.x &&
      x <= this.x + this.width &&
      y >= this.y &&
      y <= this.y + this.height
    )
  }

  dragCorners(): Point[] {
    return [
      { x: this.x, y: this.y }, // top left
      { x: this.x + this.width, y: this.y }, // top right
      { x: this.x, y: this.y + this.height }, // bottom left
      { x: this.x + this.width, y: this.y + this.height }, // bottom right
    ]
  }

  resizeOnDrag({
    cornerIndex,
    x,
    y,
  }: {
    cornerIndex: number
    x: number
    y: number
  }) {
    switch (cornerIndex) {
      case 0: // top-left
        this.width += this.x - x
        this.height += this.y - y
        this.x = x
        this.y = y
        break
      case 1: // top-right
        this.width = x - this.x
        this.height += this.y - y
        this.y = y
        break
      case 2: // bottom-left
        this.width += this.x - x
        this.height = y - this.y
        this.x = x
        break
      case 3: // bottom-right
        this.width = x - this.x
        this.height = y - this.y
        break
      default:
        break
    }

    // Stop the square from flipping inside out
    if (this.width < 0) {
      this.width = 0
    }
    if (this.height < 0) {
      this.height = 0
    }
  }

  move({ x, y }: Point) {
    this.x += x
    this.y += y
  }

  labelAnchor(): Point {
    return { x: this.x, y: this.y }
  }

  registerUpdateCardAnchor(): Point {
    return { x: this.x, y: this.y + this.height }
  }
}
