import { Entity } from '@core/entities/entity'
import {
  IPermitTemplateFinalSignOff,
  PermitTemplateFinalSignOffRule,
  PermitTemplateFinalSignOffData,
} from '@core/entities/permit-template-editor'
import { plainToInstance, Type } from 'class-transformer'

export class PermitTemplateFinalSignOff extends Entity<IPermitTemplateFinalSignOff> {
  @Type(() => PermitTemplateFinalSignOffData)
  data: Array<PermitTemplateFinalSignOffData>

  @Type(() => PermitTemplateFinalSignOffRule)
  rules: Array<PermitTemplateFinalSignOffRule>

  public static new(payload: unknown): PermitTemplateFinalSignOff {
    const entity = plainToInstance(PermitTemplateFinalSignOff, payload)
    return entity
  }
}
