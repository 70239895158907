import { IChecklistTemplateVersion } from '@core/entities/checklist/ChecklistTemplateVersion/IChecklistTemplateVersion'
import { Entity } from '@core/entities/entity'
import { Type } from 'class-transformer'
import { plainToInstance } from 'class-transformer'
import {
  Folder,
  PermittedActions,
} from '@core/entities/checklist/ChecklistTemplateVersion/types'
import { TemplateForm } from '@core/entities/template/TemplateForm/TemplateForm'
import { TemplateFormWrapper } from '@core/entities/template/TemplateFormWrapper/TemplateFormWrapper'
import { User } from '@core/entities/user'
import { TemplateEditorPermitTemplateVersionStatus } from '@core/entities/permit-template-editor/types'

export class ChecklistTemplateVersion extends Entity<IChecklistTemplateVersion> {
  id: number

  organisationId: number

  organisationName: string

  name: string

  description: string

  templateId: number

  version: number

  createdOn: string

  modifiedOn: string

  @Type(() => User)
  createdBy: User

  @Type(() => User)
  modifiedBy: User

  status: TemplateEditorPermitTemplateVersionStatus

  timezone: string

  @Type(() => TemplateFormWrapper)
  templateQuestions: Array<TemplateFormWrapper>

  useInReviewVersion: boolean

  folder: Folder

  permittedActions: PermittedActions
  public static new(payload: unknown): ChecklistTemplateVersion {
    const entity = plainToInstance(ChecklistTemplateVersion, payload)

    return entity
  }

  public getMainQuestions(): TemplateForm {
    return this.templateQuestions.find((templateQuestions) => {
      return templateQuestions.questionsType === 'MAIN_QUESTIONS'
    })?.checklistQuestions
  }
}
