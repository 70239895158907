import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query'
import { RequestTypes } from '@core/react-query/features/profile/requestTypes'
import { profileKeys } from '@core/react-query/features/profile/profileKeys'
import { useUploadMedia } from '@core/react-query/features/media'
import {
  RequestErrorType,
  APIAxiosInstance,
} from '@core/utils/api-axios-instance'

export const useUpdateProfile = (
  options?: UseMutationOptions<
    unknown,
    RequestErrorType,
    RequestTypes['updateProfile']
  >,
) => {
  const queryClient = useQueryClient()
  const uploadMutation = useUploadMedia()

  return useMutation({
    mutationKey: null,
    mutationFn: async (payload) => {
      if (payload.signatureFile) {
        const uploadedSignatureId = await uploadMutation.mutateAsync({
          file: payload.signatureFile,
          folderName: 'signature',
        })
        await APIAxiosInstance.post(`users/me/signature`, {
          signatureId: uploadedSignatureId,
        })
      }

      const { data } = await APIAxiosInstance.put(`users/me`, {
        ...payload,
      })
      return data
    },
    // We don't retry mutations since they're not idempotent
    // We can allow retries if we implement idempotency keys and idempotency checking on the back-end
    retry: 0,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(profileKeys.profile.queryKey, {})
    },
    ...options,
  })
}
