import { Entity } from '@core/entities/entity'
import { ISkiaShapeBase } from '@core/entities/skia/SkiaShapeBase/ISkiaShapeBase'
import { Point, ShapeTypes } from '@core/entities/skia/types'

export class SkiaShapeBase<
  ExtendedType extends ISkiaShapeBase = ISkiaShapeBase,
> extends Entity<ExtendedType> {
  id: string
  type: ShapeTypes
  color?: string
  opacity?: number

  dragCorners(): Point[] {
    throw new Error('Should be implemented in child class')
  }

  resizeOnDrag({
    cornerIndex,
    x,
    y,
  }: {
    cornerIndex: number
    x: number
    y: number
  }) {
    throw new Error('Should be implemented in child class')
  }

  move({ x, y }: Point) {
    throw new Error('Should be implemented in child class')
  }

  labelAnchor(): Point {
    throw new Error('Should be implemented in child class')
  }
}
