import { Entity } from '@core/entities/entity'
import {
  IPermitTemplateApproval,
  PermitTemplateApprovalRule,
  PermitTemplateApprovalData,
  PermitTemplateApprovalStage,
} from '@core/entities/permit-template-editor'
import { plainToInstance, Type } from 'class-transformer'

export class PermitTemplateApproval extends Entity<IPermitTemplateApproval> {
  @Type(() => PermitTemplateApprovalData)
  data: Array<PermitTemplateApprovalData>

  @Type(() => PermitTemplateApprovalRule)
  rules: Array<PermitTemplateApprovalRule>

  public static new(payload: unknown): PermitTemplateApproval {
    const entity = plainToInstance(PermitTemplateApproval, payload)
    return entity
  }

  public static removeApprovalsByStage(
    approvals: IPermitTemplateApproval,
    stage: PermitTemplateApprovalStage = 'BEFORE_PERMIT_HOLDER',
  ): IPermitTemplateApproval {
    return {
      ...approvals,
      data: approvals.data.filter(
        (approval) => approval.approvalStage !== stage,
      ),
    }
  }
}
