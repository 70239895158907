import Amplify, { Auth } from 'aws-amplify'

const Cognito = {
  init: (config) => {
    API.log('COGNITO', 'INIT', config)
    Amplify.configure({
      Auth: config,
    })
  },
  signUp: async function (
    username: string,
    password: string,
    attributes?: Record<any, any>,
  ) {
    try {
      const { user, userConfirmed } = await Auth.signUp({
        username,
        password,
        attributes,
      })
      return {
        user,
        userConfirmed,
      }
    } catch (error) {
      console.log('error signing up:', error)
      throw error
    }
  },
  getSession: async function () {
    try {
      await Auth.currentAuthenticatedUser()
      return await Auth.currentSession()
    } catch (e) {
      return null
    }
  },
  confirmSignUp: async function (username: string, code: string) {
    return await Auth.confirmSignUp(username, code)
  },
  login: async function (
    username: string,
    password: string,
    skipSignIn?: boolean,
  ): Promise<string> {
    API.log('COGNITO', username, password)
    // We use this flag when we do federated auth
    // The session information (tokens) is added before this call so we don't need to log in the user
    if (!skipSignIn) {
      const res = await Auth.signIn({
        username,
        password,
      })
    }
    try {
      await Auth.currentAuthenticatedUser()
      const session = await Auth.currentSession()
      return session?.getAccessToken()?.getJwtToken()
    } catch (e) {
      return null
    }
  },
  resetPassword: async function (email: string) {
    return await Auth.forgotPassword(email)
  },
  changePassword: async function (
    username: string,
    code: string,
    password: string,
  ) {
    return await Auth.forgotPasswordSubmit(username, code, password)
  },
  logout: async function () {
    return await Auth.signOut()
  },
  resendConfirmSignUp: async function (username: string) {
    return await Auth.resendSignUp(username)
  },
  resendConfimationCode: async function (email: string) {
    return await Auth.forgotPassword(email)
  },
  getUsername: async (): Promise<string | null> => {
    try {
      const authenticatedUser = await Auth.currentAuthenticatedUser()
      const username = authenticatedUser?.username
      return username
    } catch (e) {
      const errorMessage = e?.toString()
      if (errorMessage.includes('not authenticated')) {
        return null
      }
    }
  },
  usernameSync: () => {
    // Hack Alert: we access the private member of Auth so that we can get the username synchronously
    // @ts-ignore
    return Auth?.user?.username
  },
}

export default Cognito
