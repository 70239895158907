import { useCallback } from 'react'
import {
  InfiniteData,
  QueryClient,
  useQueryClient,
} from '@tanstack/react-query'
import { PageResponse } from '@core/entities/pagination'

const useMutateQueryData = (defaultQueryClient?: QueryClient) => {
  const queryClient = defaultQueryClient || useQueryClient()

  const updateInfiniteQueryItem = useCallback(
    <T extends { id: number | string }>(
      queryKey: readonly unknown[],
      item: T,
      params: { mergeWithExisting?: boolean } = {},
    ) => {
      const { mergeWithExisting } = params

      /* !!! Please Note!!!
       ** This is a workaround for a bug in react-query
       ** exact key needs to be provided for exact queries to be updated
       ** We get use getQueryData to check if the query exists and then set the exact key accordingly
       */
      const queryData = queryClient.getQueryData(queryKey)

      queryClient.setQueriesData<InfiniteData<PageResponse<T>>>(
        { queryKey, exact: !!queryData },
        (data) => {
          if (!data?.pages) return data
          let itemFound = false
          const pages =
            data.pages.map((page) => {
              if (itemFound) return page
              const content = page.content.map((pageItem) => {
                if (pageItem.id === item.id) {
                  itemFound = true
                  return mergeWithExisting ? { ...pageItem, ...item } : item
                }
                return pageItem
              })
              return { ...page, content }
            }) || []
          if (!itemFound && !mergeWithExisting) pages[0].content.unshift(item)

          return { ...data, pages }
        },
      )
    },
    [],
  )

  const deleteInfiniteQueryItem = useCallback(
    <T extends { id: number | string }>(
      queryKey: readonly unknown[],
      id: number | string,
    ) => {
      /* !!! Please Note!!!
       ** This is a workaround for a bug in react-query
       ** exact key needs to be provided for exact queries to be updated
       ** We get use getQueryData to check if the query exists and then set the exact key accordingly
       */
      const queryData = queryClient.getQueryData(queryKey)

      queryClient.setQueriesData<InfiniteData<PageResponse<T>>>(
        { queryKey, exact: !!queryData },
        (data) => {
          if (!data) return data
          let itemFound = false
          const pages =
            data?.pages.map((page) => {
              if (itemFound) return page
              const content = page.content.filter((pageItem) => {
                if (pageItem.id === id) {
                  itemFound = true
                  return false
                }
                return true
              })
              return { ...page, content }
            }) || []

          return { ...data, pages }
        },
      )
    },
    [],
  )

  return { updateInfiniteQueryItem, deleteInfiniteQueryItem }
}

export { useMutateQueryData }
