import { Type, plainToInstance } from 'class-transformer'
import { Entity } from '@core/entities/entity'
import { IPermitSuspension } from './IPermitSuspension'
import { PermitSuspensionReasons, PermitSuspensionStates } from './types'
import { User } from '@core/entities/user'

export class PermitSuspension extends Entity<IPermitSuspension> {
  permitId: number

  reason: string

  resumeReason?: string

  reasonCategory: PermitSuspensionReasons

  resumeRequestReason?: string

  @Type(() => User)
  resumedRequestedBy?: User

  resumeRequestDeclineReason?: string

  @Type(() => User)
  resumeRequestDeclinedBy?: User

  startedOn: string

  @Type(() => User)
  suspendedBy: User

  suspendedMediaUrls?: string[]

  resumeRequestedMediaUrls?: string[]

  state: PermitSuspensionStates

  public static new(payload: unknown): PermitSuspension {
    const entity = plainToInstance(PermitSuspension, payload)

    return entity
  }

  public getTextFromSuspensionState(
    textForSuspend: string,
    textForReject: string,
  ) {
    switch (this.state) {
      case 'SUSPENDED':
      case 'RESUME_REQUESTED':
        return textForSuspend
      case 'RESUME_REQUEST_DECLINED':
        return textForReject
      default:
        return ''
    }
  }

  public isPending(): boolean {
    return (
      this.state === 'RESUME_REQUESTED' ||
      this.state === 'SUSPENDED' ||
      this.state === 'RESUME_REQUEST_DECLINED'
    )
  }
}
