import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { RequestTypes, s3MediaKeys } from '@core/react-query/features/media'
import { RequestErrorType } from '@core/utils/api-axios-instance'

export const useUploadMedia = (
  options?: UseMutationOptions<
    string,
    RequestErrorType,
    RequestTypes['uploadMedia']
  >,
) => {
  return useMutation({
    ...s3MediaKeys.uploadMedia,
    // We don't retry mutations since they're not idempotent
    // We can allow retries if we implement idempotency keys and idempotency checking on the back-end
    retry: 0,
    ...options,
  })
}
