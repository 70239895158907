import React from 'react'
import { Box as MuiBox, BoxProps as MuiBoxProps } from '@mui/material'

type BoxProps = MuiBoxProps & {
  dataTest?: string
  variant?: string
  className?: string
  onClick?: (e: any) => void
  cursor?: string
  clickable?: boolean
  float?: string
  ref?:
    | ((instance: React.RefObject<HTMLDivElement>) => void)
    | React.MutableRefObject<React.RefObject<HTMLDivElement>>
    | React.RefObject<HTMLDivElement>
    | React.MutableRefObject<unknown>
}

const Box: React.FC<BoxProps> = MuiBox

export { Box }
export type { BoxProps }
