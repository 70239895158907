import { useCallback } from 'react'
import { AnalyticsEvent, userProperties } from '@core/analytics'
import { useGetProfile } from '@core/react-query/features/profile/hooks/useGetProfile'
import { Profile } from '@core/entities/profile'
import { analytics } from 'webapp/components/init/InitSegment'

export const shouldSendAnalytics = (profile: Profile | null) => {
  if (!profile) {
    return false
  }

  if (Constants.E2E) {
    return false
  }

  return true
}

export const useAnalytics = () => {
  const { data: profile } = useGetProfile()
  const identify = useCallback(
    (profileArg: Profile) => {
      if (shouldSendAnalytics(profile)) {
        const { userId, properties } = userProperties(profileArg)
        analytics.identify(userId, properties)
      }
    },
    [profile],
  )

  const page = useCallback(() => {
    if (shouldSendAnalytics(profile)) {
      const screenSize = {
        height: window.screen.height,
        width: window.screen.width,
      }
      analytics.page({ screenSize })
    }
  }, [profile])

  const sendAnalyticsEvent = useCallback(
    (event: AnalyticsEvent) => {
      if (shouldSendAnalytics(profile)) {
        const eventProperties = event?.eventProperties
        const eventName = event.eventName
        analytics.track(eventName, eventProperties)
      }
    },
    [profile],
  )

  return {
    identify,
    page,
    sendAnalyticsEvent,
  }
}
