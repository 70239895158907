import { Entity } from '@core/entities/entity'
import { ITemplateForm } from '@core/entities/template/TemplateForm/ITemplateForm'
import { TemplateQuestionGroup } from '@core/entities/template/TemplateQuestionGroup'
import {
  ITemplateRule,
  TemplateRule,
} from '@core/entities/template/TemplateRule'
import { plainToInstance, Type } from 'class-transformer'

import { Dictionary, groupBy } from 'lodash'
import { TemplateWarning } from '@core/entities/template/TemplateWarnings'
import remove from 'lodash/remove'

export class TemplateForm extends Entity<ITemplateForm> {
  @Type(() => TemplateQuestionGroup)
  questions: Array<TemplateQuestionGroup>

  @Type(() => TemplateRule)
  rules?: Array<TemplateRule>

  @Type(() => TemplateWarning)
  warningsConfig?: Array<TemplateWarning>

  public deleteQuestion({
    groupIndex,
    questionIndex,
  }: {
    groupIndex: number
    questionIndex?: number
  }) {
    const templateFormClone: ITemplateForm = JSON.parse(JSON.stringify(this))
    const deleteQuestionWarnings = (questionId: string) => {
      remove(
        templateFormClone.warningsConfig,
        (warning) => warning.questionId === questionId,
      )
    }
    if (questionIndex !== undefined) {
      deleteQuestionWarnings(
        templateFormClone.questions[groupIndex]?.data[questionIndex]?.id,
      )
      templateFormClone.questions[groupIndex]?.data?.splice(questionIndex, 1)
    } else {
      templateFormClone.questions[groupIndex]?.data?.forEach((question) => {
        deleteQuestionWarnings(question.id)
      })
      templateFormClone.questions.splice(groupIndex, 1)
    }
    return templateFormClone
  }

  public isAnswerUsedByRule(
    answerId: string,
    rules: Array<ITemplateRule>,
  ): boolean {
    return rules?.some((rule) =>
      rule.conditions.some((condition) =>
        condition.terms.find((term) => term.answerId === answerId),
      ),
    )
  }

  public hasRulePointingToQuestion(
    id: string,
    rules?: Array<ITemplateRule>,
  ): boolean {
    const list = rules || this.rules
    return list?.some((rule) =>
      rule.shownQuestions.find((thenShow) => thenShow.questionId === id),
    )
  }

  public hasQuestionPointingToRules(
    id: string,
    rules: Array<ITemplateRule>,
  ): boolean {
    let result = false
    rules.forEach((rule) => {
      if (rule.conditions.find((condition) => condition.questionId === id)) {
        result = true
      }
    })
    return result
  }

  public getVisibilityTexts(id: string): string[] {
    const questionIds: string[] = []
    const texts: string[] = []
    const answerIds: Array<string | number> = []

    this.rules?.forEach((rule) => {
      if (rule.shownQuestions.find((thenShow) => thenShow.questionId === id)) {
        rule.conditions.forEach((condition) => {
          questionIds.push(condition.questionId)
          condition.terms.forEach((term) => {
            answerIds.push(term.answerId)
          })
        })
      }
    })

    this.questions.forEach((qGroup, gIndex) => {
      qGroup.data.forEach((question, qIndex) => {
        if (questionIds.includes(question.id)) {
          const answers = []
          question.answers?.forEach((answer) => {
            if (answerIds.includes(answer.id)) {
              answers.push(answer.value)
            }
          })
          texts.push(
            `Visible when Question ${gIndex + 1}-${
              qIndex + 1
            } is ${answers.join(', ')}`,
          )
        }
      })
    })

    return texts
  }

  public getQuestionAnswers = (questionId: string) => {
    let answers = []
    this.questions
      .filter((questionGroup) => {
        const [question] = questionGroup.data
        return question?.questionType !== 'INFO'
      })
      .forEach((questionGroup) => {
        questionGroup.data
          .filter((question) => question?.questionType === 'MULTIPLE_CHOICE')
          .forEach((q) => {
            if (q.id === questionId) {
              answers = q.answers
            }
          })
      })
    return answers
  }

  public getRulesByDependentQuestionId(): Dictionary<ITemplateRule[]> {
    return groupBy(
      this.rules,
      ({ conditions: [condition] }) => condition.questionId,
    )
  }

  public getRulesByTargetQuestionId(): Dictionary<ITemplateRule[]> {
    return groupBy(
      this.rules,
      ({ shownQuestions: [thenShow] }) => thenShow.questionId,
    )
  }

  public hasMultipleChoiceQuestion(): boolean {
    return this.questions.some((group) =>
      group.data.some(
        (question) => question.questionType === 'MULTIPLE_CHOICE',
      ),
    )
  }

  public getMultipleChoiceQuestions() {
    return this?.questions
      .map((questionGroup, key) => {
        return questionGroup.data
          .filter((question) => question.questionType !== 'INFO')
          .filter((question) => question?.questionType === 'MULTIPLE_CHOICE')
          .map((question, i) => {
            if (question.questionType !== 'MULTIPLE_CHOICE') {
              return null
            }
            return {
              ...question,
              groupIndex: key,
              questionIndex: i,
            }
          })
      })
      .flat()
  }

  public static new(payload: unknown): TemplateForm {
    const entity = plainToInstance(TemplateForm, payload)

    return entity
  }
}
