import { Entity } from '@core/entities/entity'
import { plainToInstance, Type } from 'class-transformer'
import { IChecklistRegisterInspection } from '@core/entities/checklist/ChecklistRegisterInspection'
import { RegisterItem } from '@core/entities/register/RegisterItem'

export class ChecklistRegisterInspection extends Entity<IChecklistRegisterInspection> {
  registerId: number

  @Type(() => RegisterItem)
  registerItem: RegisterItem

  public static new(payload: unknown): ChecklistRegisterInspection {
    const entity = plainToInstance(ChecklistRegisterInspection, payload)

    return entity
  }
}
