import { ITemplateFormAnswers } from '@core/entities/template/TemplateFormAnswers/ITemplateFormAnswers'
import { Entity } from '@core/entities/entity'
import { plainToInstance, Type } from 'class-transformer'
import { TemplateQuestionGroup } from '@core/entities/template/TemplateQuestionGroup'
import { User } from '@core/entities/user'
import { SubmissionProof } from '@core/entities/template/TemplateQuestion'

export class TemplateFormAnswers extends Entity<ITemplateFormAnswers> {
  @Type(() => TemplateQuestionGroup)
  answers: Array<TemplateQuestionGroup>

  @Type(() => User)
  submittedBy: User
  submittedOn: string

  public static new(payload: unknown): TemplateFormAnswers {
    const entity = plainToInstance(TemplateFormAnswers, payload)

    return entity
  }

  public isAnswered(): boolean {
    return !!this.answers.length && !!this.submittedOn
  }

  public getImageS3Keys(): string[] {
    return this.answers
      ?.flatMap((group) =>
        group.data?.flatMap((question) =>
          question?.submissionProofs?.flatMap((proof) => proof.key),
        ),
      )
      .filter((key) => !!key)
  }

  public getProofs(): SubmissionProof[] {
    return this.answers
      ?.flatMap((group) =>
        group.data?.flatMap((question) => question?.submissionProofs),
      )
      .filter((key) => !!key)
  }
}
