import {
  createMutationKeys,
  mergeQueryKeys,
} from '@lukemorales/query-key-factory'
import { s3Upload } from '@core/react-query/helpers'

const s3MediaMutationKeys = createMutationKeys('s3-media', {
  uploadMedia: { mutationKey: null, mutationFn: s3Upload },
})

const s3MediaKeys = mergeQueryKeys(s3MediaMutationKeys)['s3-media']

export { s3MediaKeys }
