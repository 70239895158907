import { plainToInstance, Type } from 'class-transformer'
import { Entity } from '@core/entities/entity'
import { IPermitTemplate } from './IPermitTemplate'
import { TemplateForm } from '@core/entities/template/TemplateForm/TemplateForm'
import { User } from '@core/entities/user'
import { PermitTemplateVersion } from '@core/entities/permit-template-editor'
import { IFolderPath } from '@core/entities/folders/FolderPath'
import { PermitTemplateStatus } from '@core/entities/permit/PermitTemplate/types'
import { ProcessTagsWrapper } from '@core/entities/processTags/ProcessTagsWrapper'

export class PermitTemplate extends Entity<IPermitTemplate> {
  id: number
  organisationName: string
  organisationId: number
  name: string
  code: string
  colour: string
  description: string
  templateVersionId: number
  version: number
  requesterConfirmationText: string
  approverConfirmationText: string
  published: boolean
  skipPostPermitChecks: boolean
  initiatorRole: 'AUTHORISED_PERSON' | 'PERMIT_HOLDER' | string
  requesterConfirmationCountdown: { name: string; time: number }

  createdOn: string
  modifiedOn: string
  @Type(() => User)
  createdBy: User
  @Type(() => User)
  modifiedBy: User

  requiresApproval: boolean
  requiresBriefingVerification: boolean
  requireGeolocationForBriefings: boolean
  permitHolderSelfApprove: boolean
  requireGeolocationForPermit: boolean
  requireGeolocationForPostPermitChecks: boolean
  geoLocationInputType: 'WHAT_3_WORDS' | 'DRAW_ON_MAP'

  status: PermitTemplateStatus
  timezone: string
  calculateDurationBasedOnDays: boolean
  maxDurationEnabled: boolean
  maxDurationSeconds: number
  maxDurationDays: number

  @Type(() => TemplateForm)
  permitHolderQuestions?: TemplateForm

  @Type(() => TemplateForm)
  authorisedPersonQuestions: TemplateForm

  @Type(() => TemplateForm)
  postPermitChecksQuestions: TemplateForm

  useInReviewVersion: boolean
  permittedActions: 'READ' | 'EDIT' | 'OVERRIDE' | 'CLONE_VERSION'

  @Type(() => PermitTemplateVersion)
  currentVersion?: PermitTemplateVersion

  folder?: {
    folderId: number
    pathFromRoot?: IFolderPath
  }

  @Type(() => ProcessTagsWrapper)
  processTags?: ProcessTagsWrapper

  public static new(payload: unknown): PermitTemplate {
    return plainToInstance(PermitTemplate, payload)
  }
}
