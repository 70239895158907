import { createContext, useCallback, useEffect, useState } from 'react'
import Pusher from 'pusher-js'
import { useGetProfile } from '@core/react-query/features/profile'

export const PusherContext = createContext<{
  pusher: Pusher
  initPusher: (sessionToken: string) => void
}>(null)

export const PusherWrapper = ({ children }) => {
  const { sendAnalyticsEvent } = API.useAnalytics()
  const [pusher, setPusher] = useState<Pusher>(null)
  const { data: profile } = useGetProfile()

  const initPusher = useCallback((sessionToken) => {
    if (Constants.E2E || Constants.INTEGRATION_TEST) return

    const newPusherInstance = new Pusher(Project.pusherKey, {
      cluster: 'eu',
      channelAuthorization: {
        endpoint: `${Project.api}pusher/auth`,
        transport: 'ajax',
        headers: { 'AUTHORIZATION': `Bearer ${sessionToken}` },
      },
    })

    setPusher(newPusherInstance)
  }, [])

  useEffect(() => {
    if (pusher && profile) {
      pusher.connection.bind('connected', () => {
        sendAnalyticsEvent({
          eventName: 'pusher.connection-successful',
          eventProperties: {
            platform: API.platform === 'web' ? 'web' : 'mobile',
          },
        })
      })
      pusher.connection.bind('disconnected', () => {
        sendAnalyticsEvent({
          eventName: 'pusher.connection-closed',
          eventProperties: {
            platform: API.platform === 'web' ? 'web' : 'mobile',
          },
        })
      })
    }
  }, [profile, pusher])

  useEffect(() => {
    if (pusher && !profile?.id) {
      if (pusher.connection?.state === 'connected') pusher.disconnect()
      setPusher(null)
    }
  }, [profile?.id])

  return (
    <PusherContext.Provider value={{ pusher, initPusher }}>
      {children}
    </PusherContext.Provider>
  )
}
