import { Type, plainToInstance } from 'class-transformer'
import { Entity } from '@core/entities/entity'
import { User } from '@core/entities/user'
import { IPermitFinalSignOffReview } from '@core/entities/permit/PermitFinalSignOffReview'
import { PermitFinalSignOffReviewContent } from '@core/entities/permit/PermitFinalSignOffReviewContent'

export class PermitFinalSignOffReview extends Entity<IPermitFinalSignOffReview> {
  @Type(() => PermitFinalSignOffReviewContent)
  finalSignOff: PermitFinalSignOffReviewContent

  @Type(() => User)
  createdBy: User

  createdOn: string

  public static new(payload: unknown): PermitFinalSignOffReview {
    const entity = plainToInstance(PermitFinalSignOffReview, payload)

    return entity
  }
}
