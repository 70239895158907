import { plainToInstance, Type } from 'class-transformer'
import { Entity } from '@core/entities/entity'
import { TemplateRuleCondition } from '../TemplateRuleCondition'
import { ITemplateRule } from './ITemplateRule'

export class TemplateRule extends Entity<ITemplateRule> {
  @Type(() => TemplateRuleCondition)
  conditions?: Array<TemplateRuleCondition>

  shownQuestions?: Array<{ questionId?: number | string }>

  approvals?: Array<{ id: string }>

  public static new(payload: unknown): TemplateRule {
    const entity = plainToInstance(TemplateRule, payload)

    return entity
  }
}
