import React, { useEffect } from 'react'
import { beamerInitialize } from 'react-beamer'
import { Profile } from '@core/entities/profile'

type ComponentType = {
  children: React.ReactNode
  profile: Profile
}

const BeamerWrapper: React.FC<ComponentType> = ({ children, profile }) => {
  // We don't init beamer if the user is an E2E test user so that we don't get charged for it
  useEffect(() => {
    if (!Constants.E2E && !!profile) {
      // We add the all_users filter so that we can target all users with Beamer posts
      const filters = ['all_users']
      if (
        profile?.email?.includes('@siteassist.co') &&
        profile?.email?.includes('testing.')
      ) {
        // For testing users we add the testing_user filter so that we can test Beamer posts without it showing up for real users
        filters.push('testing_users')
      }

      beamerInitialize(
        'zWSDkvQs50243',
        'https://app.getbeamer.com/js/beamer-embed.js',
        {
          display: 'none',
          selector: '.beamerButton',
          user_firstname: profile.firstName,
          user_lastname: profile.lastName,
          user_email: profile.email,
          user_id: profile.id,
          filter: filters.join(';'),
        },
      )
    }
  }, [profile])

  if (Constants.E2E) {
    return null
  }

  return <div className='beamerButton'>{children}</div>
}

export default BeamerWrapper
