import { localizedStrings } from '@core/strings'
import React from 'react'
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@common/material'
import ClearIcon from '@mui/icons-material/Clear'
import { Alert } from '@common/material'
import { AlertColor } from '@mui/material/Alert/Alert'
import { ModalDialogContainer } from '@common/modal/ModalDialogContainer'

type ComponentType = {
  title: string
  text: string
  onDismissClick: () => void
  okText?: string
  severity?: AlertColor
}

const AlertModal: React.FC<ComponentType> = ({
  title,
  text,
  onDismissClick,
  okText = localizedStrings.confirm,
  severity = 'info',
}) => {
  return (
    <ModalDialogContainer preventDialogClose>
      {title && (
        <DialogTitle
          variant='h5'
          display='flex'
          justifyContent='space-between'
          alignItems='center'
          sx={{ pr: 1, fontSize: 20 }}
        >
          {title}
          <IconButton aria-label='close-modal' onClick={onDismissClick}>
            <ClearIcon />
          </IconButton>
        </DialogTitle>
      )}

      {text && (
        <DialogContent>
          <Alert severity={severity}>{text}</Alert>
        </DialogContent>
      )}

      <DialogActions sx={{ pb: 2, pr: 3 }}>
        <Button
          variant='contained'
          onClick={onDismissClick}
          data-test='modalAlertOkBtn'
        >
          {okText}
        </Button>
      </DialogActions>
    </ModalDialogContainer>
  )
}

export default AlertModal
