import { IPermitTemplateFinalSignOffData } from '@core/entities/permit-template-editor'
import { Entity } from '@core/entities/entity'
import { TemplateForm } from '@core/entities/template/TemplateForm'
import { plainToInstance, Type } from 'class-transformer'

export class PermitTemplateFinalSignOffData extends Entity<IPermitTemplateFinalSignOffData> {
  id?: string
  label: string
  allowDeletion: boolean
  @Type(() => TemplateForm)
  finalSignOffQuestions?: TemplateForm

  public static new(payload: unknown): PermitTemplateFinalSignOffData {
    const entity = plainToInstance(PermitTemplateFinalSignOffData, payload)
    return entity
  }
}
