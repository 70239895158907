import { plainToInstance } from 'class-transformer'
import { IRegisterTemplateProperty } from './IRegisterTemplateProperty'
import { Entity } from '../../entity'
import { RegisterTemplatePropertyType } from './types'

export class RegisterTemplateProperty extends Entity<IRegisterTemplateProperty> {
  type: RegisterTemplatePropertyType
  id: number
  name: string
  required?: boolean
  key?: string
  selectConfig?: {
    uuid: string
    label: string
    key: string
    colour: string
  }[]
  parentTemplate?: {
    id: number
    name: string
    properties: {
      id: string
      name: string
      type: RegisterTemplatePropertyType
    }[]
  }
  formulaConfig?: {
    function: any[]
  }
  prefix?: string
  delimiter?: string
  incrementByParent?: string
  padToNumberOfCharacters?: number
  acceptedNumberValues?: { value: number }[]
  isLinked: boolean
  canBeOverridden?: boolean
  lockedAfterCreation?: boolean

  public static new(payload: unknown): RegisterTemplateProperty {
    const entity = plainToInstance(RegisterTemplateProperty, payload)

    return entity
  }
}
