import ProjectType from '@core/project-type'
import { ProjectCommon } from '@core/project-common'

const Project: ProjectType = {
  ...ProjectCommon,
  pspdfkitLicenseKey:
    'BfMlHQw0EV9XkCz3YtTO4n4Dy4AWZJAPJ0VulkAsd7y84JmMc2W6U_sS8z295NYCGZnf8W1WmZOkzrDV860YX34zUfvcZ2EBJu_yK6U6kIy6jWuMwu6g2PZGRxIuHAMe7xWWRa-jLVC83adinmrvQj5_tbUuu4yTAg1L9IX14afGly16-anopUCn_b56MHpCV4dhem9OuJamd3vQSCEgsD-VtDUD7CjIh-aweuJ3vHwyGz5CnAsSU0fSOuVEH-3VV7lB--uQn4-i0FNhbGWb48epRbj8F1OpIp0qh8_0nIEVzfb9fl3wJI5bKkPLK4hfbw8jg7N0T9tpH6INmLUpFQXWIG5uAz2wzEuhHO8rArwOecvqpuBd1T21m4CRpvyToAMxTZ7T8X56ra5cDFjdqojO_K2PQ-zaAG2QUouLGIBwrH0a6WMInt8J7MStfneYsYzga3W4inKS2Z3FdfIW2Z63tjqrSXFg7D_EISErKFmpPf_3Rrn3ttIl_T8E-3hoS60D_0hlnUPWAU6L0Yfz1XcVPk68OeNAjFA3ZjomMIV4_7uOfuFz11uXbE5CoJ5T9TSjKHAyI-M75-wRNSejmIwBwXXbhsn_EL3Ti6sY3ORdth1zqVV0owjZWJFM3icm',
}

// @ts-ignore
global.Project = Project

export default Project
