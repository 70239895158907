import {
  createMutationKeys,
  createQueryKeys,
  mergeQueryKeys,
} from '@lukemorales/query-key-factory'
import { RequestTypes } from '@core/react-query/features/notifications/requestTypes'
import { APIAxiosInstance } from '@core/utils/api-axios-instance'
import { createQueryKeyString } from '@core/react-query/queryParams'

const notificationsQueryKeys = createQueryKeys('notifications', {
  getNotifications: ({
    userId,
    page,
    ...payload
  }: RequestTypes['getNotifications']) => {
    return {
      queryKey: [userId, payload.status, page, payload.size],
      queryFn: async ({ pageParam = 1 }) => {
        const queryParams = createQueryKeyString({
          page: page ? page : pageParam,
          ...payload,
        })

        const { data } = await APIAxiosInstance.get(
          `users/${userId}/notifications${queryParams}`,
        )

        return data
      },
    }
  },
  getNotificationsUnseenCounter: ({
    userId,
  }: RequestTypes['getNotificationsUnseenCounter']) => {
    return {
      queryKey: [userId],
      queryFn: async () => {
        const { data } = await APIAxiosInstance.get(
          `users/${userId}/notifications/counter`,
        )

        return data
      },
    }
  },
})

const notificationsMutationKeys = createMutationKeys('notifications', {
  updateNotification: {
    mutationKey: null,
    mutationFn: async ({
      userId,
      notificationId,
      read,
    }: RequestTypes['updateNotification']) => {
      const { data } = await APIAxiosInstance.put(
        `users/${userId}/notifications/${notificationId}`,
        { read },
      )

      return data
    },
  },
  deleteNotification: {
    mutationKey: null,
    mutationFn: async ({
      userId,
      notificationId,
    }: RequestTypes['deleteNotification']) => {
      const { data } = await APIAxiosInstance.delete(
        `users/${userId}/notifications/${notificationId}`,
      )

      return data
    },
  },
  markAllNotificationsAsRead: {
    mutationKey: null,
    mutationFn: async ({
      userId,
    }: RequestTypes['markAllNotificationsAsRead']) => {
      const { data } = await APIAxiosInstance.put(
        `users/${userId}/notifications`,
        { read: true },
      )

      return data
    },
  },
  markAllNotificationsAsSeen: {
    mutationKey: null,
    mutationFn: async ({
      userId,
    }: RequestTypes['markAllNotificationsAsSeen']) => {
      const { data } = await APIAxiosInstance.put(
        `users/${userId}/notifications/counter`,
      )

      return data
    },
  },
})

export const notificationsKeys = mergeQueryKeys(
  notificationsQueryKeys,
  notificationsMutationKeys,
).notifications
