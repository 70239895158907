import { plainToInstance, Type } from 'class-transformer'

import { TemplateFormAnswers } from '@core/entities/template/TemplateFormAnswers'
import { Entity } from '@core/entities/entity'
import { IPermitApproval } from '@core/entities/permit/PermitApproval/IPermitApproval'
import { TemplateForm } from '@core/entities/template/TemplateForm'
import { ApprovalStage } from '@core/entities/permit/PermitApprovalReviews'

export class PermitApproval extends Entity<IPermitApproval> {
  id: string

  label: string

  allowDeletion: boolean

  @Type(() => TemplateForm)
  approvalQuestions: TemplateForm

  @Type(() => TemplateFormAnswers)
  approvalAnswers: TemplateFormAnswers

  approvalStage: ApprovalStage

  public static new(payload: unknown): PermitApproval {
    const entity = plainToInstance(PermitApproval, payload)

    return entity
  }
}
