import { Entity } from '@core/entities/entity'
import { IPermitNominatedApprovers } from '@core/entities/permit/PermitNominatedApprovers/IPermitNominatedApprovers'
import { plainToInstance, Type } from 'class-transformer'
import { PermitNominateApprover } from '@core/entities/permit/PermitNominateApprover'
import { SuggestedApprover } from '@core/entities/permit/SuggestedApprover'

export class PermitNominatedApprovers extends Entity<IPermitNominatedApprovers> {
  @Type(() => PermitNominateApprover)
  approvers: Array<PermitNominateApprover>

  @Type(() => SuggestedApprover)
  suggestedApprovers: Array<SuggestedApprover>

  public static new(payload: unknown): PermitNominatedApprovers {
    const entity = plainToInstance(PermitNominatedApprovers, payload)

    return entity
  }
}
