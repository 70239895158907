import { plainToInstance, Type } from 'class-transformer'
import { truncate } from 'lodash'
import { Entity } from '@core/entities/entity'
import { PermitMultipleChoiceAnswer } from '../../permit/PermitMultipleChoiceAnswer'
import { ITemplateQuestion } from './ITemplateQuestion'
import {
  RequiredOptionalType,
  SubmissionDocument,
  SubmissionProof,
} from '@core/entities/template/TemplateQuestion/types'
import {
  LinkedChecklistResult,
  LinkedPermitResult,
} from '@core/entities/linkedProcesses'
import { ITemplateQuestionGroup } from '../TemplateQuestionGroup'

import { RegisterTemplateProperty } from '@core/entities/register/RegisterTemplateProperty'
import { PermitStatus } from '@core/entities/permit/PermitBase'
import { QuestionType } from '@core/entities/template/QuestionType'
import { ChecklistStatus } from '@core/entities/checklist/Checklist'

export class TemplateQuestion extends Entity<ITemplateQuestion> {
  id: string

  questionType: QuestionType

  body: string

  required?: boolean

  requiresSignature?: RequiredOptionalType

  requiresProof?: RequiredOptionalType

  requiresAdditionalInformation?: RequiredOptionalType

  requireProofFromCamera?: boolean

  minAnswers?: number

  maxAnswers?: number

  @Type(() => PermitMultipleChoiceAnswer)
  answers?: Array<PermitMultipleChoiceAnswer>

  validAnswer?: string

  invalidAnswer?: string

  invalidDescription?: string

  acknowledgeInformationDocumentKey?: string

  acknowledgeInformationDocumentDownloadUrl?: string

  dataTableId?: number

  permitTemplateLinkingIds?: Array<number>
  permitTemplateLinkingStatuses?: Array<PermitStatus>

  checklistTemplateLinkingIds?: Array<number>
  checklistTemplateLinkingStatuses?: Array<ChecklistStatus>

  //from this point only the answered questions have these types
  invalid?: boolean
  submissionText?: string
  signatureKey?: string
  signatureUrl?: string
  submissionProofs?: Array<SubmissionProof>
  submissionDocuments?: Array<SubmissionDocument>
  acknowledged?: boolean
  isHidden?: boolean

  linkedPermitsIds?: Array<number>
  @Type(() => LinkedPermitResult)
  linkedPermitsResults?: Array<LinkedPermitResult>

  linkedChecklistsIds?: Array<number>
  @Type(() => LinkedChecklistResult)
  linkedChecklistsResults?: Array<LinkedChecklistResult>

  //from this point only the answered questions have these types
  localSignature?: string
  localSubmissionProofs?: Array<File>
  localSubmissionDocuments?: Array<File>

  @Type(() => RegisterTemplateProperty)
  registerEditPropertyIdResult?: RegisterTemplateProperty

  @Type(() => RegisterTemplateProperty)
  registerExtractPropertiesResults?: Array<RegisterTemplateProperty>
  public static new(payload: unknown): TemplateQuestion {
    const entity = plainToInstance(TemplateQuestion, payload)

    return entity
  }

  public static setLinkedProcessesIds(
    templateQuestionGroupArray: Array<ITemplateQuestionGroup>,
  ) {
    templateQuestionGroupArray.forEach((questionGroup) =>
      questionGroup.data.forEach((question) => {
        if (question.questionType === 'LINK_PERMITS') {
          question.linkedPermitsIds = question.linkedPermitsResults?.map(
            (linkedPermitResult) => linkedPermitResult.id,
          )
        }
        if (question.questionType === 'LINK_CHECKLISTS') {
          question.linkedChecklistsIds = question.linkedChecklistsResults?.map(
            (linkedChecklistResult) => linkedChecklistResult.id,
          )
        }
      }),
    )
  }

  public static getQuestionTypesList(): Array<QuestionType> {
    return [
      'ALPHANUMERIC',
      'DATE',
      'MULTIPLE_CHOICE',
      'NUMERIC',
      'PRE_CONDITION',
      'DOCUMENT',
      'INFO',
      'ACKNOWLEDGE_INFORMATION',
      'LINK_PERMITS',
      'LINK_CHECKLISTS',
      'REGISTER_ITEM_EXTRACT_PROPERTIES',
      'EDIT_REGISTER_ITEM_PROPERTY_VALUE',
    ]
  }

  public static getQuestionIndexName({
    groupIndex,
    questionIndex,
    questionGroup,
  }): string {
    return `Question${questionGroup ? ' Group ' : ' '}${groupIndex + 1}${
      questionGroup ? '' : `-${questionIndex + 1 || 1}`
    }`
  }

  public static getBodyFormattedText(body: string): string {
    const maxLength = 30
    const bodyWithoutHtmlTags = body
      .replace(/<\/?[^>]+(>|$)/g, '')
      .replace(/&nbsp;/g, '')

    return truncate(bodyWithoutHtmlTags, { length: maxLength })
  }

  public getBodyFormattedText(): string {
    return TemplateQuestion.getBodyFormattedText(this.body)
  }
}
