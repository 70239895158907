import { RequestTypes } from '@core/react-query/features/media'
import {
  APIAxiosInstance,
  headersWithAccessToken,
} from '@core/utils/api-axios-instance'

// Helper function to retry a request
const retryRequest = async (
  fn: () => Promise<any>,
  retries: number,
  delay: number,
): Promise<any> => {
  let lastError: any
  for (let i = 0; i < retries; i++) {
    try {
      return await fn()
    } catch (error) {
      lastError = error
      if (i < retries - 1) {
        await new Promise((resolve) => setTimeout(resolve, delay))
      }
    }
  }
  throw lastError
}

// Modified s3Upload function with retry functionality
export const s3Upload = async ({
  file,
  folderName,
  accessToken,
  retries = 3,
  delay = 1000,
}: RequestTypes['uploadMedia'] & { retries?: number; delay?: number }) => {
  const headers = headersWithAccessToken({ accessToken })

  const params = {
    name: `${Date.now().valueOf()}-${file.name}`,
  }

  const uploadToS3 = async () => {
    const { data } = await APIAxiosInstance.post(
      `media/upload/${folderName}`,
      params,
      { headers },
    )

    //!!!Hack Alert: We are using fetch instead of axios for uploading files and media
    // TODO: Find why axios is not working properly for media and file upload
    const fetchOptions = {
      method: 'PUT',
      headers: {
        'content-type': file.type,
      },
      body: file as File,
    }
    await fetch(data.url, fetchOptions)

    return data.id
  }

  return retryRequest(uploadToS3, retries, delay)
}
