import { RequestErrorType } from '@core/utils/api-axios-instance'
import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query'
import {
  notificationsKeys,
  RequestTypes,
} from '@core/react-query/features/notifications'
import { useMutateQueryData } from '@core/react-query/hooks'

export const useUpdateNotification = (
  options?: UseMutationOptions<
    unknown,
    RequestErrorType,
    RequestTypes['updateNotification']
  >,
) => {
  const queryClient = useQueryClient()
  const { updateInfiniteQueryItem } = useMutateQueryData(queryClient)

  return useMutation({
    ...notificationsKeys.updateNotification,
    ...options,
    onMutate: (...params) => {
      const [{ userId, notificationId, read }] = params

      updateInfiniteQueryItem(
        notificationsKeys.getNotifications({ userId }).queryKey.slice(0, -3),
        { id: notificationId, isRead: read },
        { mergeWithExisting: true },
      )

      options?.onMutate?.(...params)
    },
  })
}
