import { Entity } from '@core/entities/entity'
import { IOwnedByRegisterItem } from '@core/entities/register/OwnedByRegisterItem/IOwnedByRegisterItem'
import { RegisterItem } from '@core/entities/register/RegisterItem'
import { Register } from '@core/entities/register/Register'
import { Type } from 'class-transformer'

export class OwnedByRegisterItem extends Entity<IOwnedByRegisterItem> {
  @Type(() => RegisterItem)
  registerItem: RegisterItem

  @Type(() => Register)
  register: Register
}
