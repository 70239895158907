import React from 'react'
import { Typography, TypographyProps, SxProps, Tooltip } from '@mui/material'

type TextProps = TypographyProps & SxProps & { showTooltip?: boolean }

const Text: React.FC<TextProps> = React.forwardRef(
  ({ children, showTooltip, ...props }, ref) => (
    <Tooltip title={children} arrow disableHoverListener={!showTooltip}>
      <Typography ref={ref} {...props}>
        {children}
      </Typography>
    </Tooltip>
  ),
)

export { Text }
