import React from 'react'
import { BoxProps } from '@fundamentals'
import MapOptions = google.maps.MapOptions

export type MarkerProps = BoxProps & {
  lat: number
  lng: number
  onClick?: (markerIndex: number | string) => void
  markerIndex?: number | string
  children?: React.ReactNode
}

export type MapProps = BoxProps & {
  initialCoordinates: { lat: number; lng: number }
  initialZoom?: number
  onClick?: (data) => void
  onBoundsChange?: ({ center, zoom, bounds, marginBounds }) => void
  options?: MapOptions
  fitMarkers?: boolean
  readOnly?: boolean
  ref?: any
  markers?: Array<MarkerProps>
}
