import { useMemo } from 'react'

export const useHtml = (html) => {
  const processedHtml = useMemo(() => {
    const el = document.createElement(`div`)
    el.innerHTML = html
    const anchors = el.getElementsByTagName('a')
    Array.from(anchors).forEach((anchor) => {
      anchor.setAttribute('target', '_blank')
      anchor.setAttribute('rel', 'noopener noreferrer')
    })
    return el.innerHTML.split('\n')?.join(' <br> ')
  }, [html])
  return { html: processedHtml }
}
