import { Type, plainToInstance } from 'class-transformer'
import { Entity } from '@core/entities/entity'
import { IPermitStageDrafts } from '@core/entities/permit/PermitStageDrafts/IPermitStageDrafts'
import { TemplateFormAnswers } from '@core/entities/template/TemplateFormAnswers'

export class PermitStageDrafts extends Entity<IPermitStageDrafts> {
  id: string

  permitId: number

  referenceObjectId: string

  stage: 'APPROVALS'

  createdOn: string

  modifiedOn: string

  @Type(() => TemplateFormAnswers)
  answers: TemplateFormAnswers

  public static new(payload: unknown): PermitStageDrafts {
    const entity = plainToInstance(PermitStageDrafts, payload)

    return entity
  }
}
