import { TemplateRuleCondition } from '@core/entities/template/TemplateRuleCondition'
import { IPermitTemplateFinalSignOffRule } from '@core/entities/permit-template-editor/IPermitTemplateFinalSignOffRule'
import { Entity } from '@core/entities/entity'
import { Type } from 'class-transformer'

export class PermitTemplateFinalSignOffRule extends Entity<IPermitTemplateFinalSignOffRule> {
  @Type(() => TemplateRuleCondition)
  conditions?: Array<TemplateRuleCondition>
  shownQuestions?: {
    questionId: string
  }[]
}
