import { localizedStrings } from '@core/strings'
import '@core/project'
import BaseUtils from './base/_utils'
import moment from 'moment'
import axios, { AxiosResponse } from 'axios'
import { PermitSort, PermitStatus } from '@core/entities/permit/PermitBase'
import { TemplateEditorPermitTemplateVersionStatus } from '@core/entities/permit-template-editor/types'
import { PermitTemplateStatus } from '@core/entities/checklist/ChecklistTemplateVersion/types'
import { APIAxiosInstance } from './legacy-api-axios-instance'

//@ts-ignore
const Utils = (global.Utils = Object.assign({}, BaseUtils, {
  getAvatarUrl(url?: string) {
    return url || '/images/placeholders/avatar.svg'
  },
  // numToWords :: (Number a, String a) => a -> String
  getPermitStatusColor(status: PermitStatus) {
    switch (status) {
      case 'REQUESTED':
      case 'AWAITING_FINAL_SIGN_OFF':
      case 'AWAITING_CHECKS':
      case 'SENT_TO_PERMIT_HOLDER':
      case 'SUSPENDED_RESUME_REQUESTED':
      case 'EXTENSION_REQUESTED':
        return 'warning'

      case 'ISSUED':
        return 'info'

      case 'CANCELLED':
      case 'UNSIGNED':
      case 'DISCARDED':
      case 'EXPIRED':
      case 'REJECTED':
      case 'SUSPENDED':
        return 'error'

      case 'OPEN':
      case 'SIGNED':
        return 'success'

      case 'CLOSED':
        return 'primary'

      default:
        return 'primary'
    }
  },
  getTextForStatus(status: PermitStatus): string {
    switch (status) {
      case 'REQUESTED':
        // We have mapped the Requested state to Awaiting Approval on the front-end
        // This will be renamed in the back-end in the future
        return localizedStrings.awaitingApproval
      case 'REJECTED':
        return localizedStrings.rejected
      case 'DISCARDED':
        return localizedStrings.discarded
      case 'ISSUED':
        return localizedStrings.preStart
      case 'EXPIRED':
        return localizedStrings.expired
      case 'AWAITING_CHECKS':
        return localizedStrings.awaitingPostPermitChecks
      case 'AWAITING_FINAL_SIGN_OFF':
        return localizedStrings.awaitingFinalSignOff
      case 'CLOSED':
        return localizedStrings.closed
      case 'UNSIGNED':
        return localizedStrings.unsigned
      case 'SIGNED':
        return localizedStrings.signed
      case 'EXTENSION_REQUESTED':
        return localizedStrings.requestedExtension
      case 'CANCELLED':
        return localizedStrings.cancelled
      case 'OPEN':
        return localizedStrings.open
      case 'SENT_TO_PERMIT_HOLDER':
        return localizedStrings.sentToPermitHolder
      case 'SUSPENDED':
        return localizedStrings.suspended
      case 'SUSPENDED_RESUME_REQUESTED':
        return localizedStrings.resumeRequested
      default:
        return status
    }
  },
  getTextForPermitSort(sort: PermitSort) {
    switch (sort) {
      case PermitSort.STARTS_ON_ASCENDING:
        return localizedStrings.startsOnAscending
      case PermitSort.STARTS_ON_DESCENDING:
        return localizedStrings.startsOnDescending
      case PermitSort.ENDS_ON_ASCENDING:
        return localizedStrings.endsOnAscending
      case PermitSort.ENDS_ON_DESCENDING:
        return localizedStrings.endsOnDescending
      default:
        return 'Unknown'
    }
  },
  getTemplateStatusColor(status: PermitTemplateStatus) {
    switch (status) {
      case 'PUBLISHED':
        return 'success'
      case 'UNPUBLISHED':
        return 'error'
      case 'ARCHIVED':
        return 'default'
      default:
        return 'default'
    }
  },
  getTemplateStatusText(status: PermitTemplateStatus) {
    switch (status) {
      default:
      case 'PUBLISHED':
        return 'Published'
      case 'UNPUBLISHED':
        return 'Unpublished'
      case 'ARCHIVED':
        return 'Archived'
    }
  },
  getPermitTemplateVersionStatusColor(
    status: TemplateEditorPermitTemplateVersionStatus,
  ) {
    switch (status) {
      case 'ACTIVE':
        return 'success'
      case 'ARCHIVED':
        return 'default'
      case 'DRAFT':
        return 'default'
      default:
        return 'default'
    }
  },
  getPermitTemplateVersionStatusText(
    status: TemplateEditorPermitTemplateVersionStatus,
  ) {
    switch (status) {
      case 'ACTIVE':
        return 'Active'
      case 'ARCHIVED':
        return 'Archived'
      case 'DRAFT':
        return 'Draft'
      default:
        return 'Draft'
    }
  },
  getDate(startDate: string, endDate: string) {
    const duration = moment.duration(moment(endDate).diff(startDate))
    const diffDays = Math.floor(duration.asDays())
    const diffHours = duration.hours()
    const diffMins = duration.minutes()
    const parts = [
      diffDays ? `${diffDays}${localizedStrings.daysShort}` : '',
      diffHours ? `${diffHours}${localizedStrings.hoursShort}` : '',
      diffDays
        ? ''
        : diffMins
        ? `${diffMins}${localizedStrings.minsShort}`
        : '',
    ]
      .filter((v) => !!v)
      .join(' ')

    return {
      hasStarted: moment(startDate).valueOf() <= new Date().valueOf(),
      hasEnded: moment(endDate).valueOf() <= new Date().valueOf(),
      dateString: moment(startDate).format('Do MMM'),
      timeString: moment(startDate).format('HH:mm'),
      endDateString: moment(endDate).format('Do MMM'),
      endTimeString: moment(endDate).format('HH:mm'),
      duration: parts,
    }
  },
  secondsToDhm(seconds: number) {
    seconds = Number(seconds)
    const d = Math.floor(seconds / (3600 * 24))
    const h = Math.floor((seconds % (3600 * 24)) / 3600)
    const m = Math.floor((seconds % 3600) / 60)

    const dDisplay = d > 0 ? d : 0
    const hDisplay = h > 0 ? h : 0
    const mDisplay = m > 0 ? m : 0
    return { days: dDisplay, hours: hDisplay, minutes: mDisplay }
  },
  sliceIntoChunks(arr: [], chunkSize: number) {
    const res = []
    for (let i = 0; i < arr.length; i += chunkSize) {
      const chunk = arr.slice(i, i + chunkSize)
      res.push(chunk)
    }
    return res
  },
  processQuestionBodyHTML(body?: string) {
    if (!body) {
      return ''
    }

    const el = document.createElement(`div`)
    el.innerHTML = body
    const anchors = el.getElementsByTagName('a')
    for (let i = 0, max = anchors.length; i < max; i++) {
      const anchor = anchors.item(i)
      anchor.setAttribute('target', '_blank')
      anchor.setAttribute('rel', 'noopener noreferrer')
    }
    return el.innerHTML
  },
  pollForSuccessfulRequest: async (
    url,
    useAPIAxiosInstance = false,
    statusCheckCallback = (status, data) => status >= 200 && status < 300,
    attempt = 0,
    totalPollTime = 60000,
    pollTime = 500,
  ) => {
    const attemptLimit = totalPollTime / pollTime
    if (attempt > attemptLimit) return

    const { data, status } = await new Promise<AxiosResponse>((resolve) => {
      setTimeout(() => {
        let response: Promise<AxiosResponse<any, any>>
        if (useAPIAxiosInstance) {
          response = APIAxiosInstance.get(url)
        } else {
          response = axios.get(url, { headers: { Range: 'bytes=0-0' } })
        }

        response
          .then((response) => resolve(response))
          .catch((error) => resolve(error.response))
      }, pollTime)
    })

    if (statusCheckCallback(status, data)) {
      return data
    }
    return Utils.pollForSuccessfulRequest(
      url,
      useAPIAxiosInstance,
      statusCheckCallback,
      attempt + 1,
      totalPollTime,
      pollTime,
    )
  },
  toTitleCase: (str) => {
    return str
      .toLowerCase()
      .replace(/(?:^|\s|_)\w/g, function (match) {
        return match.toUpperCase()
      })
      .replace('_', ' ')
  },
}))

export default Utils
