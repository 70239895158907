import * as React from 'react'
import { Box } from '.'

const Form = ({ children, ...props }) => {
  return (
    <Box component='form' {...props}>
      {children}
    </Box>
  )
}

export { Form }
