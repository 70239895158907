import { FunctionComponent } from 'react'
import { FlagsmithProvider } from 'flagsmith/react'
import { useGetProfile } from '@core/react-query/features/profile/hooks/useGetProfile' // we need this to make JSX compile
import flagsmith from 'flagsmith'
import { useNavigationContext } from '@common/hooks'
import { useDeepCompareEffect } from 'use-deep-compare'

type ComponentType = {
  children: React.ReactElement
}

export const InitFlagsmith: FunctionComponent<ComponentType> = ({
  children,
}) => {
  // Don't init flagsmith in E2E tests
  if (Constants.E2E) {
    return children
  }

  const { organisationId } = useNavigationContext()

  const { data: profile } = useGetProfile()

  useDeepCompareEffect(() => {
    if (profile) {
      flagsmith.identify(profile.email)
      flagsmith.setTrait('email', profile.email)
      flagsmith.setTrait('organisationId', organisationId)
    }
  }, [profile?.email, organisationId])

  return (
    <FlagsmithProvider
      options={{
        enableLogs: false,
        preventFetch: true,
        cacheFlags: true,
        AsyncStorage: API.storage,
        environmentID: Project.flagsmith,
      }}
      flagsmith={API.flagsmith}
    >
      {/*// @ts-ignore*/}
      {children}
    </FlagsmithProvider>
  )
}

export default InitFlagsmith
