import { plainToInstance } from 'class-transformer'
import { Entity } from '@core/entities/entity'
import { ITemplateRuleCondition } from './ITemplateRuleCondition'

export class TemplateRuleCondition extends Entity<ITemplateRuleCondition> {
  questionId: string
  conjoiningOperator: 'AND' | 'OR'
  terms: Array<{ operator: 'IS'; answerId?: number | string }>

  public isAnswerUsedByTerm(answerId: string): boolean {
    return this.terms.some((term) => term.answerId === answerId)
  }

  public static new(payload: unknown): TemplateRuleCondition {
    const entity = plainToInstance(TemplateRuleCondition, payload)

    return entity
  }
}
