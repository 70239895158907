import { ISkiaRect } from '@core/entities/skia/SkiaRect/ISkiaRect'
import { plainToInstance } from 'class-transformer'
import { Point } from '@core/entities/skia/types'
import { SkiaShapeBase } from '@core/entities/skia/SkiaShapeBase/SkiaShapeBase'
import { SiteBoxDocumentAnnotation } from '@core/entities/SiteBoxDocumentAnnotation'

export class SkiaPolygon extends SkiaShapeBase<ISkiaRect> {
  type: 'POLYGON'
  points: Point[]

  public static new(payload: unknown): SkiaPolygon {
    return plainToInstance(SkiaPolygon, payload)
  }

  public static newFromSiteboxDocumentAnnotation(
    payload: SiteBoxDocumentAnnotation,
  ): SkiaPolygon {
    const transformToSkiaPolygon = {
      id: payload.uuid,
      type: 'POLYGON',
      points: payload.annotationData.points.map((point) => ({
        x: point[0],
        y: point[1],
      })),
    }

    return plainToInstance(SkiaPolygon, transformToSkiaPolygon)
  }

  isInBounds(point: Point): boolean {
    let isInside = false
    for (
      let i = 0, j = this.points.length - 1;
      i < this.points.length;
      j = i++
    ) {
      const xi = this.points[i].x,
        yi = this.points[i].y
      const xj = this.points[j].x,
        yj = this.points[j].y

      const intersect =
        yi > point.y !== yj > point.y &&
        point.x < ((xj - xi) * (point.y - yi)) / (yj - yi) + xi
      if (intersect) isInside = !isInside
    }
    return isInside
  }

  dragCorners(): Point[] {
    return this.points
  }

  resizeOnDrag({
    cornerIndex,
    x,
    y,
  }: {
    cornerIndex: number
    x: number
    y: number
  }) {
    this.points = this.points.map((point, index) => {
      if (index === cornerIndex) {
        return { x, y }
      }
      return point
    })
  }

  move({ x, y }: Point) {
    this.points = this.points.map((point) => ({
      x: point.x + x,
      y: point.y + y,
    }))
  }

  labelAnchor(): Point {
    return this.points.reduce(
      (acc, point) => {
        if (point.x < acc.x) acc.x = point.x
        if (point.y < acc.y) acc.y = point.y
        return acc
      },
      { x: Infinity, y: Infinity },
    )
  }

  registerUpdateCardAnchor(): Point {
    return this.points.reduce(
      (acc, point) => {
        if (point.x < acc.x) acc.x = point.x // Keep the smallest x (leftmost)
        if (point.y > acc.y) acc.y = point.y // Now keep the largest y (bottommost)
        return acc
      },
      { x: Infinity, y: -Infinity }, // Start with -Infinity for y to ensure we get the highest value
    )
  }
}
