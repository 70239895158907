import _ModalHeader from 'reactstrap/lib/ModalHeader'
import _ModalBody from 'reactstrap/lib/ModalBody'
import _Modal from 'reactstrap/lib/Modal'
import _ModalFooter from 'reactstrap/lib/ModalFooter'
import React from 'react'

export const ModalHeader = _ModalHeader
export const ModalFooter = _ModalFooter
export const Modal = (args) => <_Modal {...args} />
export const ModalBody = _ModalBody
