import { Type } from 'class-transformer'
import { Entity } from '@core/entities/entity'
import { ITemplateFormWrapper } from '@core/entities/template/TemplateFormWrapper/ITemplateFormWrapper'
import { TemplateForm } from '@core/entities/template/TemplateForm'
import { plainToInstance } from 'class-transformer'
import { User } from '@core/entities/user'

export class TemplateFormWrapper extends Entity<ITemplateFormWrapper> {
  questionsType: unknown

  @Type(() => TemplateForm)
  checklistQuestions: TemplateForm

  createdOn: string

  modifiedOn: string

  @Type(() => User)
  createdBy: User

  @Type(() => User)
  modifiedBy: User

  public static new(payload: unknown): TemplateFormWrapper {
    const entity = plainToInstance(TemplateFormWrapper, payload)

    return entity
  }
}
