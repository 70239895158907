import { useEffect, useRef, useState } from 'react'
import { useRouter } from 'next/router'
import moment from 'moment'

const OpenRoutesInNewTab = () => {
  const router = useRouter()
  const [keyPress, setKeyPress] = useState<moment.Moment>(null)
  // Define an array using refs.
  const pressedKeysRef = useRef([])

  const handleKeyDown = (e) => {
    // This allows for the array to be reset in case more than 1 values are added.
    // This is required due to key combinations such as `cmd + f`, where the `keyUp` event does not fire.
    if (pressedKeysRef.current.length > 1) {
      pressedKeysRef.current = []
      pressedKeysRef.current.push(e.key)
    } else {
      pressedKeysRef.current.push(e.key)
    }

    if (e.metaKey || e.ctrlKey) {
      setKeyPress(moment())
    } else {
      setKeyPress(null)
    }
  }

  const handleKeyUp = () => {
    pressedKeysRef.current = []
    setKeyPress(null)
  }

  useEffect(() => {
    const routeChangeStart = (route) => {
      if (
        keyPress &&
        pressedKeysRef.current.length <= 1 &&
        (pressedKeysRef.current.includes('Meta') ||
          pressedKeysRef.current.includes('Control')) &&
        // On macs the CMD button can be used to CMD + Tab to another app
        // If that happens, then the browser may not get the key up event for the CMD button
        // This tests ensures that the CMD button was pressed down at most 3 seconds in the past
        moment().diff(keyPress, 'seconds') <= 3
      ) {
        window.open(route, '_blank', 'noopener, noreferrer')
        router.events.emit('routeChangeError')
        throw 'SA_IGNORE_ERROR: cancel route change.'
      }
    }
    document.body.addEventListener('keydown', handleKeyDown)
    document.body.addEventListener('keyup', handleKeyUp)
    router.events.on('routeChangeStart', routeChangeStart)

    return () => {
      document.body.removeEventListener('keydown', handleKeyDown)
      document.body.removeEventListener('keyup', handleKeyUp)
      router.events.off('routeChangeStart', routeChangeStart)
    }
  }, [keyPress, router.asPath, router.events])

  return null
}

export default OpenRoutesInNewTab
