import { Entity } from '@core/entities/entity'
import { plainToInstance, Type } from 'class-transformer'
import { LinkedProcessItemTypes } from '@core/entities/linkedProcesses/types'
import {
  LinkedPermitResult,
  LinkedChecklistResult,
  ILinkedProcessItemResult,
  LinkedPeriodicChecksResult,
} from '@core/entities/linkedProcesses'

export class LinkedProcessItemResult extends Entity<ILinkedProcessItemResult> {
  id: number

  createdOn: string

  type: LinkedProcessItemTypes

  @Type(() => LinkedChecklistResult)
  linkedChecklistResult?: LinkedChecklistResult

  @Type(() => LinkedPermitResult)
  linkedPermitResult?: LinkedPermitResult

  @Type(() => LinkedPeriodicChecksResult)
  linkedPeriodicChecksResult?: LinkedPeriodicChecksResult

  public static new(payload: unknown): LinkedProcessItemResult {
    const entity = plainToInstance(LinkedProcessItemResult, payload)

    return entity
  }
}
