import { Card as MaterialCard, CardProps } from '@mui/material'
import { forwardRef } from 'react'

const Card: React.FC<CardProps> = forwardRef(
  ({ variant = 'outlined', ...props }, ref) => (
    <MaterialCard variant={variant} {...props} ref={ref} />
  ),
)

export { Card }
export type { CardProps }
