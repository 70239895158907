import {
  OrganisationLink,
  OrganisationPermission,
} from '@core/entities/organisation/types'
import { Entity } from '@core/entities/entity'
import { IOrganisation } from '@core/entities/organisation/IOrganisation'
import { plainToInstance } from 'class-transformer'

export class Organisation extends Entity<IOrganisation> {
  id?: number
  name: string
  logoUrl: string
  isEnabled: boolean
  links: OrganisationLink[]
  permittedActions: OrganisationPermission[]
  isVisibleTestOrganisation: boolean
  timezone: string

  public static new(payload: unknown): Organisation {
    const entity = plainToInstance(Organisation, payload)

    return entity
  }

  private hasPermission(permission: OrganisationPermission): boolean {
    return this.permittedActions.includes(permission)
  }

  public canChangeUserRole(): boolean {
    return this.hasPermission('UPDATE_MEMBER_ROLE')
  }

  public isSuperUser(rootRole: string): boolean {
    const allowedRoles = ['SUPER_ADMIN', 'SUPPORT_USER']
    return allowedRoles.includes(rootRole)
  }

  public canSeeLinkedOrganizations(rootRole: string): boolean {
    return this.isSuperUser(rootRole)
  }

  public canAddUser(): boolean {
    return this.hasPermission('INVITE_USER')
  }

  public canListTemplates(): boolean {
    return this.hasPermission('LIST_TEMPLATES')
  }

  public canViewTimeline(): boolean {
    return this.hasPermission('LIST_AUDITS')
  }

  public canCreateProjects(): boolean {
    return this.hasPermission('CREATE_PROJECTS')
  }

  public canSeeDetailsAndUsers(): boolean {
    return this.hasPermission('EDIT')
  }

  public canSeeBusinessUnits(): boolean {
    return this.hasPermission('LIST_BUSINESS_UNITS')
  }

  public canCreateBusinessUnits(): boolean {
    return this.hasPermission('CREATE_BUSINESS_UNITS')
  }

  public canSeeAllPermits(): boolean {
    return this.hasPermission('SEE_ALL_PERMITS')
  }

  public canCreateRegisterTemplate(): boolean {
    return this.hasPermission('CREATE_REGISTER_TEMPLATES')
  }

  public canEdit(): boolean {
    return this.hasPermission('EDIT')
  }

  public canSeeAuthorisationGroups(): boolean {
    return this.hasPermission('LIST_GROUPS')
  }

  public canEditAuthorisationGroups(): boolean {
    return this.hasPermission('CREATE_GROUPS')
  }
}
