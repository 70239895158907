import { plainToInstance, Type } from 'class-transformer'
import { TemplateFormAnswers } from '@core/entities/template/TemplateFormAnswers/TemplateFormAnswers'
import { PermitPermittedActions } from './types'
import { PermitBase } from '@core/entities/permit/PermitBase'
import { IPermit } from '@core/entities/permit/Permit/IPermit'
import { PermitSuspension } from '@core/entities/permit/PermitSuspension'
import { User } from '@core/entities/user'
import { ApprovalStage } from '@core/entities/permit/PermitApprovalReviews'
import { PermitLastExtension } from '../PermitLastExtension'
import { SubmissionProof } from '@core/entities/template/TemplateQuestion'
import { PermitWarning } from '@core/entities/template/TemplateWarnings'
import { PermitStageDrafts } from '../PermitStageDrafts'

export class Permit extends PermitBase<IPermit> {
  @Type(() => TemplateFormAnswers)
  permitHolderAnswers?: TemplateFormAnswers

  @Type(() => TemplateFormAnswers)
  authorisedPersonAnswers?: TemplateFormAnswers

  @Type(() => TemplateFormAnswers)
  postPermitChecksAnswers?: TemplateFormAnswers

  @Type(() => PermitSuspension)
  permitSuspensionResult?: PermitSuspension

  @Type(() => PermitLastExtension)
  lastExtension: PermitLastExtension

  @Type(() => User)
  approvedBy?: User

  @Type(() => User)
  rejectedBy?: User

  @Type(() => User)
  canceledBy?: User

  rejectReason?: string

  cancelReason?: string

  permittedActions: Array<PermitPermittedActions>
  permitInitiationAuthGroupsIds: Array<number>

  @Type(() => PermitBase)
  isCloneOf?: PermitBase

  @Type(() => PermitWarning)
  warnings: Array<PermitWarning>

  @Type(() => PermitStageDrafts)
  permitStageDrafts?: Array<PermitStageDrafts>

  public static new(payload: unknown): Permit {
    const entity = plainToInstance(Permit, payload)

    return entity
  }

  public getApproverAnswers(): Array<TemplateFormAnswers> {
    return (
      this.approvalReviews?.approvals
        .filter(
          (approval) =>
            approval.action === 'APPROVED' &&
            approval.approval.approvalAnswers?.isAnswered(),
        )
        .map((approval) => approval.approval.approvalAnswers) || []
    )
  }

  public getApproverAnswersByStage(
    approvalStage: ApprovalStage,
  ): Array<TemplateFormAnswers> {
    return (
      this.approvalReviews?.approvals
        .filter(
          (approval) =>
            approval.action === 'APPROVED' &&
            approval.approval.approvalStage === approvalStage &&
            approval.approval.approvalAnswers?.isAnswered(),
        )
        .map((approval) => approval.approval.approvalAnswers) || []
    )
  }

  public hasPermission(action: PermitPermittedActions): boolean {
    const basePermission = this.permittedActions.includes(action)
    switch (action) {
      case 'REQUEST_CHANGES':
        return (
          basePermission &&
          (!this.lastRequestedChange || !!this.lastRequestedChange.addressedBy)
        )
      default:
        return basePermission
    }
  }

  public hasAnyPermissions(actions: Array<PermitPermittedActions>): boolean {
    return actions.some((action) => this.hasPermission(action))
  }

  public hasAllPermissions(actions: Array<PermitPermittedActions>): boolean {
    return actions.every((action) => this.hasPermission(action))
  }

  public hasSuspensionReason(): boolean {
    return !!this.permitSuspensionResult
  }

  public getImageS3Keys(): string[] {
    const keys: string[] = []
    if (this.permitHolderAnswers) {
      keys.push(...this.permitHolderAnswers.getImageS3Keys())
    }
    if (this.authorisedPersonAnswers) {
      keys.push(...this.authorisedPersonAnswers.getImageS3Keys())
    }
    return keys
  }

  public getProofs(): SubmissionProof[] {
    const keys: SubmissionProof[] = []
    if (this.permitHolderAnswers) {
      keys.push(...this.permitHolderAnswers.getProofs())
    }
    if (this.authorisedPersonAnswers) {
      keys.push(...this.authorisedPersonAnswers.getProofs())
    }
    return keys
  }
}
