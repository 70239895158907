import { Entity } from '@core/entities/entity'
import { ILinkedPeriodicChecksResult } from '@core/entities/linkedProcesses'
import { plainToInstance, Type } from 'class-transformer'
import { User } from '@core/entities/user'

export class LinkedPeriodicChecksResult extends Entity<ILinkedPeriodicChecksResult> {
  id: number

  templatePeriodicCheckId: number

  name: string

  createdOn: string

  @Type(() => User)
  submittedBy: User

  public static new(payload: unknown): LinkedPeriodicChecksResult {
    const entity = plainToInstance(LinkedPeriodicChecksResult, payload)

    return entity
  }
}
