import { Dialog } from '@common/material'
import {
  backdropClasses,
  dialogClasses,
  DialogProps,
  modalClasses,
} from '@mui/material'
import React from 'react'
import { useModal } from '@common/GlobalModal'

type ModalContainerProps = Omit<DialogProps, 'open'> & {
  preventDialogClose?: boolean
}

export const ModalDialogContainer: React.FC<ModalContainerProps> = ({
  children,
  sx,
  onClose,
  preventDialogClose,
  ...rest
}) => {
  const { hideModal } = useModal()

  const disableE2ETransitionsClasses = {
    [`.${modalClasses.root}`]: {
      transition: 'none !important',
    },
    [`.${modalClasses.hidden}`]: {
      transition: 'none !important',
    },
    [`.${dialogClasses.paper}`]: {
      transition: 'none!important',
    },
    [`.${dialogClasses.container}`]: {
      transition: 'none!important',
    },
    [`.${dialogClasses.root}`]: {
      transition: 'none!important',
    },
    [`.${backdropClasses.root}`]: {
      transition: 'none!important',
    },
  }

  const defaultSx = {
    minWidth: '20rem',
  }

  return (
    <Dialog
      open={true}
      onClose={preventDialogClose ? () => {} : onClose || hideModal}
      sx={{
        ...defaultSx,
        ...sx,
        ...(Constants.E2E ? disableE2ETransitionsClasses : {}),
      }}
      fullWidth
      {...rest}
    >
      {children}
    </Dialog>
  )
}
