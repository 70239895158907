import React from 'react'

import { useGetProfile } from '@core/react-query/features/profile'
import { useRealTimeNotifications } from '@core/react-query/features/notifications'

const NotificationsRealtimeSubscription: React.FC<{}> = ({}) => {
  const { data: profile } = useGetProfile()
  useRealTimeNotifications(profile?.id, { enabled: !!profile?.id })

  return null
}

const MemoizedNotificationsRealtimeSubscription = React.memo(
  NotificationsRealtimeSubscription,
)

export { MemoizedNotificationsRealtimeSubscription as NotificationsRealtimeSubscription }
