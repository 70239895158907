import {
  createMutationKeys,
  createQueryKeys,
} from '@lukemorales/query-key-factory'
import { RequestTypes } from '@core/react-query/features/profile/requestTypes'
import { APIAxiosInstance } from '@core/utils/legacy-api-axios-instance'

export const profileKeys = createQueryKeys('profile', {
  profile: {
    queryKey: ['profile'],
    queryFn: async () => {},
    contextQueries: {
      byUserId: (payload: RequestTypes['getProfile']) => ({
        queryKey: [payload.userId],
        queryFn: async () => {
          if (!payload.userId) {
            return null
          }
          const { data } = await APIAxiosInstance.get(`users/me`)
          return data
        },
      }),
    },
  },
  getServerTime: ({}: RequestTypes['getServerTime']) => {
    return {
      queryKey: ['time'],
      queryFn: async () => {
        const { data } = await APIAxiosInstance.get(`users/me/time`, {
          timeout: 60000, // 1 minute
        })

        return data as { time: string }
      },
    }
  },
})

export const profileMutationKeys = createMutationKeys('profile', {})
