import { useContext, useEffect } from 'react'
import { PusherContext } from '@core/components/PusherWrapper'
import Pusher from 'pusher-js'

export type PusherOptions = {
  enabled?: boolean
}

type UsePusher = (args: PusherOptions) => Pusher
export const usePusher: UsePusher = ({ enabled = true }) => {
  const { pusher, initPusher } = useContext(PusherContext)
  const { E2E, INTEGRATION_TEST } = Constants

  useEffect(() => {
    if (pusher || !enabled || E2E || INTEGRATION_TEST) return
    API.auth.Cognito.getSession().then((session) => {
      if (session) initPusher(session.accessToken.jwtToken)
    })
  }, [enabled])

  return pusher
}
