import React, { createContext } from 'react'
import {
  DrawerAnchorTypes,
  DrawerVariantTypes,
} from '@common/GlobalDrawer/types'
import { WithDrawerLayout } from '@common/GlobalDrawer/WithDrawerLayout'
import { useDeepCompareMemo } from 'use-deep-compare'

export type DrawerOptions = {
  anchor?: DrawerAnchorTypes
  variant?: DrawerVariantTypes
  onClose?: () => void
}

type GlobalDrawerContext = {
  showDrawer: (component: React.ReactNode, options?: DrawerOptions) => void
  hideDrawer: () => void
}

const initialState: GlobalDrawerContext = {
  showDrawer: () => {},
  hideDrawer: () => {},
}

const GlobalDrawerContext = createContext(initialState)
export const useDrawer = () => React.useContext(GlobalDrawerContext)

export const GlobalDrawer: React.FC<{}> = ({ children }) => {
  const [drawerConfig, setDrawerConfig] = React.useState({
    open: false,
    component: null,
    options: null,
  })

  const showDrawer = (component: React.ReactNode, options?: DrawerOptions) => {
    setDrawerConfig({
      open: true,
      component,
      options,
    })
  }

  const hideDrawer = () => {
    setDrawerConfig({
      open: false,
      options: null,
      component: drawerConfig.component,
    })

    // This is to prevent the drawer from closing before the animation is complete
    // There were past issues caused by not resetting the component after the drawer is closed
    setTimeout(() => {
      setDrawerConfig({
        open: false,
        component: null,
        options: null,
      })
    }, 200)
  }

  const drawer = useDeepCompareMemo(
    () => drawerConfig.component,
    [drawerConfig],
  )

  return (
    <GlobalDrawerContext.Provider
      value={{
        showDrawer,
        hideDrawer,
      }}
    >
      <WithDrawerLayout
        open={drawerConfig.open}
        onClose={hideDrawer}
        component={drawer}
        anchor={drawerConfig.options?.anchor}
        variant={drawerConfig.options?.variant}
      >
        {children}
      </WithDrawerLayout>
    </GlobalDrawerContext.Provider>
  )
}
