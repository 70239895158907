import { Entity } from '@core/entities/entity'
import { IPermitTemplateVersionPeriodicChecklistResponse } from './IPermitTemplateVersionPeriodicChecklistResponse'
import { PermitTemplateVersionPeriodicChecklist } from '@core/entities/permit-template-editor/PermitTemplateVersionPeriodicChecklist'
import { plainToInstance, Type } from 'class-transformer'

export class PermitTemplateVersionPeriodicChecklistResponse extends Entity<IPermitTemplateVersionPeriodicChecklistResponse> {
  @Type(() => PermitTemplateVersionPeriodicChecklist)
  periodicChecklistsTemplates: PermitTemplateVersionPeriodicChecklist[]

  public static new(
    payload: unknown,
  ): PermitTemplateVersionPeriodicChecklistResponse {
    const entity = plainToInstance(
      PermitTemplateVersionPeriodicChecklistResponse,
      payload,
    )

    return entity
  }
}
