import jsCookie from 'js-cookie'
import { useFlags } from 'flagsmith/react'
import { IFlagsmithFeature } from 'flagsmith/src/types'

export type FeatureFlag =
  | 'project_documents'
  | 'mobile_debug'
  | 'force_update'
  | 'dangerous_force_update_testing_override'
  | 'test_feature_flag'
  | 'beta'
  | 'nest_api_testing'
  | 'plan_view_save_image'
  | 'mobile_keyboard_accessory'
  | 'mobile_open_pdf_in_webview'
  | 'mobile_pspdfkit_pdf_annotation'
  | 'mobile_pspdfkit_image_annotation'
  | 'mobile_codepush_status'
  | 'web_dashboard_download'
  | 'web_periodic_checklists'
  | 'web_beamer'
  | 'web_create_checklist_from_register'
  | 'web_permit_approval_stage'
  | 'prodigy_labelling'
  | 'web_checklist_template_roles'
  | 'web_rich_text_input'
  | 'mobile_rich_text_input'
  | 'mobile_caching_pdfs'
  | 'project_locations_web'
  | 'web_template_optional_final_signoff'
  | 'web_object_detection'
  | 'web_register_template_schedule'
  | 'ai_prefill_workflow'
  | 'web_project_plans'
  | 'mobile_project_plans'
  | 'web_register_templates'
  | 'web_register_linking_checklist'
  | 'checklist_form_refactor'
  | 'permit_form_refactor'
  | 'mobile_qr_code_scanner'
  | 'web_skia_poc'
  | 'permit_approver_form_refactor'
  | 'open_ai_analytics'
  | 'tablet_customised_view'
  | 'mobile_project_map_refactor'
  | 'mobile_maintenance_mode'
  | 'web_transfer_approval'
  | 'web_site_box'
  | 'web_register_template_diagram'
  | 'web_project_auth_group_refactor'
  | 'mobile_site_box'
  | 'mobile_prefetch_queries'
  | 'web_real_time_permit_details'
  | 'mobile_real_time_permit_details'
  | 'checklist_comments'
  | 'mobile_registers'
  | 'process_tags'
  | 'web_permit_approval_drafts'
  | 'web_register_template_inspections'
  | 'mobile_notifications'
  | 'web_notifications'
  | 'timeline_redesign'

const useFeatureFlags = <T extends FeatureFlag = FeatureFlag>(
  flags: T[],
): {
  flags: { [K in T]: IFlagsmithFeature }
} => {
  // In integration tests we don't have any feature flags on
  // If feature flags become relevant for integration tests then we should find a way to control the feature flags
  if (Constants.INTEGRATION_TEST) {
    // @ts-ignore
    return { flags: {} }
  }

  // Web E2E
  // In E2E tests the feature flags have to be set explicitly otherwise they are off
  if (!API.isMobile() && Constants.E2E) {
    const featuresCookie = jsCookie.get('sa.e2e.features')
    return {
      flags: featuresCookie ? JSON.parse(featuresCookie) : {},
    }
  } else if (Constants.E2E) {
    // @ts-ignore
    return { flags: {} }
  }

  const flagsmithFlags = useFlags(flags)

  // If a flag is not enabled we set it as false in the flags map
  // This allows users of this hook to use `flags.flagName` to check if a flag is enabled
  for (const key of Object.keys(flagsmithFlags)) {
    // @ts-ignore
    if (!flagsmithFlags[key]?.enabled) {
      // @ts-ignore
      flagsmithFlags[key] = false
    }
  }

  return {
    flags: flagsmithFlags,
  }
}

export default useFeatureFlags
