// import { RouteUrls } from "../../mobile/app/route-urls";
// allows e2e to set __DEV__, nextjs has build checks on __DEV__ so it is redeclared here
export const getConstants = (DEV) => {
  return {
    events: {
      LOGIN: { event: 'User login', category: 'User' },
      REGISTER: { event: 'User register', category: 'User' },
    },
    E2E: DEV && false,
    E2E_NAMESPACE: null,
    INTEGRATION_TEST: false,
    defaultLocale: 'en',
    // STORYBOOK: DEV && true,
    simulate: !DEV
      ? {}
      : {
          // authorisedForPermit: true,
          user: {
            // email: 'kyle@solidstategroup.com',
            // password: 'S3cur3P4ssW0rd!',
            email: '',
            password: '',
          },
          // CONFIRM_EMAIL: true,
          // FORCE_PAGE: RouteUrls?.PeriodicChecksDetailScreen,
          FORCE_LANGUAGE: false, // set to "en" etc to specify a language
        },
    statusBarHeight: 0, // gets set on launch
    pages: {
      NOT_FOUND: 'Not Found',
      HOME_PAGE: 'Home',
    },
    // <title>
    titles: {
      home: 'The Web App', // Used by default on all pages
      NOT_FOUND: 'Not Found',
      HOME_PAGE: 'Home',
    },
    // meta:description
    descriptions: {
      // Used by default
      home: '',
    },
    // meta:description
    keywords: {
      // Used by default
      home: '',
    },
  }
}
//@ts-ignore
const Constants = (global.Constants = getConstants(
  typeof __DEV__ !== 'undefined' ? __DEV__ : false,
))

export default Constants
