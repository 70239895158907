import { Breadcrumbs as MaterialBreadcrumbs } from '@mui/material'
import React from 'react'
import { BreadcrumbsProps } from '@mui/material/Breadcrumbs/Breadcrumbs'
import { NavigateNext } from '@mui/icons-material'

type ComponentType = BreadcrumbsProps & {}

const Breadcrumbs: React.FC<ComponentType> = ({ ...props }) => {
  return (
    <MaterialBreadcrumbs
      aria-label='breadcrumb'
      separator={<NavigateNext fontSize='small' />}
      maxItems={4}
      itemsAfterCollapse={3}
      itemsBeforeCollapse={0}
      {...props}
    />
  )
}

export { Breadcrumbs }
