import React, { createContext, useContext, useState } from 'react'
import { useDeepCompareMemo } from 'use-deep-compare'
import AlertModal from '@common/modal/AlertModal'

export type ModalOptions = {
  // If an id is passed then calling show with the same id will replace the modal instead of adding a new one
  modalId?: string
  hideOthers?: boolean
}

type ShowAlertProps = {
  title?: string
  text?: string
  okText?: string
  onDismiss?: () => void
  options?: ModalOptions
}

type GlobalModalContext = {
  showModal: (component: React.ReactNode, options?: ModalOptions) => void
  showAlert: (props: ShowAlertProps) => void
  hideModal: () => void
  hideAllModals: () => void
}

const initalState: GlobalModalContext = {
  showModal: () => {},
  hideModal: () => {},
  showAlert: () => {},
  hideAllModals: () => {},
}

const GlobalModalContext = createContext(initalState)
export const useModal = () => useContext(GlobalModalContext)

type ModalConfig = {
  modalId: string
  component: React.ReactNode
  options: ModalOptions
  withoutWrapper?: boolean
}

export const GlobalModal: React.FC<{}> = ({ children }) => {
  const [modalConfigs, setModalConfigs] = useState<ModalConfig[]>([])

  const showAlert = ({
    title,
    text,
    okText,
    onDismiss,
    options,
  }: ShowAlertProps) => {
    const onDismissClick = () => {
      if (onDismiss) {
        onDismiss()
      }
      hideModal()
    }

    showModal(
      <AlertModal
        title={title}
        text={text}
        okText={okText}
        onDismissClick={onDismissClick}
      />,
      options,
    )
  }

  const showModal = (
    modalContentNode: React.ReactNode,
    options: ModalOptions,
  ) => {
    setModalConfigs((previousModals) => {
      const modalWithIdExists =
        !!options?.modalId &&
        !!previousModals.find((m) => m.modalId === options?.modalId)

      let newModals: ModalConfig[] = []
      if (modalWithIdExists) {
        // Replace the modal in the array rather than adding pushing a new one
        newModals = previousModals?.map((m) => {
          if (m.modalId === options?.modalId) {
            return {
              modalId: m.modalId,
              component: modalContentNode,
              options,
            }
          }
          return m
        })
      } else {
        newModals = [
          ...(options?.hideOthers ? [] : previousModals),
          {
            modalId: options?.modalId || Utils.GUID(),
            component: modalContentNode,
            options,
          },
        ]
      }

      return newModals
    })
  }

  const hideModal = () => {
    setModalConfigs((previousModals) => {
      if (!previousModals?.length) {
        return []
      }
      return previousModals.slice(0, -1)
    })
  }

  const hideAllModals = () => {
    setModalConfigs([])
  }

  const modals = useDeepCompareMemo(
    () => modalConfigs?.map((m) => m.component),
    [modalConfigs],
  )

  return (
    <GlobalModalContext.Provider
      value={{
        showModal,
        hideModal,
        showAlert,
        hideAllModals,
      }}
    >
      {modals}
      {children}
    </GlobalModalContext.Provider>
  )
}
