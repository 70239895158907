import { plainToInstance } from 'class-transformer'
import { Point } from '@core/entities/skia/types'
import { ISkiaTextLabel } from '@core/entities/skia/SkiaTextLabel/ISkiaTextLabel'
import { Entity } from '@core/entities/entity'

export class SkiaTextLabel extends Entity<ISkiaTextLabel> {
  id: string
  type: 'TEXT_LABEL'
  groupId: string
  shapeId: string
  x: number
  y: number
  text: string
  backgroundColor: string
  selected: boolean
  public static new(payload: unknown): SkiaTextLabel {
    return plainToInstance(SkiaTextLabel, payload)
  }

  static translateDistance = (distance: number, zoom: number) => {
    return distance / zoom
  }

  static fontSize = ({ zoom }: { zoom: number }) => {
    // We translate so that the font is the same size independent of zoom
    return this.translateDistance(16, zoom)
  }

  static padding = ({ zoom }: { zoom: number }) => {
    return this.translateDistance(5, zoom)
  }

  static textHeight = ({ zoom }: { zoom: number }) => {
    return SkiaTextLabel.fontSize({ zoom })
  }

  static borderWidth = ({ zoom }: { zoom: number }) => {
    return this.translateDistance(1, zoom)
  }

  static borderRadius = ({ zoom }: { zoom: number }) => {
    return this.translateDistance(4, zoom)
  }

  textBottomLeftPoint = ({ zoom }: { zoom: number }) => {
    const padding = SkiaTextLabel.padding({ zoom })
    // Move right and up by the padding
    return { x: this.x + padding, y: this.y - padding }
  }

  static charWidth = ({ zoom }: { zoom: number }) => {
    return SkiaTextLabel.fontSize({ zoom }) * 0.6
  }

  textWidth = ({ zoom }: { zoom: number }) => {
    return this.text.length * SkiaTextLabel.charWidth({ zoom })
  }

  rectTopLeftPoint = ({ zoom }: { zoom: number }) => {
    const padding = SkiaTextLabel.padding({ zoom })

    return {
      x: this.x,
      y: this.y - SkiaTextLabel.textHeight({ zoom }) - padding * 2,
    }
  }

  static rectHeight = ({ zoom }: { zoom: number }) => {
    return (
      SkiaTextLabel.textHeight({ zoom }) + 2 * SkiaTextLabel.padding({ zoom })
    )
  }

  rectWidth = ({ zoom }: { zoom: number }) => {
    return this.textWidth({ zoom }) + 2 * SkiaTextLabel.padding({ zoom })
  }

  isInBounds(point: Point, zoom: number): boolean {
    const rectPoint = this.rectTopLeftPoint({ zoom: zoom })
    return (
      point.x >= rectPoint.x &&
      point.x <= rectPoint.x + this.rectWidth({ zoom: zoom }) &&
      point.y >= rectPoint.y &&
      point.y <= rectPoint.y + SkiaTextLabel.rectHeight({ zoom: zoom })
    )
  }
}
