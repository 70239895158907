import * as React from 'react'
import { Box, BoxProps } from '.'

const Flex: React.FC<BoxProps> = React.forwardRef(
  ({ children, ...props }, ref) => {
    return (
      <Box ref={ref} display='flex' {...props}>
        {children}
      </Box>
    )
  },
)

export { Flex }
