import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import React, { useEffect, useRef, useState } from 'react'
import { Profile } from '@core/entities/profile'
import { useGetProfile } from '@core/react-query/features/profile/hooks/useGetProfile'

let ErrorBoundary = null
// Initialises Bugsnag on app mount
export const InitBugsnag = ({ children }) => {
  const profileRef = useRef<Profile>()
  const { data: profile } = useGetProfile()
  const [initialised, setInitialised] = useState(false)
  useEffect(() => {
    profileRef.current = profile
  }, [profile])

  useEffect(() => {
    Bugsnag.start({
      apiKey: Project.bugsnag,
      plugins: [new BugsnagPluginReact()],
      // Don't report errors that happen during development
      releaseStage: process.env.NODE_ENV,
      enabledReleaseStages: ['production'],
      metadata: {
        gitHash: Project.gitHash,
      },
      onError: (event) => {
        const profile = profileRef.current
        if (profile?.id) {
          event.setUser(
            `${profile.id}`,
            profile.email,
            `${profile.firstName} ${profile.lastName}`,
          )
        }
        // Ignore the route change error
        // This is something expected so should not show up in Bugsnag
        if (
          event?.originalError
            ?.toString()
            .includes('Abort route change. Please ignore this error') ||
          event?.originalError
            ?.toString()
            .includes('SA_IGNORE_ERROR: cancel route change.')
        ) {
          return false
        }
      },
    })

    setInitialised(true)
  }, [])

  if (!initialised) {
    return null
  }

  if (!ErrorBoundary) {
    ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React)
  }

  return <ErrorBoundary>{children}</ErrorBoundary>
}
