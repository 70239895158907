export const createQueryKeyString = (params: Record<string, any>) => {
  const queryString = Object.keys(params)
    .filter((k) => !!params[k])
    .map((k) => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
    .join('&')
  return queryString ? `?${queryString}` : ''
}

export const parseQueryParamsFromUrl = (url: string) => {
  const [paramsString] = url.split('?').reverse()
  return paramsString.split('&').reduce((acc, param) => {
    const [key, value] = param.split('=')
    return { ...acc, [key]: value }
  }, {} as Record<string, any>)
}
