import { localizedStrings } from '@core/strings'
import * as Icon from '@mui/icons-material'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Box, BoxProps, Text } from '.'
import { Button } from '@mui/material'

type ImageProps = BoxProps &
  React.DetailedHTMLProps<
    React.ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  >

const RETRY_COUNT = 3
const RETRY_DELAY = 1000

const Image: React.FC<ImageProps> = ({ style, ...props }) => {
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<boolean>(false)
  const componentRef = useRef<number>()

  const handleLoad = () => {
    setError(false)
    setLoading(false)
  }

  useEffect(() => {
    componentRef.current = RETRY_COUNT
  }, [])

  const handleError = useCallback(
    ({ currentTarget }) => {
      if (componentRef && componentRef.current && componentRef.current > 1) {
        setTimeout(() => {
          currentTarget.onerror = null
          currentTarget.src = props.src
          currentTarget.alt = props.alt
          componentRef.current =
            componentRef && componentRef.current && componentRef.current - 1
        }, RETRY_DELAY)
      } else {
        setLoading(false)
        setError(true)
      }
    },
    [props.src, props.alt],
  )

  const handleRetry = () => {
    setLoading(true)
    setError(false)
    componentRef.current = RETRY_COUNT
  }

  return (
    <>
      {!error && (
        <Box
          component='img'
          onLoad={handleLoad}
          onError={handleError}
          style={{ display: loading ? 'none' : 'inline', ...style }}
          {...props}
        />
      )}
      {loading && !error && <Loader />}
      {error && (
        <Button variant='text' color='error' onClick={handleRetry}>
          <Box>
            <Icon.Replay />
            <Text fontSize={10} fontWeight='bold'>
              {localizedStrings.retry}
            </Text>
          </Box>
        </Button>
      )}
    </>
  )
}

export { Image }
