import { QueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { defaultOptions } from '@core/react-query/defaultOptions'

export const withReactQuery = (Component) => {
  return function WrappedComponent(props) {
    const webDefaultQueryOptions: typeof defaultOptions.queries = {
      ...defaultOptions.queries,
    }
    const webDefaultMutationOptions: typeof defaultOptions.mutations = {
      ...defaultOptions.mutations,
      networkMode: 'always',
    }
    const [queryClient] = useState(
      () =>
        new QueryClient({
          defaultOptions: {
            queries: webDefaultQueryOptions,
            mutations: webDefaultMutationOptions,
          },
        }),
    )
    return <Component {...props} queryClient={queryClient} />
  }
}
