import { RequestErrorType } from '@core/utils/api-axios-instance'
import {
  InfiniteData,
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query'
import {
  notificationsKeys,
  RequestTypes,
} from '@core/react-query/features/notifications'
import { PageResponse } from '@core/entities/pagination'
import { IAppNotification } from '@core/entities/notifications'

export const useMarkAllNotificationsAsRead = (
  options?: UseMutationOptions<
    unknown,
    RequestErrorType,
    RequestTypes['markAllNotificationsAsRead']
  >,
) => {
  const queryClient = useQueryClient()

  return useMutation({
    ...notificationsKeys.markAllNotificationsAsRead,
    ...options,
    onMutate: (...params) => {
      const [{ userId }] = params

      queryClient.setQueriesData<InfiniteData<PageResponse<IAppNotification>>>(
        {
          queryKey: notificationsKeys
            .getNotifications({ userId })
            .queryKey.slice(0, -3),
        },
        (data) => {
          if (!data?.pages) return

          const pages = data.pages.map((page) => ({
            ...page,
            content: page.content.map((notification) => ({
              ...notification,
              isRead: true,
            })),
          }))

          return { ...data, pages }
        },
      )

      options?.onMutate?.(...params)
    },
  })
}
