import { plainToInstance } from 'class-transformer'
import { Point } from '@core/entities/skia/types'
import { SkiaShapeBase } from '@core/entities/skia/SkiaShapeBase/SkiaShapeBase'
import { ISkiaOval } from '@core/entities/skia/SkiaOval/ISkiaOval'
import { SiteBoxDocumentAnnotation } from '@core/entities/SiteBoxDocumentAnnotation'

export class SkiaOval extends SkiaShapeBase<ISkiaOval> {
  id: string
  type: 'OVAL'
  x: number
  y: number
  width: number
  height: number

  public static new(payload: unknown): SkiaOval {
    return plainToInstance(SkiaOval, payload)
  }

  public static newFromSiteboxDocumentAnnotation(
    payload: SiteBoxDocumentAnnotation,
  ): SkiaOval {
    const transformToSkiaOval = {
      id: payload.uuid,
      type: 'OVAL',
      x: payload.annotationData.bbox[0],
      y: payload.annotationData.bbox[1],
      width: payload.annotationData.bbox[2],
      height: payload.annotationData.bbox[3],
    }

    return plainToInstance(SkiaOval, transformToSkiaOval)
  }

  isInBounds({ x, y }: Point): boolean {
    // Calculate the center of the oval
    const cx = this.x + this.width / 2
    const cy = this.y + this.height / 2
    // Calculate the radii
    const rx = this.width / 2
    const ry = this.height / 2

    // Normalize the point with respect to the center and radii
    const normalizedX = (x - cx) / rx
    const normalizedY = (y - cy) / ry

    // Check if the point is inside the oval
    return normalizedX * normalizedX + normalizedY * normalizedY <= 1
  }

  dragCorners(): Point[] {
    return [
      { x: this.x, y: this.y }, // top left
      { x: this.x + this.width, y: this.y }, // top right
      { x: this.x, y: this.y + this.height }, // bottom left
      { x: this.x + this.width, y: this.y + this.height }, // bottom right
    ]
  }

  resizeOnDrag({
    cornerIndex,
    x,
    y,
  }: {
    cornerIndex: number
    x: number
    y: number
  }) {
    switch (cornerIndex) {
      case 0: // top-left
        this.width += this.x - x
        this.height += this.y - y
        this.x = x
        this.y = y
        break
      case 1: // top-right
        this.width = x - this.x
        this.height += this.y - y
        this.y = y
        break
      case 2: // bottom-left
        this.width += this.x - x
        this.height = y - this.y
        this.x = x
        break
      case 3: // bottom-right
        this.width = x - this.x
        this.height = y - this.y
        break
      default:
        break
    }
  }

  move({ x, y }: Point) {
    this.x += x
    this.y += y
  }

  labelAnchor(): Point {
    return { x: this.x, y: this.y }
  }

  registerUpdateCardAnchor(): Point {
    return { x: this.x, y: this.y + this.height }
  }
}
