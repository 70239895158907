import { plainToInstance, Type } from 'class-transformer'
import { Entity } from '@core/entities/entity'
import { TemplateQuestion } from '../TemplateQuestion'
import { ITemplateQuestionGroup } from './ITemplateQuestionGroup'
import { ConditionalQuestionGroup } from '@core/entities/template/TemplateQuestionGroup/types'

export class TemplateQuestionGroup extends Entity<ITemplateQuestionGroup> {
  id: string

  singular: boolean

  @Type(() => TemplateQuestion)
  data: Array<TemplateQuestion>

  title?: string

  conditionalQuestionGroup?: ConditionalQuestionGroup

  //from this point only the answered questions have these types
  isHidden?: boolean

  public static new(payload: unknown): TemplateQuestionGroup {
    const entity = plainToInstance(TemplateQuestionGroup, payload)

    return entity
  }
}
