import { localizedStrings } from '@core/strings'
import axios from 'axios'
import Constants from '@core/utils/constants'
import { statusCodeType } from '@core/react-query/features/testing'
import { usesNest } from '@core/utils/usesNest'

const APIAxiosInstance = axios.create({ baseURL: Project.api })

APIAxiosInstance.interceptors.request.use(async (config) => {
  config.withCredentials = false

  if (Constants.INTEGRATION_TEST) {
    return config
  }
  // Add Cognito auth header to all requests
  const session = await API.auth.Cognito.getSession()
  if (session && session.accessToken) {
    config.headers.AUTHORIZATION = `Bearer ${session.accessToken.jwtToken}`
  }
  // Add client header: web | android | iOS
  config.headers['siteassist-client'] = API.platform
  // Add E2E test header if the app is running in E2E mode
  if (Constants.E2E) {
    config.headers['E2E-Test'] = '1'
  }

  if (usesNest(config.url)) {
    config.baseURL = Project.nestApi
  }

  return config
})

APIAxiosInstance.interceptors.response.use(
  (response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response
  },
  async (error) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error?.response?.status === 401 && !Constants.INTEGRATION_TEST) {
      API.logout()
    }

    const getErrorMessage = (errorStatus: statusCodeType) => {
      const getStringifiedMessage = (message) =>
        typeof message === 'string' ? message : JSON.stringify(message)

      const responseMessage =
        typeof error?.response?.data === 'string'
          ? error?.response?.data
          : Object.values(error?.response?.data)
              .map((v) => getStringifiedMessage(v))
              .join('\n')

      const isErrorMessageDetailed = (
        errorStatus: statusCodeType,
        defaultMessage: string,
      ) =>
        responseMessage === `${errorStatus} Error` || !responseMessage
          ? defaultMessage
          : responseMessage

      switch (errorStatus) {
        case 400:
        case 401:
        case 500:
          return responseMessage || error.message
        case 404:
          return isErrorMessageDetailed(errorStatus, localizedStrings.notFound)
        case 403:
          return isErrorMessageDetailed(
            errorStatus,
            localizedStrings.unauthorized,
          )
        default:
          return localizedStrings.defaultErrorMessage
      }
    }

    // If there is no status we return the message
    // This happens when the user is offline
    // In that case the message is `Network Error`
    if (!error?.response?.status) {
      return Promise.reject(error?.message)
    }
    return Promise.reject(getErrorMessage(error.response.status))
  },
)

export { APIAxiosInstance }
