import { plainToInstance, Type } from 'class-transformer'
import { Entity } from '@core/entities/entity'
import { IChangeRequest } from './IChangeRequest'
import { User } from '@core/entities/user'

export class ChangeRequest extends Entity<IChangeRequest> {
  comment: string

  @Type(() => User)
  requestedBy: User

  @Type(() => User)
  addressedBy?: User

  requestedAt: string

  public static new(payload: unknown): ChangeRequest {
    const entity = plainToInstance(ChangeRequest, payload)

    return entity
  }

  public isUnanswered(): boolean {
    return !this.addressedBy?.id
  }
}
