import { Entity } from '@core/entities/entity'
import { plainToInstance } from 'class-transformer'
import { IPermitTemplateVersionPeriodicChecklist } from './IPermitTemplateVersionPeriodicChecklist'

export class PermitTemplateVersionPeriodicChecklist extends Entity<IPermitTemplateVersionPeriodicChecklist> {
  id: number
  name: string
  checklistTemplateId: number
  description: string

  public static new(payload: unknown): PermitTemplateVersionPeriodicChecklist {
    const entity = plainToInstance(
      PermitTemplateVersionPeriodicChecklist,
      payload,
    )

    return entity
  }
}
