import { Entity } from '@core/entities/entity'
import { IProfile } from '@core/entities/profile/IProfile'
import {
  ProfileOrganisation,
  ProfileProject,
} from '@core/entities/profile/types'
import { RootRole } from '@core/entities/user'
import { plainToInstance } from 'class-transformer'

export class Profile extends Entity<IProfile> {
  id: number
  firstName: string
  locale: string
  signatureId?: number
  signatureUrl?: string
  impersonated?: boolean
  avatarUrl?: string
  lastName: string
  email: string
  phone: string
  company: string
  projects: ProfileProject[]
  role: RootRole
  organisations: ProfileOrganisation[]

  public static new(payload: unknown): Profile {
    const entity = plainToInstance(Profile, payload)

    return entity
  }

  public isSuperUser(): boolean {
    return this.isSuperAdmin() || this.isSupportUser()
  }

  public isSuperAdmin(): boolean {
    return this.role === 'SUPER_ADMIN'
  }

  public isSupportUser(): boolean {
    return this.role === 'SUPPORT_USER'
  }
}
