import { useAnalytics } from '@common/hooks'
import { APICommon } from 'project/api-common'

const API = {
  ...APICommon,
  useAnalytics,
}

// TODO: remove this ignore and fix the types
// @ts-ignore
global.API = API
export default API
