import { PageResponse } from '@core/entities/pagination'
import {
  useInfiniteQuery,
  UseInfiniteQueryOptions,
} from '@tanstack/react-query'
import {
  notificationsKeys,
  RequestTypes,
} from '@core/react-query/features/notifications'
import { RequestErrorType } from '@core/utils/api-axios-instance'
import { AppNotification, IAppNotification } from '@core/entities/notifications'
import { usePageableQuery } from '@core/react-query/hooks'

const dataTransformer = ({ content }: PageResponse<IAppNotification>) => {
  return content.map((notification) => AppNotification.new(notification))
}

export const useGetNotifications = (
  payload: RequestTypes['getNotifications'],
  options: UseInfiniteQueryOptions<
    PageResponse<IAppNotification>,
    RequestErrorType,
    Array<AppNotification>
  > = {},
) => {
  const getNotificationsKey = notificationsKeys.getNotifications(payload)

  const infiniteQueryOptions = usePageableQuery({
    queryEnabled: options.enabled,
    queryKey: getNotificationsKey.queryKey,
    dataTransformer,
  })

  return useInfiniteQuery<
    PageResponse<IAppNotification>,
    RequestErrorType,
    Array<AppNotification>
  >({
    ...infiniteQueryOptions,
    ...notificationsKeys.getNotifications(payload),
    ...options,
  })
}
