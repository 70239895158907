import { Entity } from '@core/entities/entity'
import { ProcessTagReferenceObjectType } from '@core/entities/processTags/types'
import { plainToInstance, Type } from 'class-transformer'
import { IProcessTagsWrapper } from '@core/entities/processTags/IProcessTagsWrapper'
import { IProcessTag } from '@core/entities/processTags/IProcessTag'
import { ProcessTag } from '@core/entities/processTags/ProcessTag'

export class ProcessTagsWrapper extends Entity<IProcessTagsWrapper> {
  referenceObjectTypeId: number

  referenceObjectType: ProcessTagReferenceObjectType

  @Type(() => ProcessTag)
  tags: Array<IProcessTag>

  public static new(payload: unknown): ProcessTagsWrapper {
    const entity = plainToInstance<ProcessTagsWrapper, unknown>(
      ProcessTagsWrapper,
      payload,
    )

    return entity
  }
}
