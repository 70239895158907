import { useEffect, useState } from 'react'
import React from 'react'
import { useGetProfile } from '@core/react-query/features/profile/hooks/useGetProfile'
import Cognito from '@core/cognito'

type InitProfileProps = {
  children: React.ReactElement
}

// If the user is logged in, then we initialise the profile
export const InitProfile: React.FC<InitProfileProps> = ({ children }) => {
  const [ready, setReady] = useState(false)
  const { data: profile, refetch } = useGetProfile({
    // We fetch explicitly
    enabled: false,
  })

  useEffect(() => {
    const init = async () => {
      // We don't run the init server side
      const isClient = typeof window !== 'undefined'
      if (!isClient) {
        return
      }

      // If the user is not logged in, there's nothing to do
      const cognitoUsername = await Cognito.getUsername()
      if (!cognitoUsername) {
        setReady(true)
        return
      }
      // If the user is logged in, we fetch the profile
      const profileResponse = await refetch()
      setReady(true)
    }

    init()
  }, [])

  if (!ready) {
    return null
  }

  return children
}
