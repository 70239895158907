import Router from 'next/router'
import { PageUrls } from '@core/page-urls'
import { Profile } from '@core/entities/profile'

export const loginRedirect = (args: {
  profile: Profile
  forceRedirect?: boolean
}) => {
  if (!args.profile) {
    return
  }
  const { profile, forceRedirect } = args

  const params = Router.query
  if (document.location.href.includes('accept-invite') && !args.forceRedirect) {
    return
  }

  const getRedirect = () => {
    // If we have a redirect query param we take the user there
    // This is used when a user that's not logged in tries to view a page that requires auth
    if (params.redirect) {
      return params.redirect
    }

    // For support users we take them to the support page
    if (profile.isSuperUser()) {
      return PageUrls.adminOrganisations
    }

    if (!profile?.organisations?.[0]?.id) return PageUrls.noOrganisation

    // For any other users they go to the page for their first organisation
    return PageUrls.organisation(profile.organisations[0].id)
  }
  const redirect = getRedirect()
  // @ts-ignore
  Router.replace(redirect, redirect, {
    shallow: true,
  })
}
