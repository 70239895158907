import { localizedStrings } from '@core/strings'
import { FunctionComponent } from 'react'
localizedStrings.setLanguage('en-GB')

const LanguageHandler: FunctionComponent<any> = ({ children }) => {
  // TODO: implement this when we internationalize
  // const { profile } = useProfile()
  // const locale = profile?.locale
  // const forceLanguage = Constants.simulate.FORCE_LANGUAGE
  // useEffect(() => {
  //   if (forceLanguage) {
  //     localizedStrings.setLanguage(forceLanguage)
  //   }
  //   if (locale && locale !== localizedStrings.getInterfaceLanguage()) {
  //     try {
  //       localizedStrings.setLanguage(locale)
  //     } catch (e) {}
  //   }
  // }, [locale, forceLanguage])
  return children
}

export default LanguageHandler
