import {
  RadioGroup as MaterialRadioGroup,
  Radio as MaterialRadio,
} from '@mui/material'

const RadioGroup = MaterialRadioGroup

const Radio = MaterialRadio

export { RadioGroup, Radio }
