import { Profile } from './entities/profile'

export const userProperties = (profile: Profile) => {
  const organisations = profile?.organisations || []
  const projects = profile?.projects || []

  return {
    userId: `${profile.id}`,
    properties: {
      name: `${profile?.firstName} ${profile?.lastName}`,
      email: profile?.email,
      organisationIds: organisations.map((o) => o.id),
      organisationNames: organisations.map((o) => o.name),
      organisations: organisations,
      projectIds: projects.map((p) => p.id),
      projectNames: projects.map((p) => p.name),
      projects: projects,
    },
  }
}

export type HelpOpened = {
  eventName: 'help.opened'
  eventProperties?: null
}

export type TestingEventWithProperties = {
  eventName: 'testing.event'
  eventProperties: {
    something: string
  }
}

export type TestingEventWithoutProperties = {
  eventName: 'testing.event.without.properties'
  eventProperties?: null
}

export type MobileCodePushVersionLaunched = {
  eventName: 'mobile.codepush-version.launched'
  eventProperties: {
    environment: string
    version: string
  }
}

export type MobileCheckinLocationError = {
  eventName: 'mobile.check-in.location.error'
  eventProperties: {
    errorCode: number
    errorMessage: string
  }
}

export type MobilePermitDetailsNavigationMethod = {
  eventName: 'mobile.ux.permit-details.navigation-method'
  eventProperties: {
    method: 'app-bar' | 'hyperlink-text' | 'summary-section-card'
    route: string
  }
}

export type MobileOfflineActionCached = {
  eventName: 'mobile.offline-action-cached'
  eventProperties?: {}
}

export type MobileOfflineActionSynced = {
  eventName: 'mobile.offline-action-synced'
  eventProperties?: { isBackgroundSync: boolean }
}

export type MobileOfflineActionSyncFailed = {
  eventName: 'mobile.offline-action-sync-failed'
  eventProperties?: { isBackgroundSync: boolean }
}

export type MobileNavigationEvent = {
  eventName: 'mobile.navigation'
  eventProperties: {
    route: string
  }
}

export type BackgroundSyncTimeout = {
  eventName: 'mobile.offline-action-sync-timeout'
  eventProperties?: {}
}

export type PusherConnectionEvent = {
  eventName: 'pusher.connection-successful' | 'pusher.connection-closed'
  eventProperties: {
    platform: 'web' | 'mobile'
  }
}

export type PusherSubscriptionEvent = {
  eventName: 'pusher.subscription-successful' | 'pusher.subscription-closed'
  eventProperties: {
    platform: 'web' | 'mobile'
    channel: string
  }
}

export type AnalyticsEvent =
  | TestingEventWithProperties
  | TestingEventWithoutProperties
  | HelpOpened
  | MobileCodePushVersionLaunched
  | MobileCheckinLocationError
  | MobilePermitDetailsNavigationMethod
  | MobileOfflineActionCached
  | MobileOfflineActionSynced
  | MobileOfflineActionSyncFailed
  | MobileNavigationEvent
  | BackgroundSyncTimeout
  | PusherConnectionEvent
  | PusherSubscriptionEvent
