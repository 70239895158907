import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import Cognito from '@core/cognito'

export const useUserRedirect = () => {
  const router = useRouter()
  const path = router.asPath
  const [isReady, setIsReady] = useState<boolean>(false)
  // Redirect the user to the login page with a ?redirect query param so that they get taken to the page they were trying
  useEffect(() => {
    const run = async () => {
      const redirectPath = () => {
        // Testcafe seems to add junk into the path that next js is aware of
        // router.asPath = asfiwejflae/https://localhost:3000/actual/path
        // This removes the prefix junk so that we get just the path
        if (Constants.E2E) {
          if (path.includes('localhost')) {
            return router.asPath.split('http://localhost:3000')?.[1]
          }
        }
        return path
      }

      if (isReady) {
        return
      }

      // We access a private member of the Auth object so that we have a synchronous way of getting the username
      // @ts-ignore
      const cognitoUsername = Cognito.usernameSync()
      if (!cognitoUsername && typeof window !== 'undefined') {
        const newPath = `/?redirect=${encodeURIComponent(redirectPath())}`
        router.push(newPath)
      } else {
        setIsReady(true)
      }
    }
    run()
  }, [])

  return {
    isReady,
  }
}
