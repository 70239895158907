import { plainToInstance } from 'class-transformer'
import { Entity } from '@core/entities/entity'
import { IPermitMultipleChoiceAnswer } from './IPermitMultipleChoiceAnswer'

export class PermitMultipleChoiceAnswer extends Entity<IPermitMultipleChoiceAnswer> {
  id: string
  value: string
  requiresSubmissionText?: boolean

  //from this point it's only the answered questions that have this type
  selected?: boolean
  submissionText?: string

  public static new(payload: unknown): PermitMultipleChoiceAnswer {
    const entity = plainToInstance(PermitMultipleChoiceAnswer, payload)

    return entity
  }
}
