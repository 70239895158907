import { PermitApprovalWrapper } from '@core/entities/permit/PermitApprovalWrapper'
import { plainToInstance, Type } from 'class-transformer'
import { Entity } from '@core/entities/entity'
import { IPermitApprovalReviews } from '@core/entities/permit/PermitApprovalReviews/IPermitApprovalReviews'
import { PermitNominatedApprovers } from '@core/entities/permit/PermitNominatedApprovers'
import { ApprovalStage } from './types'

export class PermitApprovalReviews extends Entity<IPermitApprovalReviews> {
  @Type(() => PermitApprovalWrapper)
  approvals: Array<PermitApprovalWrapper>

  nextApprovalId: string

  @Type(() => PermitNominatedApprovers)
  nominatedApprovers: PermitNominatedApprovers

  nextApprovalStage: ApprovalStage

  public static new(payload: unknown): PermitApprovalReviews {
    const entity = plainToInstance(PermitApprovalReviews, payload)

    return entity
  }

  public nextApprovalHasQuestions(): boolean {
    const nextApproval = this.approvals.find(
      ({ approval }) => approval.id === this.nextApprovalId,
    )

    if (!nextApproval) return false

    return nextApproval.approval?.approvalQuestions?.questions?.length > 0
  }

  public hasNominatedApprovers(): boolean {
    return this.nominatedApprovers?.approvers?.length > 0
  }

  public getApprovalsFromCurrentStage(): Array<PermitApprovalWrapper> {
    return this.approvals.filter(
      (approval) => approval.approval.approvalStage === this.nextApprovalStage,
    )
  }
}
