export type SubmissionDocument = {
  key: string
  downloadUrl?: string
  getUrl?: string
}

export type SubmissionProof = {
  key: string
  downloadUrl?: string
}

export const ALL_PROOF_TYPES = ['pdf', 'img'] as const
type ProofTypeTuple = typeof ALL_PROOF_TYPES
export type ProofType = ProofTypeTuple[number]

export const ALL_REQUIRED_OPTIONAL_TYPES = [
  'REQUIRED',
  'OPTIONAL',
  'NOT_REQUIRED',
] as const
type RequiredOptionalTypeTuple = typeof ALL_REQUIRED_OPTIONAL_TYPES
export type RequiredOptionalType = RequiredOptionalTypeTuple[number]
