import { localizedStrings } from '@core/strings'
import React, { FunctionComponent, useEffect, useRef, useState } from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from './modal/Modal'
import Input from './base/forms/Input'
import { ButtonPrimary } from './Button'
import * as yup from 'yup'
import { useFormik } from 'formik'
import Signature from '../webapp/components/common/Signature'
import { useGetProfile } from '@core/react-query/features/profile/hooks/useGetProfile'
import { useUpdateProfile } from '@core/react-query/features/profile/hooks/useUpdateProfile'

type ComponentType = {}

const schema = yup.object().shape({
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required'),
  phone: yup.string().required('Phone number is required'),
  company: yup.string().required('Company is required'),
})

const CompleteProfileTakeover: FunctionComponent<ComponentType> = ({}) => {
  const { data: profile } = useGetProfile()
  const updateProfileMutation = useUpdateProfile()
  const [isActive, setIsActive] = useState<boolean>(false)
  const org = profile?.organisations?.[0]
  const [hasSignature, setHasSignature] = useState<boolean>(false)
  useEffect(() => {
    if (!profile) return
    if (!profile?.isSuperUser() && (!profile.signatureId || !profile.phone)) {
      setIsActive(true)
    }
    if (profile.signatureId && profile.phone && isActive) {
      setIsActive(false)
    }
  }, [profile])

  const {
    setFieldValue,
    handleBlur,
    handleChange,
    handleSubmit,
    errors,
    setTouched,
    touched,
    values,
  } = useFormik({
    validateOnMount: true,
    initialValues: {
      firstName: profile?.firstName,
      lastName: profile?.lastName,
      phone: profile?.phone,
      company: profile?.company,
    },
    validationSchema: schema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      // @ts-ignore
      sigRef.current.getCanvas().toBlob(
        (res) => {
          const file = new File([res], 'signature.png')
          updateProfileMutation.mutate({
            ...profile,
            signatureFile: file,
            firstName: values.firstName,
            lastName: values.lastName,
            phone: values.phone,
            company: values.company,
          })
        },
        'image/png',
        1,
      )
    },
  })

  const changeHandler = (e) => {
    handleChange(e)
  }

  const onSubmit = (e) => {
    Utils.preventDefault(e)
    const newTouched = {}
    Object.keys(errors).map((v) => {
      newTouched[v] = true
    })
    setTouched(newTouched)
    handleSubmit(e)
  }

  const sigRef = useRef()
  if (!profile || profile?.isSuperUser() || !org) return null

  return (
    <Modal isOpen={isActive}>
      <form data-test='complete-profile' onSubmit={onSubmit}>
        <ModalHeader>{localizedStrings.completeYourProfile}</ModalHeader>
        <ModalBody>
          <div
            dangerouslySetInnerHTML={{
              __html: localizedStrings.formatString(
                localizedStrings.completeProfile,
                `${profile?.firstName} ${profile?.lastName}`,
                Format.enumeration.get(org.role),
                org?.name,
              ) as string,
            }}
          />
          <div className='row'>
            <div className='col-md-6'>
              <Input
                name='firstName'
                onChange={changeHandler}
                onBlur={handleBlur}
                value={values.firstName}
                isValid={!errors.firstName}
                className='mb-2 mt-3 input-container__required'
                placeholderChar={localizedStrings.enterName}
                label={localizedStrings.firstName}
              />
            </div>
            <div className='col-md-6'>
              <Input
                name='lastName'
                onChange={changeHandler}
                onBlur={handleBlur}
                value={values.lastName}
                isValid={!errors.lastName}
                className='mb-2 mt-3 input-container__required'
                placeholderChar={localizedStrings.enterName}
                label={localizedStrings.lastName}
              />
            </div>
          </div>
          <div className='row'>
            <div className='col-md-12'>
              <Input
                name='phone'
                type='tel'
                onChange={changeHandler}
                onBlur={handleBlur}
                value={values.phone}
                isValid={!errors.phone}
                className='mt-3 input-container__required'
                placeholderChar={localizedStrings.enterPhoneNumber}
                label={localizedStrings.telephoneNumber}
              />
            </div>
          </div>
          <div className='row'>
            <div className='col-md-12'>
              <Input
                name='company'
                onChange={changeHandler}
                onBlur={handleBlur}
                value={values.company}
                isValid={!errors.company}
                className='mb-2 mt-3 input-container__required'
                placeholderChar={localizedStrings.company}
                label={localizedStrings.company}
              />
            </div>
          </div>
          <div className='row'>
            <div className='col-md-12'>
              <Signature
                sigRef={sigRef}
                onChange={() => {
                  if (!hasSignature) setHasSignature(true)
                }}
                onClear={() => {
                  setHasSignature(false)
                }}
              />
            </div>
          </div>
          <ErrorMessage className='mt-2'>
            {updateProfileMutation.error?.message}
          </ErrorMessage>
        </ModalBody>
        <ModalFooter>
          <ButtonPrimary
            data-test='jsCompleteProfile'
            type='submit'
            disabled={
              updateProfileMutation.isLoading ||
              !!Object.keys(errors).length ||
              !hasSignature
            }
            icon='fa fa-arrow-right'
          >
            {localizedStrings.complete}
          </ButtonPrimary>{' '}
        </ModalFooter>
      </form>
    </Modal>
  )
}

export default CompleteProfileTakeover
