import { plainToInstance } from 'class-transformer'
import { Entity } from '@core/entities/entity'
import { IPermitNominateApprover } from './IPermitNominateApprover'

export class PermitNominateApprover extends Entity<IPermitNominateApprover> {
  //ask backend to include the whole user object
  nominatedBy: { name: string; id: number; company: string }
  targetApprovers: Array<{ name: string; id: number; company: string }>

  targetApproval: { id: string; label: string }

  public static new(payload: unknown): PermitNominateApprover {
    const entity = plainToInstance(PermitNominateApprover, payload)

    return entity
  }
}
