import { IAvailableChecklistTemplatesWrapper } from '@core/entities/checklist/ChecklistTemplate/IAvailableChecklistTemplatesWrapper'
import { Entity } from '@core/entities/entity'
import { plainToInstance, Type } from 'class-transformer'
import { ChecklistTemplate } from '@core/entities/checklist/ChecklistTemplate/ChecklistTemplate'

export class AvailableChecklistTemplatesWrapper extends Entity<IAvailableChecklistTemplatesWrapper> {
  @Type(() => ChecklistTemplate)
  recent: Array<ChecklistTemplate>

  @Type(() => ChecklistTemplate)
  templates: Array<ChecklistTemplate>

  public static new(payload: unknown): AvailableChecklistTemplatesWrapper {
    const entity = plainToInstance<AvailableChecklistTemplatesWrapper, unknown>(
      AvailableChecklistTemplatesWrapper,
      payload,
    )

    return entity
  }
}
