import {
  IPermitTemplateApprovalData,
  PermitTemplateApprovalStage,
} from '@core/entities/permit-template-editor/IPermitTemplateApprovalData'
import { Entity } from '@core/entities/entity'
import { TemplateForm } from '@core/entities/template/TemplateForm'
import { plainToInstance, Type } from 'class-transformer'
import { localizedStrings } from '@core/strings'

export class PermitTemplateApprovalData extends Entity<IPermitTemplateApprovalData> {
  id?: string
  label: string
  allowDeletion: boolean
  @Type(() => TemplateForm)
  approvalQuestions?: TemplateForm
  approvalStage: PermitTemplateApprovalStage

  public static new(payload: unknown): PermitTemplateApprovalData {
    const entity = plainToInstance(PermitTemplateApprovalData, payload)
    return entity
  }

  public static stageToText(stage: PermitTemplateApprovalStage): string {
    switch (stage) {
      case 'BEFORE_PERMIT_HOLDER':
        return localizedStrings.beforePermitHolder
      case 'AFTER_PERMIT_HOLDER':
        return localizedStrings.afterPermitHolder
      default:
        return stage
    }
  }
}
