import { localizedStrings } from '@core/strings'
import Router, { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import {
  createQueryKeyString,
  parseQueryParamsFromUrl,
} from '@core/react-query/queryParams'

type useInterceptNavigationProps = (args: {
  canNavigate: boolean
  onInterceptedNavigation: (
    navigateToNextRoute: () => void,
    stayOnCurrentRoute: () => void,
  ) => void
}) => {}

export const useInterceptNavigation: useInterceptNavigationProps = ({
  canNavigate,
  onInterceptedNavigation,
}) => {
  const [interruptDisabled, setInterruptDisabled] = useState<boolean>(false)
  // This is needed to make sure that if the rerouting is cancelled, the history will still show the correct route
  const [rerouteAttemptNumber, setRerouteAttemptNumber] = useState<number>(0)
  const router = useRouter()

  const beforeUnloadHandler = (e: BeforeUnloadEvent) => {
    e.returnValue = localizedStrings.confirmLeavePage
    return localizedStrings.confirmLeavePage
  }

  const beforeHistoryChangeHandler = (nextRoute) => {
    const [nextRouteNoQuery] = nextRoute.split('?')
    const [currentRouteNoQuery] = router.asPath
      // // Due to testcafe the path of the route is of the form
      // // /PQg4VDGGw*sPUiFMhaW/http://localhost:3000/organisations/177267/permits/new
      // // This is because testcafe has its own server that all routes and then passes them to our server
      .split('/http://localhost:3000')
      .pop()
      .split('?')

    const queryParams = parseQueryParamsFromUrl(router.asPath)

    if (currentRouteNoQuery !== nextRouteNoQuery) {
      onInterceptedNavigation(
        () => {
          setInterruptDisabled(true)
          router.replace(nextRoute)
        },
        () => {
          setRerouteAttemptNumber(rerouteAttemptNumber + 1)
          queryParams.rerouteAttempt = rerouteAttemptNumber + 1
          router.push(
            `${currentRouteNoQuery}${createQueryKeyString(queryParams)}`,
            undefined,
            { shallow: true },
          )
        },
      )
      Router.events.emit('routeChangeError')
      throw 'Abort route change. Please ignore this error.'
    }
  }

  useEffect(() => {
    if (!canNavigate && !interruptDisabled) {
      Router.events.on('beforeHistoryChange', beforeHistoryChangeHandler)
      window.addEventListener('beforeunload', beforeUnloadHandler)

      return () => {
        Router.events.off('beforeHistoryChange', beforeHistoryChangeHandler)
        window.removeEventListener('beforeunload', beforeUnloadHandler)
      }
    }
  }, [canNavigate, interruptDisabled, rerouteAttemptNumber])

  return {}
}
