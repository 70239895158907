import { Type, plainToInstance } from 'class-transformer'
import { Entity } from '@core/entities/entity'
import { IPermitFinalSignOffReviews } from '@core/entities/permit/PermitFinalSignOffReviews'
import { PermitFinalSignOffReview } from '@core/entities/permit/PermitFinalSignOffReview'

export class PermitFinalSignOffReviews extends Entity<IPermitFinalSignOffReviews> {
  @Type(() => PermitFinalSignOffReview)
  reviews?: PermitFinalSignOffReview[]

  nextFinalSignOffId?: string

  public static new(payload: unknown): PermitFinalSignOffReviews {
    const entity = plainToInstance(PermitFinalSignOffReviews, payload)

    return entity
  }

  public getDoneReviewsIndex(): number {
    return this.reviews?.findIndex(
      (review) => review.finalSignOff?.id === this.nextFinalSignOffId,
    )
  }
}
