import React, { FunctionComponent, useEffect } from 'react'

import { AnalyticsBrowser } from '@segment/analytics-next'
import { useRouter } from 'next/router'
import { useAnalytics } from '@common/hooks'
import { useGetProfile } from '@core/react-query/features/profile/hooks/useGetProfile'

export const analytics = new AnalyticsBrowser()

const InitSegment: FunctionComponent = (props) => {
  const { data: profile } = useGetProfile()
  const router = useRouter()
  const { identify, page } = useAnalytics()

  useEffect(() => {
    analytics.addSourceMiddleware(({ payload, next }) => {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(`[Segment Analytics Event: ${payload.obj.type}]`, payload)
      }
      next(payload)
    })
    analytics.load({ writeKey: Project.segmentWriteKey })
  }, [])

  useEffect(() => {
    if (profile) {
      // TODO: remove ts-ignore when mobile is using new profile hook
      // @ts-ignore
      identify(profile)
    }
  }, [profile, identify])

  useEffect(() => {
    const handleRouteChange = (url, { shallow }) => {
      page()
    }

    router.events.on('routeChangeStart', handleRouteChange)

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method:
    return () => {
      router.events.off('routeChangeStart', handleRouteChange)
    }
  }, [page])

  return <></>
}

export default InitSegment
