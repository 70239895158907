import { useEffect, useState } from 'react'
import React from 'react'
import Cognito from '@core/cognito'

type InitCognitoProp = {
  children: React.ReactElement
}

export const InitCognito: React.FC<InitCognitoProp> = ({ children }) => {
  const [ready, setReady] = useState(false)

  useEffect(() => {
    const init = async () => {
      Cognito.init(Project.cognito)
      try {
        // Force the cognito user to be loaded if the user is logged in
        // This is important so that the cognito user is available in the useGetProfile hook
        await Cognito.getSession()
      } finally {
        setReady(true)
      }
    }
    init()
  }, [])

  if (!ready) {
    return null
  }

  return children
}
