import { plainToInstance, Type } from 'class-transformer'
import { Entity } from '@core/entities/entity'
import { IPermitTransfer } from './IPermitTransfer'
import { User } from '@core/entities/user'
import { localizedStrings } from '@core/strings'

export class PermitTransfer extends Entity<IPermitTransfer> {
  id: number

  permitId: number

  status:
    | 'CANCELLED'
    | 'PENDING'
    | 'REJECTED'
    | 'ACCEPTED'
    | 'REVIEW_TRANSFER'
    | 'AWAITING_REVIEW'

  message?: string

  @Type(() => User)
  createdBy?: User

  createdOn: string

  rejectedOn: string

  rejectionReason?: string

  acceptedOn?: string

  cancelledOn?: string

  cancellationReason?: string

  @Type(() => User)
  sentTo: User

  public static new(payload: unknown): PermitTransfer {
    const entity = plainToInstance(PermitTransfer, payload)

    return entity
  }

  public statusText(): string {
    switch (this.status) {
      case 'CANCELLED':
        return localizedStrings.transferCanceled
      case 'PENDING':
        return localizedStrings.transferAwaitingRecipient
      case 'REJECTED':
        return localizedStrings.transferRejected
      case 'AWAITING_REVIEW':
        return localizedStrings.transferAwaitingApproval
      case 'ACCEPTED':
        return localizedStrings.transferAccepted
      default:
        return this.status
    }
  }

  public statusColour():
    | 'default'
    | 'primary'
    | 'secondary'
    | 'error'
    | 'info'
    | 'success'
    | 'warning' {
    switch (this.status) {
      case 'CANCELLED':
      case 'REJECTED':
        return 'error'
      case 'AWAITING_REVIEW':
      case 'PENDING':
        return 'warning'
      case 'ACCEPTED':
        return 'success'
      default:
        return 'info'
    }
  }

  public isPending(): boolean {
    return (
      this.status === 'PENDING' ||
      this.status === 'AWAITING_REVIEW' ||
      this.status === 'REVIEW_TRANSFER'
    )
  }
}
