import { Flex, Text } from '@fundamentals'
import React from 'react'
import { IconButton } from '@common/material'
import Close from '@mui/icons-material/Close'

type GlobalDrawerHeaderProps = {
  title: string
  onClose: () => void
}

export const GlobalDrawerHeader: React.FC<GlobalDrawerHeaderProps> = ({
  title,
  onClose,
}) => {
  return (
    <Flex
      sx={{
        justifyContent: 'space-between',
        alignItems: 'center',
        py: 2,
        mb: 1,
      }}
    >
      <Text fontSize={18}>{title}</Text>
      <IconButton onClick={onClose}>
        <Close />
      </IconButton>
    </Flex>
  )
}
