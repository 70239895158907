import { plainToInstance } from 'class-transformer'
import { Entity } from '@core/entities/entity'
import { TemplateWarningTypes } from '@core/entities/template/TemplateWarnings/types'
import { IPermitWarning } from './IPermitWarning'
import { IPermitImageDuplicates } from '@core/entities/permit/PermitImageDuplicates'
import { IPermit } from '@core/entities/permit/Permit'
import { localizedStrings } from '@core/strings'

export class PermitWarning extends Entity<IPermitWarning> {
  id?: string

  type: TemplateWarningTypes

  text: string

  present: boolean

  feedbackProvided: boolean

  questionId?: string

  details?: IPermitImageDuplicates & IPermit

  downloadUrls?: Array<string>

  public static new(payload: unknown): PermitWarning {
    const entity = plainToInstance(PermitWarning, payload)

    return entity
  }

  public getTitle(): string {
    const titleMap: Record<TemplateWarningTypes, string> = {
      CLONED_PERMIT: localizedStrings.clonedPermitDetected,
      IMAGE_DUPLICATE: localizedStrings.duplicateImagesDetected,
      IMAGE_OBJECT_DETECTION: localizedStrings.hazardDetected,
    }

    return titleMap[this.type] ?? localizedStrings.warningDetected
  }
}
