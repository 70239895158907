import { Entity } from '@core/entities/entity'
import { IChecklistTemplate } from '@core/entities/checklist/ChecklistTemplate/IChecklistTemplate'
import { ChecklistTemplateVersion } from '@core/entities/checklist/ChecklistTemplateVersion'
import { plainToInstance, Type } from 'class-transformer'
import { User } from '@core/entities/user'
import { ChecklistTemplateStatus } from '@core/entities/checklist/ChecklistTemplate/types'
import { IFolderPath } from '@core/entities/folders/FolderPath'
import { ProcessTagsWrapper } from '@core/entities/processTags/ProcessTagsWrapper'

export class ChecklistTemplate extends Entity<IChecklistTemplate> {
  id?: number

  status: ChecklistTemplateStatus

  organisationId: number

  organisationName?: string

  name: string

  description: string

  code?: string

  @Type(() => ChecklistTemplateVersion)
  currentVersion?: ChecklistTemplateVersion

  @Type(() => User)
  createdBy?: User

  @Type(() => User)
  modifiedBy?: User

  createdOn?: string

  modifiedOn?: string

  isCreatable?: boolean

  folderId?: number

  // TODO: get backend to return this folder object from all endpoints
  folder?: {
    pathFromRoot?: IFolderPath
  }

  pathFromRoot?: IFolderPath

  @Type(() => ProcessTagsWrapper)
  processTags?: ProcessTagsWrapper

  public static new(payload: unknown): ChecklistTemplate {
    const entity = plainToInstance(ChecklistTemplate, payload)

    return entity
  }
}
