import { plainToInstance, Type } from 'class-transformer'
import { IParticipant } from './IParticipant'
import { Briefing, ParticipantRole, ParticipantStatus } from './types'
import { User } from '@core/entities/user'

export class Participant extends User<IParticipant> {
  externalId?: string
  userId?: number
  signed?: boolean
  signatureId?: number
  @Type(() => User)
  signedVia?: User
  roles?: Array<ParticipantRole>
  briefing?: Briefing
  status?: ParticipantStatus

  public static new(payload: unknown): Participant {
    const entity = plainToInstance(Participant, payload)

    return entity
  }

  public getRole(): string {
    return this.roles.map(({ name }) => name).join(', ')
  }

  public getBriefingFormattedText(): string {
    const bodyWithoutHtmlTags = this.briefing.briefingText
      .replace(/<\/?[^>]+(>|$)/g, '')
      .replace(/&nbsp;/g, '')

    return bodyWithoutHtmlTags
  }
}
