import { BoxProps, Flex } from '.'
import { styled } from '@mui/material/styles'

type IconContainerProps = BoxProps & {
  circular?: boolean
  inverse?: boolean
}

type IconProps = {
  icon: string
  size?: 'xs' | 'sm' | 'm' | 'lg' | '2x' | '3x' | '5x' | '7x' | '10x'
  variant?: 'l' | 's' | 'r'
  options?: string
}

const StyledIconContainer = styled(Flex)<IconContainerProps>`
  justify-content: center;
  align-items: center;
  ${({ inverse, circular }) => {
    if (inverse) {
      return {
        borderRadius: circular ? '50%' : '2px',
        padding: '4px',
      }
    }
  }}
`

const Icon: React.FC<IconProps & IconContainerProps> = ({
  icon,
  variant = '',
  size = 'sm',
  options = '',
  ...props
}) => {
  return (
    <StyledIconContainer {...props}>
      <i className={`fa${variant} fa-${icon} fa-${size} ${options}`} />
    </StyledIconContainer>
  )
}

export { Icon }
