import { Permit, IPermit } from '@core/entities/permit/Permit'
import { Checklist, IChecklist } from '@core/entities/checklist/Checklist'
import { IRegister, Register } from '@core/entities/register/Register'
import {
  IRegisterItem,
  RegisterItem,
} from '@core/entities/register/RegisterItem'
import { AuthGroup, IAuthGroup } from '@core/entities/Groups/AuthGroups'
import { IProject, Project } from '@core/entities/project/Project'
import { User, IUser } from '@core/entities/user'
import { Organisation, IOrganisation } from '@core/entities/organisation'

export type AppNotificationGetStatus = 'ALL' | 'READ' | 'UNREAD'

// ------- Target Result -------
export const appNotificationTargetResultClassMap = {
  USER: User,
  ORGANISATION: Organisation,
  PERMIT: Permit,
  CHECKLIST: Checklist,
  PROJECT: Project,
  GROUP: AuthGroup,
  REGISTER: Register,
  REGISTER_ITEM: RegisterItem,
} as const

type AppNotificationTargetResultInterfaceMap = {
  USER: IUser
  ORGANISATION: IOrganisation
  PERMIT: IPermit
  CHECKLIST: IChecklist
  PROJECT: IProject
  GROUP: IAuthGroup
  REGISTER: IRegister
  REGISTER_ITEM: IRegisterItem
}

export type AppNotificationReferenceObject =
  keyof typeof appNotificationTargetResultClassMap

export type IAppNotificationTargetResult<
  T extends AppNotificationReferenceObject,
> = AppNotificationTargetResultInterfaceMap[T]

type AppNotificationTargetResultClassMap = {
  [K in AppNotificationReferenceObject]: InstanceType<
    typeof appNotificationTargetResultClassMap[K]
  >
}

export type AppNotificationTargetResult<
  T extends AppNotificationReferenceObject,
> = AppNotificationTargetResultClassMap[T]

// ------- Action -------
export type AppNotificationAction =
  | 'SENT_TO_PERMIT_HOLDER'
  | 'REQUESTED'
  | 'ISSUED'
  | 'OPENED'
  | 'EXTENSION_REQUESTED'
  | 'EXTENSION_APPROVED'
  | 'EXTENSION_REJECTED'
  | 'DISCARDED'
  | 'REJECTED'
  | 'SIGNED'
  | 'AWAITING_FOR_FINAL_SIGN_OFF'
  | 'CLOSED_ADMIN'
  | 'CLOSED_USER'
  | 'CANCELLED'
  | 'COUNTDOWN_FINISHED'
  | 'EXPIRED'
  | 'ABOUT_TO_EXPIRE'
  | 'REMIND_POST_PERMIT_CHECKS'
  | 'CHANGES_REQUESTED'
  | 'CHANGED_ADDRESSED'
  | 'PARTICIPANT_ADDED'
  | 'TRANSFER_INITIATED'
  | 'TRANSFER_REQUESTED'
  | 'TRANSFER_ACCEPTED'
  | 'TRANSFER_REJECTED'
  | 'TRANSFER_CANCELLED'
  | 'SUSPENDED'
  | 'RESUMED'
  | 'SUSPENSION_RESUME_REQUESTED'
  | 'SUSPENSION_RESUME_REQUEST_APPROVED'
  | 'SUSPENSION_RESUME_REQUEST_DECLINED'
  | 'REGISTER_ITEM_CREATED'
  | 'REGISTER_ITEM_UPDATED'
