import { plainToInstance } from 'class-transformer'
import { Entity } from '@core/entities/entity'
import { ITemplateWarning } from '@core/entities/template/TemplateWarnings/ITemplateWarning'
import {
  TemplateWarningItemTypes,
  TemplateWarningTriggerTypes,
  TemplateWarningTypes,
} from '@core/entities/template/TemplateWarnings/types'

export class TemplateWarning extends Entity<ITemplateWarning> {
  id?: string

  type: TemplateWarningTypes

  questionId?: string

  triggerType?: TemplateWarningTriggerTypes

  items?: Array<TemplateWarningItemTypes>

  text: string

  public static new(payload: unknown): TemplateWarning {
    const entity = plainToInstance(TemplateWarning, payload)

    return entity
  }
}
