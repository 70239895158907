import React from 'react'
import cn from 'classnames'
import { experimental_sx as sx, styled } from '@mui/material/styles'
import { Box, BoxProps } from '@fundamentals'

export type ButtonStyleTypes =
  | 'primary'
  | 'text'
  | 'ghost'
  | 'tab'
  | 'tab-inactive'
export type ButtonType = BoxProps & {
  className?: string
  onClick?: (e: React.MouseEvent) => void
  onMouseUp?: () => void
  type?: 'button' | 'submit' | 'reset'
  disabled?: boolean
  icon?: string
  secondaryIcon?: string
  id?: string
  buttonStyle?: ButtonStyleTypes
  flat?: boolean
  size?: 'small' | 'medium' | 'large'
}

const StyledButton: React.FC<ButtonType> = styled('button')<ButtonType>`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  border-style: solid;
  &:focus {
    outline: 0;
  }
  ${({ buttonStyle }) => {
    return (
      buttonStyle &&
      sx({
        borderRadius: '6px',
        py: 1,
        px: 2,
      })
    )
  }}

  ${({ buttonStyle, theme, ...props }) => {
    const { disabled, flat } = props
    const hasHoverEffects = !(disabled || flat)
    switch (buttonStyle) {
      case 'primary':
        return {
          color: 'white',
          backgroundColor: disabled
            ? theme.palette.legacy.primary[7]
            : theme.palette.legacy.primary[10],
          borderColor: disabled
            ? theme.palette.legacy.primary[7]
            : theme.palette.legacy.primary[10],
          '&:hover': hasHoverEffects && {
            filter: 'brightness(105%)',
          },
          '&:active': !disabled && {
            filter: 'brightness(95%)',
          },
        }
      case 'text':
        return {
          borderColor: 'transparent',
          backgroundColor: 'transparent',
          '&:hover': hasHoverEffects && {
            filter: 'brightness(115%)',
          },
          '&:active': !disabled && {
            filter: 'brightness(85%)',
            opacity: 0.9,
          },
        }
      case 'ghost':
        return {
          backgroundColor: 'white',
          borderColor: 'transparent',
          boxShadow: '0 6px 13px 0 rgba(52,48,144,0.10)',
        }
      case 'tab':
        return {
          backgroundColor: 'transparent',
          borderTop: '0px solid',
          borderLeft: '0px solid',
          borderRight: '0px solid',
          borderBottom: '4px solid',
          borderColor: theme.palette.legacy.primary[10],
          borderRadius: '0',
          color: theme.palette.legacy.primary[10],
        }
      case 'tab-inactive':
        return {
          backgroundColor: 'transparent',
          borderTop: '0px solid',
          borderLeft: '0px solid',
          borderRight: '0px solid',
          borderBottom: '4px solid',
          borderColor: 'transparent',
          borderRadius: '0',
        }
      default:
        return {}
    }
  }}
  ${(props) => {
    switch (props.size) {
      case 'small':
        return sx({ fontSize: '13px', py: 0.5, px: 1 })
      case 'medium':
        return sx({ py: 0.5, px: 3 })
      case 'large':
        return sx({ fontSize: '20px', py: 1, px: 3 })
      default:
        return {}
    }
  }}
  ${({ disabled }) =>
    disabled && {
      cursor: 'default !important',
    }}

  ${({ float }) => `float: ${float};`}
`

const StyledButtonIcon: React.FC<ButtonType> = styled(Box)<ButtonType>`
  ${({ theme, buttonStyle }) => {
    return {
      backgroundColor:
        buttonStyle === 'primary' ? theme.palette.legacy.primary[7] : '',
    }
  }}
`

//Default Button without any styles
export const Button: React.FC<ButtonType> = ({
  icon,
  secondaryIcon,
  className,
  children,
  onMouseUp,
  buttonStyle,
  disabled,
  flat,
  ...rest
}) => (
  <StyledButton
    type='button'
    onMouseUp={onMouseUp}
    className={!buttonStyle && cn({ btn: true }, className)}
    buttonStyle={buttonStyle}
    disabled={disabled}
    flat={flat}
    {...rest}
  >
    {children}
    {icon ? (
      <StyledButtonIcon
        className='btn__icon'
        mx={!children ? 'auto' : undefined}
        buttonStyle={buttonStyle}
      >
        <i className={cn({ icon: true }, icon)} />
        {secondaryIcon && (
          <i className={cn({ icon: true, 'ml-2': true }, secondaryIcon)} />
        )}
      </StyledButtonIcon>
    ) : null}
  </StyledButton>
)

global.Button = Button
Button.displayName = 'Button'
export default Button

/** Default button added btn-text * */
export const ButtonText: React.FC<ButtonType> = ({ className, ...props }) => (
  <Button {...props} className={cn(className, 'btn btn-text')} />
)
global.ButtonText = ButtonText
ButtonText.displayName = 'ButtonPrimary'

export const ButtonTextDanger: React.FC<ButtonType> = ({
  className,
  ...props
}) => <Button {...props} className={cn(className, 'btn btn-text--danger')} />

global.ButtonTextDanger = ButtonTextDanger
ButtonTextDanger.displayName = 'ButtonTextDanger'

export const ButtonTextNavy: React.FC<ButtonType> = ({
  className,
  ...props
}) => <Button {...props} className={cn(className, 'btn btn-text--navy')} />

global.ButtonTextNavy = ButtonTextNavy
ButtonTextNavy.displayName = 'ButtonTextNavy'

/** Default button added btn-primary * */
export const ButtonPrimary: React.FC<ButtonType> = ({
  className,
  ...props
}) => <Button {...props} className={cn(className, 'btn btn-primary')} />

global.ButtonPrimary = ButtonPrimary
ButtonPrimary.displayName = 'ButtonPrimary'

export const ButtonDanger: React.FC<ButtonType> = ({ className, ...props }) => (
  <Button {...props} className={cn(className, 'btn btn-danger')} />
)

global.ButtonDanger = ButtonDanger
ButtonDanger.displayName = 'ButtonDanger'

export const ButtonDangerOutline: React.FC<ButtonType> = ({
  className,
  ...props
}) => <Button {...props} className={cn(className, 'btn btn-danger-outline')} />

global.ButtonDangerOutline = ButtonDangerOutline
ButtonDangerOutline.displayName = 'ButtonDangerOutline'
