import { plainToInstance } from 'class-transformer'
import { Entity } from '@core/entities/entity'
import { ITemplateProjectLocations } from './ITemplateProjectLocations'

export class TemplateProjectLocations extends Entity<ITemplateProjectLocations> {
  locations: Array<{
    locationNodeId: string
    locationName: string
    // Will be talking with backend to change the schema here
    locationPlanId?: number
  }>
  areas?: Array<{
    locationPlanId?: number
    locationNode?: { id: string }
    locationPlan?: { id: number }
    selectedAreas?: Array<{
      uuid: string
      name?: string
      color?: string
    }>
  }>

  public static new(payload: unknown): TemplateProjectLocations {
    const entity = plainToInstance(TemplateProjectLocations, payload)

    return entity
  }

  public getSelectedPermits(): string[] {
    // Return an array of unique area names
    return this.areas
      .flatMap((area) =>
        area.selectedAreas.map((selectedArea) => selectedArea.name),
      )
      .reduce(
        (uniqueNames, name) =>
          uniqueNames.includes(name) ? uniqueNames : [...uniqueNames, name],
        [],
      )
  }
}
