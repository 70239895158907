import { UseQueryOptions, useQuery } from '@tanstack/react-query'
import { profileKeys } from '@core/react-query/features/profile/profileKeys'
import { RequestTypes } from '../requestTypes'

export const useGetServerTime = (
  payload: RequestTypes['getServerTime'] = {},
  options?: UseQueryOptions<{ time: string }, string>,
) => {
  return useQuery({
    cacheTime: 0,
    ...profileKeys.getServerTime(payload),
    ...options,
  })
}
