import { Entity } from '@core/entities/entity'
import { plainToInstance, Type } from 'class-transformer'
import { IAvailablePermitsTemplatesWrapper } from '@core/entities/permit/PermitTemplate/IAvailablePermitsTemplatesWrapper'
import { PermitTemplate } from '@core/entities/permit/PermitTemplate/PermitTemplate'

export class AvailablePermitsTemplatesWrapper extends Entity<IAvailablePermitsTemplatesWrapper> {
  @Type(() => PermitTemplate)
  recent: Array<PermitTemplate>

  @Type(() => PermitTemplate)
  templates: Array<PermitTemplate>

  public static new(payload: unknown): AvailablePermitsTemplatesWrapper {
    const entity = plainToInstance<AvailablePermitsTemplatesWrapper, unknown>(
      AvailablePermitsTemplatesWrapper,
      payload,
    )

    return entity
  }
}
