import { useEffect } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { useRealTimeSubscription } from '@core/providers/useRealTimeSubscription'
import { PusherOptions } from '@core/providers/usePusher'
import {
  GetNotificationsUnseenCounterResponse,
  notificationsKeys,
} from '@core/react-query/features/notifications'
import { useMutateQueryData } from '@core/react-query/hooks'

export const useRealTimeNotifications = (
  userId: number,
  options?: PusherOptions,
) => {
  const { channel, bindEventHandler } = useRealTimeSubscription(
    `private-user.${userId}`,
    options,
  )

  const queryClient = useQueryClient()

  const { updateInfiniteQueryItem } = useMutateQueryData(queryClient)

  useEffect(() => {
    bindEventHandler('notifications.new', (data) => {
      queryClient.setQueryData<GetNotificationsUnseenCounterResponse>(
        notificationsKeys.getNotificationsUnseenCounter({ userId }).queryKey,
        (previousData) => {
          if (previousData) {
            const increaseBy = data?.unseen ?? 1

            return {
              ...previousData,
              unseen: previousData.unseen + increaseBy,
            }
          }
        },
      )

      updateInfiniteQueryItem(
        notificationsKeys.getNotifications({ userId }).queryKey.slice(0, 3),
        data.notification,
      )
    })

    bindEventHandler('notifications.reset', (data) => {
      queryClient.setQueryData<GetNotificationsUnseenCounterResponse>(
        notificationsKeys.getNotificationsUnseenCounter({ userId }).queryKey,
        (previousData) => {
          if (previousData) return { ...previousData, unseen: 0 }
        },
      )
    })
  }, [channel])
}
