import { IChecklist } from '@core/entities/checklist/Checklist/IChecklist'
import { IPeriodicCheck } from '@core/entities/PeriodicCheck'

export type ChecklistPermittedActions =
  | 'READ'
  | 'LIST_AUDITS'
  | 'DOWNLOAD'
  | 'CLONE'
  | 'ADD_PARTICIPANT'
  | 'REMOVE_PARTICIPANT'
  | 'BRIEF_PARTICIPANT'
  | 'LIST_COMMENTS'
  | 'COMMENT'

export type ChecklistResultType = {
  type: 'CHECKLIST' | 'PERIODIC_CHECK'
  checklist?: IChecklist
  periodicCheck?: IPeriodicCheck
  createdOn: string
}

export const ALL_CHECKLIST_STATUSES = ['COMPLETED'] as const
type ChecklistTypeTuple = typeof ALL_CHECKLIST_STATUSES
export type ChecklistStatus = ChecklistTypeTuple[number]

export enum ChecklistListTabs {
  ALL_CHECKLISTS = 'All Checklists',
  MY_CHECKLISTS = 'My Checklists',
  DRAFTS = 'Drafts',
}
