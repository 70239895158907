import { plainToInstance, Type } from 'class-transformer'
import { Entity } from '@core/entities/entity'

import { IPermitApprovalWrapper } from './IPermitApprovalWrapper'
import { PermitApproval } from '@core/entities/permit/PermitApproval'
import { User } from '@core/entities/user'
import { localizedStrings } from '@core/strings'

export class PermitApprovalWrapper extends Entity<IPermitApprovalWrapper> {
  action?: 'APPROVED' | 'REJECTED'

  @Type(() => PermitApproval)
  approval?: PermitApproval

  @Type(() => User)
  submittedBy: User

  submittedOn: string
  reason: string

  public static new(payload: unknown): PermitApprovalWrapper {
    const entity = plainToInstance(PermitApprovalWrapper, payload)

    return entity
  }

  public getSectionHeading(): string {
    let label = localizedStrings.approver
    if (this.action == 'REJECTED') {
      label = localizedStrings.rejectedBy
    }
    const approvalLabel =
      this.approval.label == 'Default Approval'
        ? ''
        : ` - ${this.approval.label}`
    if (approvalLabel) {
      label = `${label}${approvalLabel}`
    }
    return label
  }
}
