import { Entity } from '@core/entities/entity'
import { ILinkedChecklistResult } from '@core/entities/linkedProcesses'
import { plainToInstance, Type } from 'class-transformer'
import { User } from '@core/entities/user'
import { ChecklistStatus } from '@core/entities/checklist/Checklist'

export class LinkedChecklistResult extends Entity<ILinkedChecklistResult> {
  id: number

  templateName: string

  code: string

  summary: string

  status: ChecklistStatus

  questionId: string

  createdOn: string

  @Type(() => User)
  createdBy: User

  public static new(payload: unknown): LinkedChecklistResult {
    const entity = plainToInstance(LinkedChecklistResult, payload)

    return entity
  }
}
