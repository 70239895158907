import { Entity } from '@core/entities/entity'
import { ILinkedPermitResult } from '@core/entities/linkedProcesses'
import { PermitStatus } from '@core/entities/permit/PermitBase'
import { Type, plainToInstance } from 'class-transformer'
import { User } from '@core/entities/user'
import { PermitBase } from '@core/entities/permit/PermitBase'

export class LinkedPermitResult extends Entity<ILinkedPermitResult> {
  id: number

  name: string

  summary: string

  status: PermitStatus

  questionId?: string

  createdOn: string

  endsOn?: string

  @Type(() => User)
  permitHolder: User

  public static new(payload: unknown): LinkedPermitResult {
    const entity = plainToInstance(LinkedPermitResult, payload)

    return entity
  }

  public getStatusChipColor() {
    return PermitBase.getStatusChipColor(this.status)
  }

  public getStatusString(): string {
    return PermitBase.getStatusString(this.status)
  }
}
