export const ALL_PERMIT_STATUSES = [
  'OPEN',
  'AWAITING_CHECKS',
  'AWAITING_FINAL_SIGN_OFF',
  'CLOSED',
  'DISCARDED',
  'EXPIRED',
  'ISSUED',
  'REJECTED',
  'REQUESTED',
  'SIGNED',
  'UNSIGNED',
  'EXTENSION_REQUESTED',
  'CANCELLED',
  'SENT_TO_PERMIT_HOLDER',
  'SUSPENDED',
  'SUSPENDED_RESUME_REQUESTED',
]
type PermitStatusTuple = typeof ALL_PERMIT_STATUSES
export type PermitStatus = PermitStatusTuple[number]

export enum NewPermitTabs {
  BASIC_INFO = 1,
  ASSIGMENT = 2,
  QUESTIONS = 3,
  SUMMARY = 4,
  SUBMITTING = 5,
}
export enum PermitSort {
  STARTS_ON_ASCENDING = 'startsOn,ASC',
  STARTS_ON_DESCENDING = 'startsOn,DESC',
  ENDS_ON_ASCENDING = 'endsOn,ASC',
  ENDS_ON_DESCENDING = 'endsOn,DESC',
}
export const PermitSortOptions = [
  PermitSort.STARTS_ON_DESCENDING,
  PermitSort.STARTS_ON_ASCENDING,
  PermitSort.ENDS_ON_ASCENDING,
  PermitSort.ENDS_ON_DESCENDING,
]

export enum PermitListTabs {
  ALL_PERMITS = 'All Permits',
  MY_PERMITS = 'My Permits',
  DRAFTS = 'Drafts',
}
