import { Entity } from '@core/entities/entity'
import { IOrganisationsListItem } from '@core/entities/organisation/IOrganisationsListItem'
import { plainToInstance } from 'class-transformer'

export class OrganisationsListItem extends Entity<IOrganisationsListItem> {
  id: number
  name: string
  logoUrl: string

  public static new(payload: unknown): OrganisationsListItem {
    const entity = plainToInstance(OrganisationsListItem, payload)

    return entity
  }
}
